import "./ManageStream.scss";

import React, { useContext, useEffect, useRef, useState } from "react";
import { CampaignContext } from "../../contexts/CampaignContext";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import {pauseBotRoute, updateFeatureVarsRoute,twitchStreamerExistsRoute, startLiveStreamRoute, getCampaignOverviewRoute, checkBotStatusRoute, deactivateStreamRoute, activateStreamRoute, sendBotChatMessageRoute, streamConnectionStatusRoute, getChatHistoryRoute, getFeatureVarsRoute, getStreamCustomisationVarsRoute, changeStreamStatusRoute, stopStreamAnalyticsRoute, getQuizzesByIdsRoute, getCampaignThemesRoute } from "../../api/routes";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { IconJsxer } from "../../helpers/IconHelper";
import TabbedPanel from "../../components/TabbedPanel/TabbedPanel";
import BotsOverview from "../Campaigns/BotsOverview";
import Toggle from "../../components/forms/Toggle/Toggle";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import ConnectingPopup from "./ConnectingPopup";
import ControlBar from "./ControlBar";
import { io } from "socket.io-client";
import ChatTranscript from "./ChatTranscript";
import StreamTranscriptItem from "../../components/Streams/StreamTranscriptItem";
import CirclePerc from "../../components/CirclePerc/CirclePerc";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import TabStreamManager from "./TabStreamManager";
import TabBots from "./TabBots";
import TabAnalytics from './TabAnalytics';
import { CSL_DASHBOARD_VAR_MAP, checkAndUpdateObjectVar, findObjectVarLoc, getValFromObject } from "../../config/FeatureDashboardVarMap";
import FeatureControls from "../../components/FeatureControls/FeatureControls";
import TabDisruptors from "./TabDisruptors";
import { POST_MESSAGE_IN_TYPES, POST_MESSAGE_OUT_TYPES, resetOnLoadDefaultFeatureVars } from "../../helpers/FeatureControlsHelper";
import FeaturePreview, { postMessageToPreviewFrame } from "../../components/FeaturePreview/FeaturePreview";
import StartupWizard from "./StartupWizard/StartupWizard";
import TabbedInviteUser from "../../components/InviteUserPopup/TabbedInviteUser";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";

import FeatureIcon from '../../assets/streamtop/feature_streamtop_icon.svg';
import ConnectIcon from '../../assets/streamtop/connect_streamtop_icon.svg';
import BotIcon from '../../assets/streamtop/bot_streamtop_icon.svg';
import AudioIcon from '../../assets/streamtop/audio_streamtop_icon.svg';
import LeftColorBlockBox, { LEFT_COLOR_BLOCK_STYLES } from "../../components/LeftColorBlockBox/LeftColorBlockBox";
import ForceStopPopup from "./ForceStopPopup";
import RateStreamPopup from "./RateStreamPopup/RateStreamPopup";
import {CHANNEL_COLOURS } from "../../helpers/AnalyticsFunctions";
import KillFeaturesPopup from "./KillFeaturesPopup";
import RestrictedCustomisationPopup from "../CustomiseFeature/RestrictedCustomisationPopup";

window.testIOSocket = (URI) => {
    let socket = io(URI)
    console.log(socket)
}
const ManageStream = (props) => {
    const [showWizard, setShowWizard] = useState(false);
    const closeWizard = () => setShowWizard(false);

    const [showInviteUserPanel, setShowInviteUserPanel] = useState(null);
    const showInvitePanel = (e) => {
        setShowInviteUserPanel(true);
    }
    const closeInviteUserPanel = (e) => {
        setShowInviteUserPanel(false);
    }

    const { campaignId, streamId } = useParams();
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const campaignData_ref = useRef(null);
    const [streamData, setStreamData] = useState(null);
    const [currentTab, setCurrentTab] = useState(0);
    const [activationObj, setActivationObj] = useState({});
    const [analyticsObj, setAnalyticsObj] = useState({});
    const [timedBots, setTimedBots] = useState({});
    const [intialised, setInitialised] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [chatConnected, setChatConnected] = useState(false);
    const analyticsAvailable = useRef(false);
    const [botAuthorised, setBotAuthorised] = useState(false);
    const [streamerExists,setStreamerExists] = useState(false);
    const [featureReady, setFeatureReady] = useState(false);
    const [activatedFeatures, setActivatedFeatures] = useState(0);
    const activeFeatureIds = useRef([]);
    const masterFeatureId = useRef(0);

    const [audioEnabled, setAudioEnabled] = useState(false);
    const [streamStarted, setStreamStarted] = useState(false);
    const [streamLiveTime, setStreamLiveTime] = useState(null);

    const [demoMode, setDemoMode] = useState(false);
    const [streamStartCountdown, setStreamStartCountdown] = useState(false);
    const [countdownTime, setCountdownTime] = useState(10);
    const [autoHideAfterCountdown, setAutoHideAfterCountdown] = useState(false);
    const [adBug, setAdBug] = useState(false);
    const adBugPosOptions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
    const [adBugPos, setAdBugPos] = useState(adBugPosOptions[0]);
    const [overlayState, setOverlayState] = useState('idle');

    const [currentFeature, setCurrentFeature] = useState(null);
    const currentFeatureRef = useRef(null);
    const changeFeature = (f) => {
        currentFeatureRef.current = f;
        setCurrentFeature(f);
    }
    const [previewMode, setPreviewMode] = useState(false);
    const previewModeRef = useRef(false);
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const [previewTitlePostFix, setPreviewTitlePostFix] = useState('');
    const togglePreviewMode = (newVal, featureToPreview, titlePostFix = '') => {
        if (featureToPreview) {
            changeFeature(featureToPreview);
        } else {
            newVal = false;
        }
        setPreviewTitlePostFix(titlePostFix);
        setPreviewLoaded(false);
        setPreviewMode(newVal);
        previewModeRef.current = newVal;
    }

    const buildCslStateObject = () => {
        return {
            adBug,
            adBugPos,
            streamStartCountdown,
            countdownTime,
            autoHideAfterCountdown,
            overlayState,
        }
    }

    const setCslStateFromSettingsObject = (settingsObject) => {
        const _adBug = getValFromObject(settingsObject, CSL_DASHBOARD_VAR_MAP.rootProp, findObjectVarLoc(CSL_DASHBOARD_VAR_MAP, 'adBug'));
        if (_adBug !== null) setAdBug(_adBug);
        const _adBugPos = getValFromObject(settingsObject, CSL_DASHBOARD_VAR_MAP.rootProp, findObjectVarLoc(CSL_DASHBOARD_VAR_MAP, 'adBugPos'));
        if (_adBugPos !== null) setAdBugPos(_adBugPos);
        const _streamStartCountdown = getValFromObject(settingsObject, CSL_DASHBOARD_VAR_MAP.rootProp, findObjectVarLoc(CSL_DASHBOARD_VAR_MAP, 'streamStartCountdown'));
        if (_streamStartCountdown !== null) setStreamStartCountdown(_streamStartCountdown);
        const _countdownTime = getValFromObject(settingsObject, CSL_DASHBOARD_VAR_MAP.rootProp, findObjectVarLoc(CSL_DASHBOARD_VAR_MAP, 'countdownTime'));
        if (_countdownTime !== null) setCountdownTime(_countdownTime);
        const _autoHideAfterCountdown = getValFromObject(settingsObject, CSL_DASHBOARD_VAR_MAP.rootProp, findObjectVarLoc(CSL_DASHBOARD_VAR_MAP, 'autoHideAfterCountdown'));
        if (_autoHideAfterCountdown !== null) setAutoHideAfterCountdown(_autoHideAfterCountdown);
        const _overlayState = getValFromObject(settingsObject, CSL_DASHBOARD_VAR_MAP.rootProp, findObjectVarLoc(CSL_DASHBOARD_VAR_MAP, 'overlayState'));
        if (_overlayState !== null) setOverlayState(_overlayState);
    }

    const [liveStreamServerState, setLiveStreamServerState] = useState(null);
    const toggleChatCommandEnabled = (featureId) => {
        if (chatConnected && socket.current) {
            if (liveStreamServerState?.disabledChatCommandFeatureIds?.indexOf(featureId) === -1 ?? true) {
                // Disable
                socket.current.emit('disableFeatureChatCommands', {streamID:streamId, featureId:featureId});
            } else {
                // Enable
                socket.current.emit('enableFeatureChatCommands', {streamID:streamId, featureId:featureId});
            }
        }
    }

    // Capture changes and send them up to the backend, but only once initialised...
    useEffect(
        () => {
            if (intialised) {
                
                console.log('Something changed - send up to the backend!', socket.current);
                if (socket.current) {
                    
                    const cslState = buildCslStateObject();
                    let varsUpdated = false;
                    for (let i = 0; i < CSL_DASHBOARD_VAR_MAP.vars.length; i++) {
                        const updated = checkAndUpdateObjectVar(featureVarsRef.current.current, CSL_DASHBOARD_VAR_MAP.rootProp, CSL_DASHBOARD_VAR_MAP.vars[i].objectVar, cslState[CSL_DASHBOARD_VAR_MAP.vars[i].stateVar], CSL_DASHBOARD_VAR_MAP.vars[i].varType);
                        if (updated) varsUpdated = true;
                    }

                    // Some root level vars
                    if (featureVarsRef.current.current.demoMode !== demoMode) {
                        featureVarsRef.current.current.demoMode = demoMode;
                        varsUpdated = true;
                    }
                    if (featureVarsRef.current.current.activated !== streamStarted) {
                        featureVarsRef.current.current.activated = streamStarted;
                        varsUpdated = true;
                    }

                    console.log('Vars updated: ', varsUpdated);
                    if (varsUpdated) {
                        setFeatureVars(featureVarsRef.current);
                        reportFeatureVarsChanged();
                    }
                }
            } else {
                console.log('Don\'t send any changes yet! Still initialising...');
            }
        },
        [
            demoMode,
            streamStarted,
            streamStartCountdown,
            countdownTime,
            autoHideAfterCountdown,
            adBug,
            adBugPos,
            overlayState,
        ]
    )

    // Some things need to be reported contantsly (range sliders).
    // In a live scenario we don't want to do this too often, so we'll throttle it, by only allowing it to happen every 100ms...
    const constantReportingLastUpdateTime = useRef(null);
    const constantReportingTimeout = useRef(null);
    const reportFeatureVarsChanged = (onlyUpdateDisruptor = false, onComplete = null) => {
        // A range slider can trigger an update that is meant just for the disruptor to constantly update the disruptor,
        // but this only really works in preview mode as in live mode we need to send an update to the server to keep all dashboards in sync...
        if (!onlyUpdateDisruptor) {
            // A full update
            clearTimeout(constantReportingTimeout.current);
            // constantReportingTimeout.current = null;
            constantReportingLastUpdateTime.current = Date.now();
            updateFeatureVars(featureVarsRef.current.current, onComplete);
        } else
        if (!previewModeRef.current) {
            // We are not in preview mode, so we need to send the update to the server.
            // Here's where out throttling comes in...
            if (constantReportingLastUpdateTime.current === null || Date.now() - constantReportingLastUpdateTime.current > 100) {
                clearTimeout(constantReportingTimeout.current);
                // constantReportingTimeout.current = null;
                constantReportingLastUpdateTime.current = Date.now();
                updateFeatureVars(featureVarsRef.current.current, onComplete);
            } else {
                // Also protecting this by checking if the timeout is null, we will null it only when the server responds, 
                // so we can be duble sure not to overload the server with requests...
                if (constantReportingTimeout.current === null) {
                    constantReportingTimeout.current = setTimeout(() => {
                        updateFeatureVars(featureVarsRef.current.current, onComplete);
                        // constantReportingTimeout.current = null;
                    }, 100);
                }
            }
        }
        if (previewModeRef.current === true) {
            // In preview mode, we can send the data straight to the preview frame
            console.log('Report vars to preview: ', featureVarsRef.current.current[currentFeatureRef.current._id]);
            postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.SETTINGS_VARS_RECEIVED, featureVarsRef.current.current[currentFeatureRef.current._id]);
        }
    }

    const reportCustomisationVarsChanged = (updatedVars, featureID) => {
        console.log('EMIT CUSTOMISATION CHANGES', updatedVars, featureID, socket);
       socket.current.emit('updateCustomisationVars', {streamID:streamId, updatedVars:updatedVars, featureID:featureID});
       console.log('EMITTED')
        if (previewModeRef.current === true) {
            postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.CUSTOMISATION_VARS_RECEIVED, updatedVars.current[featureID]);
        }
    }

    const reportCustomisationVarsChangedAndSave = (updatedVars, featureID) => {
        console.log('EMIT CUSTOMISATION CHANGES', updatedVars, featureID, socket);
       socket.current.emit('updateCustomisationVarsWithSave', {campaignID:campaignId, streamID:streamId, updatedVars:updatedVars, featureID:featureID});
       console.log('EMITTED')
        if (previewModeRef.current === true) {
            postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.CUSTOMISATION_VARS_RECEIVED, updatedVars.current[featureID]);
        }
    }

    const MAX_CHAT_MESSAGES = 250;
    // Doing this seems to work, hopefully I can limit the length of the chat history this way...
    let _chatHistory = [];
    const [chatHistory, setChatHistory] = useState(_chatHistory);

    const [showingTooltip, setShowingTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [tooltipPos, setTooltipPos] = useState({left: 0, bottom: 0});
    const tooltipTimeout = useRef(null);
    const showTooltip = (text, e) => {
        setTooltipPosToMouse(e, true);
        setTooltipText(text);
        if (text.length > 0 && !showingTooltip && tooltipTimeout.current === null) {
            tooltipTimeout.current = setTimeout(() => setShowingTooltip(true), 300);
        }
    }
    const hideTooltip = (e) => {
        clearTimeout(tooltipTimeout.current);
        tooltipTimeout.current = null;
        setShowingTooltip(false);
    }
    const setTooltipPosToMouse = (e, force = false) => {
        if (showingTooltip || force) {
            const toolTipPos = {
                left: e.pageX + 'px',
                bottom: (window.innerHeight - e.pageY + 10) + 'px'
            }
            setTooltipPos(toolTipPos);
        }
    }

    //Connect to the web socket server to receive real time updates created by other users and chat
    const socket = useRef(null);
    const createNewSocketConnection = () => {
        if (socket.current === null) {
            socket.current = process.env.REACT_APP_NODE_ENV == 'local' ? io(process.env.REACT_APP_SOCKET_IO_BASE_URL) : io.connect(null, { secure: true, port: 8443});
            console.log(socket.current, process.env.REACT_APP_SOCKET_IO_BASE_URL, process.env.REACT_APP_NODE_ENV);
            //socket = io.connect(process.env.REACT_APP_API_BASE_URL)
            console.log(socket.current)
            socket.current.on('connect', () => {
                console.log('JOIN ROOM');
                socket.current.emit('dashboardJoinStream', streamId);
            })
            socket.current.on('activatingStream', function (data) {
                console.log('Received activatingStream message from the server!', data);
            });
            socket.current.on('streamActivated', function (data) {
                console.log('STREAM ACTIVATED')
                toast.success(data.message);
                setChatConnected(true);
                // See if the sever has some chain data (ie. we are not the first dashboard to conenct!)
                requestChainDataFromServer();
                requestLiveStreamStateFromServer();
            });
            socket.current.on('streamDeactivated', function (data) {
                toast.success(data.message);
                setChatConnected(false);
            });
            socket.current.on('updateFeatureVars', (newFeatureVars) =>{
                featureVarsRef.current.current = newFeatureVars;
                setCslStateFromSettingsObject(featureVarsRef.current.current);
                setFeatureVars({...featureVarsRef.current});
            })
            socket.current.on('updateCustomisationVars', (data) =>{
                console.log('UPDATE CUSTOMIOSATION VARS SOCKET CALL')
                customisationVars.current = data.updatedVars;
            })
            socket.current.on('activationStatus', (activationData) => {
                setActivationObj(activationData.activationObj);
                setConnecting(activationData.isActivating);
            })
            socket.current.on('chatMessage', (chatObj) => {
                _chatHistory = _chatHistory.concat(chatObj);
                while (_chatHistory.length > MAX_CHAT_MESSAGES) {
                    _chatHistory.shift();
                }
                setChatHistory(_chatHistory);
            })
            socket.current.on('heartbeat', (timeObj) => {
                // console.log('Heartbeat: ', timeObj);
                setStreamLiveTime(timeObj);
            });
            socket.current.on('updateAnalytics', (minimisedAnalyticsObj) =>{
                let aObjCopy = Object.assign({}, analyticsObj);
                setAnalyticsObj((prev) => {
                    const clonedAnalyticObj = Object.assign(prev, minimisedAnalyticsObj);
                    return clonedAnalyticObj
                });
            });
            socket.current.on('updateStreamTranscript', (newTranscript) =>{
                setAnalyticsObj((prev) => {
                    const clonedAnalyticObj = Object.assign(prev);
                    if(!clonedAnalyticObj.transcript) clonedAnalyticObj.transcript = [];
                    clonedAnalyticObj.transcript.push(newTranscript)
                    return clonedAnalyticObj
                });
            });
            socket.current.on('updateTimedBots', (timedBots) =>{
                setTimedBots(timedBots)
                console.log('TB:',timedBots)
                
            });
            
            socket.current.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`);
              });

            // messages from the feature page
            socket.current.on("featureReady", (data) => {
                console.log('***************************** FEATURE READY', data);
                setFeatureReady(true);
                if (activeFeatureIds.current.indexOf(data.featureID) === -1) {
                    activeFeatureIds.current.push(data.featureID);
                    setActivatedFeatures(activeFeatureIds.current.length);
                }
                console.log('Feature list:', activeFeatureIds.current);
            }
            
            );
            socket.current.on("featureUnloaded", 
                (data) => {
                    console.log('***************************** FEATURE UNLOAD', data);
                    if (activeFeatureIds.current.indexOf(data.featureID) !== -1) {
                        activeFeatureIds.current = activeFeatureIds.current.filter((id) => id !== data.featureID);
                        setActivatedFeatures(activeFeatureIds.current.length);
                        if (activeFeatureIds.current.length === 0) {
                            setFeatureReady(false); 
                        }
                    }
                    if (!data.retainAudioState) {
                        setAudioEnabled(false);
                    }
                    console.log('Feature list:', activeFeatureIds.current);
                });

            socket.current.on('setMasterFeature', (data) => {
                // console.log('Heartbeat: ', timeObj);
                console.log('NEW MASTER FEATURE:', data.masterID);
                masterFeatureId.current = data.masterID;
            });

            socket.current.on("audioEnabled", () => {setAudioEnabled(true)});


            socket.current.on('clawError', function (data) {
                toast.error('CLAW MACHINE ERROR ('+data.machineID+') '+data.info);
            });
            socket.current.on('clawMoveEnded', function (data) {
                toast.success('CLAW MACHINE ('+data.machineID+') RESULT: '+ data.isWinner ? 'PRIZE WON' : 'LOST');
            });


            // receiving chain updates, this keeps all dashboards in sync
            // find chainObjects_ref and the flow is documented just above it...
            socket.current.on("chainUpdateDown", (chains) => {
             
                let serverSentChains = false;
                for (let prop in chains) {
                    // We're doing this in a for in because we don't want to wipe out local chain data with blank data
                    if (prop !== 'rawChainData') {
                        serverSentChains = true;
                        chainObjects_ref.current[prop] = chains[prop];
                        if (chains[prop].active /*&& currentTab === 1*/) {
                            // We are on the chain tab and the chain is active, update the current feature
                            selectDisruptor(findFeatureData(chains[prop].chainItems[chains[prop].index].featureId));
                        }
                    }
                }
                setChainObjects({...chainObjects_ref.current});
                if (serverSentChains) {
                    console.log('Server sent chains down!');
                } else {
                    if (isChainMaster.current) {
                        buildAllFeatureChainsAndSendToServer(chains.rawChainData);
                    } else {
                        // If we are not the chain master, request the chain data again after a short delay,
                        // maybe the chain master is still processing the data or something...
                        // Obviously we only need to do this if the campaign data has chains - otherwise we end up in an infinite loop!
                        const campaignData = campaignData_ref.current || campaignContext.campaignData;
                        if (campaignData.chains && campaignData.chains.length > 0) {
                            setTimeout(() => {
                                console.log('RE_REQUEST DATA');
                                requestChainDataFromServer();
                                requestLiveStreamStateFromServer();
                            }, 500);
                        }
                    }
                }
            });

            // Reload all dashboards on a force stop...
            socket.current.on('forceFeatureStop',
                () => {document.location.reload()}
            );

            // Receive persistent server state
            socket.current.on('liveStreamState', (state) => {
                setLiveStreamServerState(state);
            });

            console.log('CREATE SOICKET CONNECTION');
            // See if the sever has some chain data (ie. we are not the first dashboard to conenct!)
            requestChainDataFromServer();
            requestLiveStreamStateFromServer();
        }
    }

    const [showingKillOldFeaturesPopup, setShowingKillOldFeaturesPopup] = useState(false);
    const killOldFeaturePages = () => {
        if (socket.current) {
            if (activeFeatureIds.current.length > 1) {
                let featureIdToKeep = masterFeatureId.current;
                if (featureIdToKeep === 0) {
                    // Feature ID is a timestamp, find the most recent one...
                    let mostRecent = 0;
                    for (let i = 0; i < activeFeatureIds.current.length; i++) {
                        if (activeFeatureIds.current[i] > mostRecent) {
                            mostRecent = activeFeatureIds.current[i];
                        }
                    }
                    featureIdToKeep = mostRecent;
                }
                console.log('Kill old features, keep: ', featureIdToKeep);
                socket.current.emit('killOldFeatures', {streamID: streamId, keepFeatureIDOpen: featureIdToKeep});
            }
        }
    }

    const getChatHistory = () => {
        axios
        .get(getChatHistoryRoute, {
            params: {  streamID:streamId },
            withCredentials:true
        })
        .then(function (response) {
            let newChatHistory = response.data.chatHistory
            // I couldn't find a way to limit the number of chat message doing it this way...
            // setChatHistory((chatHistory) => [...newChatHistory, ...chatHistory])
            // This method works, though
            _chatHistory = newChatHistory.concat(_chatHistory);
            while (_chatHistory.length > MAX_CHAT_MESSAGES) {
                _chatHistory.shift();
            }
            setChatHistory(_chatHistory);
        })
        .catch(function (error) {
            console.log(error)
        });
    }
    useEffect(
        () => {
         
            updateViwerChart();
            updateChattersChart();
        }, [analyticsObj]
    )
    useEffect (() => {
        if(streamData)
            {
            let sortedChannels = streamData.channels.toSorted(function(a, b) {
                var textA = a.twitchHandle.toUpperCase();
                var textB = b.twitchHandle.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            
            });
        setChannelList(sortedChannels);
        }
    }, [streamData])
    const viewersChartOptions = {}
    const [viewerChartConfig, setViewerChartConfig] = useState({});
    const updateViwerChart =() =>{

      
        let formattedViewerObject = [];
        if(analyticsObj.viewerCount)
        {
            for(let i=0; i<analyticsObj.viewerCount.length; i++)
            {
                let viewerObj = analyticsObj.viewerCount[i];
                let newViewerObj = {
                    x: analyticsObj.featureStartTime + viewerObj.timeStamp,
                    y: viewerObj.viewers
                }
                formattedViewerObject.push(newViewerObj)
            }
        }
        setViewerChartConfig( {
            
            data: {
              datasets: [{
                data:formattedViewerObject
              }]
            },
            options: {
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: 'minute'
                        }
                    }
                }
            }
          })
    }
    const [chattersChartData, setChattersChartData] = useState({ labels: [], datasets: []})
    const updateChattersChart = () =>{

        if(analyticsObj.userList)
        {
            setChattersChartData({
                labels: ['Messages', 'Unique Chatters'],
                datasets: [
                {
                   
                    data: [analyticsObj.messages,analyticsObj.userList.length],
                    backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    
                    ],
                    borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                
                    ],
                    borderWidth: 1,
                }
                ]
            });
        }
    }

    const navigate = useNavigate();

    const customisationVars = useRef(null);
    const getCustomisationVars = () => {
        console.log('Get Customisation Vars');
        axios
        .get(getStreamCustomisationVarsRoute, {
            params: { campaignID: campaignId, streamID: streamId },
            withCredentials:true
        })
        .then(function (response) {
            if (response.data.customisationVars) {
                customisationVars.current = response.data.customisationVars;
                console.log('Got customisationVars: ', customisationVars.current);
                // getFeatureVars();
                getThemeData();
            }
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    }

    /* THEMES */
    const [themeData, setThemeData] = useState(null);
    const themeDataRef = useRef(null);
    const themeDataFetchedRef = useRef(false);

    const getThemeData = () => {
        axios
            .get(getCampaignThemesRoute, { params: { campaignID: campaignId }, withCredentials: true })
            .then((res) => {
                console.log('[THEMES] --- Got theme data: ', res.data);
                themeDataRef.current = res.data.themes;
                setThemeData(res.data.themes);

                themeDataFetchedRef.current = true;
                getFeatureVars();
            })
            .catch(function (error) {
                toast.error('Error - ' + error);
            });
    }

    const applyThemeToFeature = (featureId, themeId) => {
        console.log('Apply theme to feature: ', featureId);
        if (socket.current) {
            socket.current.emit('refreshFeature', {streamID: streamId, featureID: featureId, applyTheme: themeId});
        }
    }

    const [featureVars, setFeatureVars] = useState({});
    const featureVarsRef = useRef(null);
    const getFeatureVars = () => {
        console.log('Get Feature Vars');
        axios
        .get(getFeatureVarsRoute, {
            params: { campaignID: campaignId, streamID: streamId, includeBotData:true },
            withCredentials:true
        })
        .then(function (response) {
            console.log('STREAM VARS',response.data);
            if (response.data.streamVars) {
                featureVarsRef.current = response.data.streamVars;

                // Set default theme data if it doesn't exist
                for (let p in featureVarsRef.current.current) {
                    if (typeof featureVarsRef.current.current[p] === 'object') {
                        if (typeof featureVarsRef.current.current[p].theme === 'undefined') {
                            featureVarsRef.current.current[p].theme = {id: ''};
                        }
                    }
                }

                setCslStateFromSettingsObject(featureVarsRef.current.current);
                setFeatureVars(featureVarsRef.current);
                setStreamStarted(featureVarsRef.current.current.activated);
                if (featureVarsRef.current.current.activated) {
                    analyticsAvailable.current = true;
                }
                setTimedBots(response.data.timedBots)
                getQuizData();

                resetOnLoadDefaultFeatureVarsInternal();
            }
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
            // console.log(error.response)
        });
    }
    const resetOnLoadDefaultFeatureVarsInternal = () => {
        // Reset any featureVars that need to be reset on load...
        const campaignData = campaignData_ref.current || campaignContext.campaignData;
        // console.log('RESET ONLOAD VARS 1: ', campaignData.features, campaignData.features.length);
        if (campaignData && campaignData.features) {
            for (let i = 0; i < campaignData.features.length; i++) {
                // console.log('RESET ONLOAD VARS: ', campaignData.features[i]);
                let madeChanges = resetOnLoadDefaultFeatureVars(featureVarsRef.current, campaignData.features[i], customisationVars.current);
            }
        } else {
            setTimeout(() => resetOnLoadDefaultFeatureVarsInternal(), 20);
        }
    }
    const updateFeatureVars = (updatedVars, onComplete = null) => {
        axios
        .post(updateFeatureVarsRoute, {
            campaignID: campaignId, 
            streamID: streamId, 
            updatedVars,
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data);
            // featureVars.current = response.data.streamVars;
            // setInitialised(true);
            constantReportingTimeout.current = null;
            if (onComplete) {
                onComplete(response.data)
            }
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
            // console.log(error.response)
            constantReportingTimeout.current = null;
        });
    }

    const quizData_ref = useRef(null);
    const getQuizData = () => {
        console.log('Get quiz data: ', customisationVars.current);

        const quizIds = [];
        const quizIdsByDisruptorId = {};
        for (let prop in customisationVars.current.current) {
            if (customisationVars.current.current[prop].quizdata) {
                console.log('Found quiz data (' + prop + '): ', customisationVars.current.current[prop].quizdata.quizdata.quizzes);
                quizIdsByDisruptorId[prop] = [];
                for (let i = 0; i < customisationVars.current.current[prop].quizdata.quizdata.quizzes.length; i++) {
                    if (customisationVars.current.current[prop].quizdata.quizdata.quizzes[i].quizID && customisationVars.current.current[prop].quizdata.quizdata.quizzes[i].quizID !== '') {
                        quizIds.push(customisationVars.current.current[prop].quizdata.quizdata.quizzes[i].quizID.quiz._id);
                        quizIdsByDisruptorId[prop].push(customisationVars.current.current[prop].quizdata.quizdata.quizzes[i].quizID.quiz._id);
                    }
                }
            }
        }
        if (quizIds.length > 0) {
            console.log('Get quiz data for ids: ', quizIds);
            axios.get(getQuizzesByIdsRoute, {params: {ids: quizIds}}).then(
                (res) => {
                    console.log('Got quiz data: ', res.data.quizzes);
                    quizData_ref.current = {};
                    for (let prop in quizIdsByDisruptorId) {
                        quizData_ref.current[prop] = {quizzes: []};
                        for (let i = 0; i < quizIdsByDisruptorId[prop].length; i++) {
                            for (let j = 0; j < res.data.quizzes.length; j++) {
                                if (quizIdsByDisruptorId[prop][i] === res.data.quizzes[j]._id) {
                                    quizData_ref.current[prop].quizzes.push(res.data.quizzes[j]);
                                    break;
                                }
                            }
                        }
                    }
                    console.log('Quiz data ref: ', quizData_ref.current);
                    setInitialised(true);
                }
            ).catch(
                (err) => {
                    console.log('Error getting quiz data: ', err);
                }
            )
        } else {
            // No quizzes!
            setInitialised(true);
        }
    }

    // Special functions that can be triggered by buttons in the feature controls in place of the normal feature commands...
    const BUTTON_FUNCTIONS = {
        reportFeatureVarsChanged: (featureId, param) => reportFeatureVarsChanged(),
        startLivePoll: (featureId, param) => {
            reportFeatureVarsChanged(false, 
                () =>
                    setTimeout(() => {
                        sendFeatureCommand(null, 'START_POLL', featureId, param);
                        // postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: 'START_POLL', extraData: {username:'CampignStudio', hashtag:'Manual Press', commandParam: param} })
                    }, 100)
            );
        },
        createNewLivePoll: (featureId, param) => {
            openRestrictedCustomisationPopup(featureId, "Edit Live Polls", "setup", "pollData", "polls");
        },
        editLiveTextPresets: (featureId, param) => {
            openRestrictedCustomisationPopup(featureId, "Edit Live Text Presets", "setup", "presetText", "presets");
        },
        saveLiveTextPreset: (featureId, param) => {
            const _featureVars = featureVarsRef.current.current[featureId];
            const _customisationVars = customisationVars.current.current[featureId];
            const presetDataToSave = {
                presetName: _featureVars.liveTextControls.presetName,
                headerText: _featureVars.liveTextControls.headerText,
                mainContent: _featureVars.liveTextControls.mainContent
            }
            if (presetDataToSave.mainContent === '') {
                toast.error('Cannot save an empty preset!');
                return;
            }
            if (presetDataToSave.presetName === '') {
                toast.error('Please enter a name for the preset!');
                return;
            }
            if (presetDataToSave.headerText === '') {
                toast.error('Please enter a header for the preset!');
                return;
            }
            let checkForExisting = _customisationVars.setup.presetText.presets.findIndex((p) => p.presetName === presetDataToSave.presetName);
            let presetNameExists = checkForExisting !== -1;
            let presetSuffix = 1;
            while (presetNameExists) {
                const newPresetName = presetDataToSave.presetName + ' (' + (presetSuffix++) + ')'; 
                checkForExisting = _customisationVars.setup.presetText.presets.findIndex((p) => p.presetName === newPresetName);
                if (checkForExisting === -1) {
                    presetDataToSave.presetName = newPresetName;
                    presetNameExists = false;
                }
            }
            _customisationVars.setup.presetText.presets.push(presetDataToSave);
            reportCustomisationVarsChangedAndSave(customisationVars.current, featureId);
            toast.success('Preset Text saved!');
        },
        saveResponseLiveTextPreset: (featureId, param) => {
            const _featureVars = featureVarsRef.current.current[featureId];
            const _customisationVars = customisationVars.current.current[featureId];
            const presetDataToSave = {
                presetName: _featureVars.responseTextControls.presetName,
                headerText: _featureVars.responseTextControls.headerText,
                mainContent: _featureVars.responseTextControls.mainContent
            }
            if (presetDataToSave.mainContent === '') {
                toast.error('Cannot save an empty preset!');
                return;
            }
            if (presetDataToSave.presetName === '') {
                toast.error('Please enter a name for the preset!');
                return;
            }
            if (presetDataToSave.headerText === '') {
                toast.error('Please enter a header for the preset!');
                return;
            }
            let checkForExisting = _customisationVars.setup.presetText.presets.findIndex((p) => p.presetName === presetDataToSave.presetName);
            let presetNameExists = checkForExisting !== -1;
            let presetSuffix = 1;
            while (presetNameExists) {
                const newPresetName = presetDataToSave.presetName + ' (' + (presetSuffix++) + ')'; 
                checkForExisting = _customisationVars.setup.presetText.presets.findIndex((p) => p.presetName === newPresetName);
                if (checkForExisting === -1) {
                    presetDataToSave.presetName = newPresetName;
                    presetNameExists = false;
                }
            }
            _customisationVars.setup.presetText.presets.push(presetDataToSave);
            reportCustomisationVarsChangedAndSave(customisationVars.current, featureId);
            toast.success('Response preset saved!');
        },
        selectLiveTextPreset: (featureId, param) => {
            const _featureVars = featureVarsRef.current.current[featureId];
            const _customisationVars = customisationVars.current.current[featureId];
            console.log('Select live text preset: ', featureId, _featureVars, _customisationVars);

            const presetTextIndex = Number(_featureVars.liveTextControls.presetText);
            const presetTextData = _customisationVars.setup.presetText.presets[presetTextIndex];
            if (presetTextData) {
                _featureVars.liveTextControls.presetName = presetTextData.presetName;
                _featureVars.liveTextControls.headerText = presetTextData.headerText;
                _featureVars.liveTextControls.mainContent = presetTextData.mainContent;
                featureVarsRef.current = {current: {...featureVarsRef.current.current, [featureId]: _featureVars}};
                setFeatureVars(featureVarsRef.current);
                reportFeatureVarsChanged();
            }
        },
        selectResponseLiveTextPreset: (featureId, param) => {
            const _featureVars = featureVarsRef.current.current[featureId];
            const _customisationVars = customisationVars.current.current[featureId];
            console.log('Select live text preset: ', featureId, _featureVars, _customisationVars);

            const presetTextIndex = Number(_featureVars.responseTextControls.presetText);
            const presetTextData = _customisationVars.setup.presetText.presets[presetTextIndex];
            if (presetTextData) {
                _featureVars.responseTextControls.presetName = presetTextData.presetName;
                _featureVars.responseTextControls.headerText = presetTextData.headerText;
                _featureVars.responseTextControls.mainContent = presetTextData.mainContent;
                featureVarsRef.current = {current: {...featureVarsRef.current.current, [featureId]: _featureVars}};
                setFeatureVars(featureVarsRef.current);
                reportFeatureVarsChanged();
            }
        }
    }
    const sendFeatureCommand = (e, commandString, featureId, param = null) => {
        if (e?.preventDefault) {
            e.preventDefault();
        }
        if (previewModeRef.current === true && featureId === currentFeatureRef.current._id) {
            // We are previewing this feature, redirect the command to the preview frame
            if (previewLoaded) {
                if (BUTTON_FUNCTIONS[commandString]) {
                    console.log('Running button function: ', commandString, featureId, param);
                    BUTTON_FUNCTIONS[commandString](featureId, param);
                } else {
                    postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: commandString, extraData: {username:'CampignStudio', hashtag:'Manual Press', commandParam: param} });
                }
            }
        } else {
            if (BUTTON_FUNCTIONS[commandString]) {
                console.log('Running button function: ', commandString, featureId, param);
                BUTTON_FUNCTIONS[commandString](featureId, param);
            } else {
                console.log('Emitting feature command: ', commandString, featureId, param);
                socket.current.emit('featureCommand', {streamID:streamId, featureID: featureId, command:commandString, extraData:{username:'CampignStudio', hashtag:'Manual Press', commandParam: param}});
            }
        }
    }

    const findStream = (campaignData = null) => {
        campaignData = campaignData || campaignContext.campaignData;
        console.log('Campaign Data: ', campaignData);
        for (let i = 0; i < campaignData.streams.length; i++) {
            if (campaignData.streams[i]._id === streamId) {
                setStreamData(campaignData.streams[i]);
                console.log('Stream Data: ', campaignData.streams[i])
                // Set initial settings here?
                checkConnectionStatus(campaignData.streams[i]._id);
                checkBotStatus(campaignData.streams[i].twitchHandle);
                checkStreamerStatus(campaignData.streams[i].twitchHandle)
                createNewSocketConnection();
                getChatHistory();

                // Final call before we can do anything is to pull in the feature vars...
                // getFeatureVars();
                getCustomisationVars();
                break;
            }
        }
    }
    const deactivateChat = (e) => {
        e.preventDefault();
   
        for(let i=0; i<streamData.channels.length; i++)
        {
            console.log(streamData.channels[i].twitchHandle)
            axios
            .post(deactivateStreamRoute, {
                streamID:streamId,
                campaignID:campaignId,
                channel:streamData.channels[i].twitchHandle,
                withCredentials:true
            })
            .then(function (response) {
                console.log(response.data)
                //toast.success(response.data.message);
                setChatConnected(false);
                setStreamStarted(false);
                /* if (socket.current) {
                    socket.current.close();
                }
                socket.current = null; */
            })
            .catch(function (error) {
                toast.error('Error - ' + error);
                // console.log(error.response)
            });



        }
            if(streamData.twitchHandle)
            {
            axios
            .post(deactivateStreamRoute, {
                streamID:streamId,
                campaignID:campaignId,
                channel:streamData.twitchHandle,
                withCredentials:true
            })
            .then(function (response) {
                console.log(response.data)
                //toast.success(response.data.message);
                setChatConnected(false);
                setStreamStarted(false);
                /* if (socket.current) {
                    socket.current.close();
                }
                socket.current = null; */
            })
            .catch(function (error) {
                toast.error('Error - ' + error);
                // console.log(error.response)
            });
        }

    }

    const activateChat = (e) => {
        e.preventDefault();
        // setActivationObj({stage: 1, botAuthorised: false, socketCreated: false, twitchConnected: false});
        setConnecting(true);
        console.log('CAMPAIGN CONTEXT', campaignContext)
        isChainMaster.current = true;
        chainObjects_ref.current = {};
        console.log('Is chain master: ', isChainMaster.current);
        console.log('Reset chain data: ', chainObjects_ref.current);

        //Add any quiz data to the activation so the backedn can get the commands used in the quizzes
        let quizData = [];
        for(let i=0; i< campaignContext.campaignData.features.length; i++)
        {
            let feature = campaignContext.campaignData.features[i];
            if(feature.varKey == 'quizPoll')
            {
                console.log('CONTAINS QUIZ')
                for(const customisation of campaignContext.campaignData.customisations)
                {
                    if(customisation.var == feature._id+'.quizdata.quizdata.quizzes')
                    {
                        for(const rawQuizData of customisation.value)
                        {
                            if (rawQuizData.quizID && rawQuizData.quizID.quiz) {
                                quizData.push({featureID:feature._id, quizID:rawQuizData.quizID.quiz._id})
                            }
                        }
                    }
                }
            }
        }
        console.log(quizData)
        axios
        .post(activateStreamRoute, {
            streamID:streamId,
            campaignID:campaignId,
            channel:streamData.twitchHandle,
            channelList:streamData.channels,
            bots:campaignContext.campaignData.chatbots,
            quizData:quizData,
            hashtagCommands:campaignContext.campaignData.hashtagCommands,
            isBotAuthorised:true,
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data)
            setBotAuthorised(response.data.botAuthorised);
            //toast.success(response.data.message);
            setChatConnected(true);
            setConnecting(false);
            // See if the sever has some chain data (ie. we are not the first dashboard to conenct!)
            requestChainDataFromServer();
            requestLiveStreamStateFromServer();
        })
        .catch(function (error) {
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
            setConnecting(false);
        });

    }

    const checkConnectionStatus = (streamID) => {
        console.log('Checking connection status...', streamID);
        axios
        .get(streamConnectionStatusRoute, {
            params: {  streamID:streamID },
            withCredentials:true
        })
        .then(function (response) {
            setChatConnected(response.data.active);
            if (response.data.active) {
                // See if the sever has some chain data (ie. we are not the first dashboard to conenct!)
                requestChainDataFromServer();
                requestLiveStreamStateFromServer();
            } else {
                setShowWizard(true);
            }
        })
        .catch(function (error) {
            setChatConnected(false);
        });
    }
    
    const checkBotStatus = (channel) => {
        console.log('Checking bot status...', channel);
        axios
        .get(checkBotStatusRoute, {
            params: { channel:channel },
            withCredentials:true
        })
        .then(function (response) {
            console.log('BOTY AUTH:', response.data.botAuthorised)
            setBotAuthorised(response.data.botAuthorised);
        })
        .catch(function (error) {
            setBotAuthorised(false);
        });
    }

    const checkStreamerStatus = (channel) => {
        axios
        .get(twitchStreamerExistsRoute, {
            params: { channel:channel },
            withCredentials:true
        })
        .then(function (response) {
            console.log('STREAMER EXISTS', response.data.exists)
            setStreamerExists(response.data.exists);
        })
        .catch(function (error) {
            setStreamerExists(false);
        });
    }

    const sendChatMsg = (e,name, copy, id) => {
        if (e) {
            e.preventDefault();
        }
        axios
        .post(sendBotChatMessageRoute, {
            botDetails:{
                name:name,
                copy:copy,
                id:id,
            },
            streamID:streamId,
            channel:streamData.twitchHandle,
            isBotAuthorised:false,
            withCredentials:true
        })
        .then(function (response) {
            toast.success(response.data.message);
        })
        .catch(function (error) {
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });

    }

    const endStream = (e) => {
        e.preventDefault();
        console.log('End Stream!');
        
        deactivateChat(e);
        setShowRatingPopup(true);
    }


    const pullInCampaignData = () => {
        if (campaignId) {
            axios
                .get(getCampaignOverviewRoute, { params: { campaignID: campaignId }, withCredentials: true })
                .then((response) => {
                    campaignData_ref.current = response.data.campaignData;
                    setCampaignContext((oldValues) => {
                        return { ...oldValues, initialising: false, campaignData: response.data.campaignData }
                    })
                    findStream(response.data.campaignData);
                })
                .catch((error) => {
                    toast.error("Error fetching campaign data");
                    navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
                });
        } else {
            navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
        }
    }

    useEffect(() => {
        // Commented out the bit where we skip pulling in campaignData as it causes a bug when adding new disruptors.
        // *** This IS NOT the fix! This is a TEMPORARY measure! ***

        /* if (campaignContext.campaignData && campaignContext.campaignData._id === campaignId) {
            // we already have our campaign data available!
            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: false }
            });

            findStream();
        } else {
            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: true }
            });
            // setTimeout(pullInCampaignData, 2000);
            pullInCampaignData();
        } */
        pullInCampaignData();

        // Return a cleanup function to close the socket when we leave the page, otherwise we get sockets hanging around!
        return () => {
            if (socket.current) {
                socket.current.close();
            }
            socket.current = null;
        }
    }, [campaignId]);


    /* ---- Bot stuff ---- */
    const triggerBotPost = (e, bot, messageIndex = null) => {
        console.log('Trigger Bot Post: ', bot, messageIndex);
        if(messageIndex == null)   messageIndex = Math.floor(Math.random() * bot.botCopy.length)
        // Send the message
        sendChatMsg(e, bot.name, bot.botCopy[messageIndex], bot.id);
    }

    const pauseBot = (e, bot) => {
        console.log('Pause Bot: ', bot);
        axios
        .post(pauseBotRoute, {
            campaignID: campaignId, 
            streamID: streamId, 
            botID:bot.id,
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data);
            setTimedBots(response.data.timedBots);
            response.data.pauseStatus ? toast.success('Bot successfully paused') :  toast.success('Bot successfully started')
            // featureVars.current = response.data.streamVars;
            // setInitialised(true);
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
            // console.log(error.response)
        });
    }

    const unpauseBot = (e, bot) => {
        console.log('Unpause Bot: ', bot);
    }
    /* ---- End of Bot suff ---- */

    const startCountdown = (e) => {
        e.preventDefault();
        if (chatConnected) {
            console.log('Start Countdown!');
        } else {
            console.log('Need to Go Live first');
        }
    }

    const startStream = (e) => {
        e.preventDefault();
        if (chatConnected) {
            console.log('Start Stream!');
            axios
            .post(startLiveStreamRoute, {
                campaignID:campaignId,
                streamID:streamId,
                withCredentials:true
            })
            .then(function (response) {
                toast.success(response.data.message);
                // See if the sever has some chain data (ie. we are not the first dashboard to conenct!)
                /* requestChainDataFromServer(); */
            })
            .catch(function (error) {
                toast.error('Error - ' + error/*.response.data.message*/);
                // console.log(error.response)
            });
            setStreamStarted(true);
            analyticsAvailable.current = true;
        } else {
            console.log('Need to Go Live first');
        }
    }

    const changeStreamStatus = (newStatus) => {
        axios
        .post(changeStreamStatusRoute, {
            campaignID: campaignId, _id:streamId, status: newStatus,
            withCredentials:true
        })
        .then(function (response) {
            streamData.status = newStatus;
            setStreamData({...streamData});
            toast.success('Stream status updated')
        })
        .catch(function (error) {
            console.log(error)
            toast.error('There was a problem updating the stream status')
        });
    }

    // CHAINS - used for intros / outros / feature countdowns!
    //
    // So here's the flow for this:
    //
    // Feature chains can be created locally at any time, but are only emitted to the server if the campaign has started
    //      (similarly, we should only be able to activate a chain if the campaign has started)
    // When we start a campaign, we emit a chainUpdateUp socket message with an empty object,
    //      this acts as a request for the server's (source of truth) chain states.
    // The server then emits a chainUpdateDown socket message, we can test if this is empty and if so, build all our feature chains
    //      and emit them to the server.
    // The server sends a chainUpdateDown socket message whenever it receives a chainUpdateUp (after updating it's chains), which
    //      keeps all dashboard instances in sync.
    // The server also sends a chainUpdateDown socket message when the feature page progresses through an active chain, so we can keep track
    //      on each dashboard instance.
    // Note the isChainMaster ref: this is set on the client where the user clicked the connect button, meaning only one dashboard instance
    //      will build and emit the chain data.

    const isChainMaster = useRef(false);
    const chainObjects_ref = useRef({});
    const [chainObjects, setChainObjects] = useState(null);
    const setChainItemVal = (chainId, itemIndex, key, val) => {
        chainObjects_ref.current[chainId].chainItems[itemIndex][key] = val;
        setChainObjects({...chainObjects_ref.current});
        if (chatConnected) {
            console.log('Emitting chain update!');
            socket.current.emit('chainUpdateUp', {streamID:streamId, chains:chainObjects_ref.current});
        }
    }
    const startChain = (chainId) => {
        if (chatConnected && !chainObjects_ref.current[chainId].active) {
            socket.current.emit('chainStart', {streamID:streamId, chainId:chainId});
        }
    }
    const stopChain = (chainId) => {
        if (chatConnected && chainObjects_ref.current[chainId].active) {
            socket.current.emit('chainStop', {streamID:streamId, chainId:chainId});
        }
    }

    const [chainSkipAllowed, setChainSkipAllowed] = useState(true);
    const skipChainItem = (chainId, chainItemIndex, featureId) => {
        endFeature(featureId);
        // Fail-safe in case features have a bug where they don't send the feature ended message
        // Give it a second then first a socket message to advance the chain.
        // The server will respoond to this message, advancing the chain but only if the itemIndex we send is the current index.
        setChainSkipAllowed(false);
        setTimeout(() => {
            socket.current.emit('chainSkipItem', {streamID:streamId, chainId:chainId, chainItemIndex:chainItemIndex});
            setChainSkipAllowed(true);
        }, 5000);
    }

    const endFeature = (triggerEndFeatureId) => {
        // Find the correct command
        const campaignData = campaignData_ref.current || campaignContext.campaignData;
        let featureData_end = null;
        if (campaignData && campaignData.features) {
            for (let i = 0; i < campaignData.features.length; i++) {
                if (campaignData.features[i]._id === triggerEndFeatureId) {
                    featureData_end = campaignData.features[i];
                }
            }
            if (featureData_end) {
                console.log('Find end command: ', featureData_end);
                let commandString_end = '';
                for (let i = 0; i < featureData_end.feature.commands.length; i++) {
                    if (featureData_end.feature.commands[i].isEndTrigger) {
                        commandString_end = featureData_end.feature.commands[i].command;
                    }
                }
                if (commandString_end) {
                    console.log('Emitting end feature command: ', commandString_end, triggerEndFeatureId);
                    if (socket.current) {
                        const timestamp = Date.now();
                        // We are connected - emit a socket message so our triggered feature gets logged and all that (just like clicking the control button)
                        socket.current.emit('triggerFeatureEnd', {streamID: streamId, timestamp: timestamp, featureID: triggerEndFeatureId, triggerFeatureID: triggerEndFeatureId, command: commandString_end});
                    }
                }
            }
        }
    }

    const requestChainDataFromServer = () => {
        // We send up an empty object, which is a request for the server's (source of truth) chain states.
        socket.current.emit('chainUpdateUp', {streamID:streamId, chains:{}});
    }

    const requestLiveStreamStateFromServer = () => {
        socket.current.emit('requestLiveStreamState', {streamID:streamId});
    }

    const buildAllFeatureChainsAndSendToServer = (receivedCampaignChainData = null) => {
        // Weirdly can't seem to access campaignContext from this function after a refresh,
        // even though it works fine elsewhere...

        // isChainMaster is set on the client where the user clicked the connect button,
        // meaning only one dashboard instance will build and emit the chains.
        if (!isChainMaster.current) {
            return;
        }

        const campaignData = campaignData_ref.current || campaignContext.campaignData;
        console.log('CAMPAIGN DATA ===============', campaignData)
        let gotChains = false;
        if (campaignData && campaignData.features) {
            for (let i = 0; i < campaignData.features.length; i++) {
                if (campaignData.features[i].feature.featureType === 'feature') {
                    const builtChain = buildFeatureChainStateObject(campaignData.features[i]._id, campaignData, receivedCampaignChainData);
                    if (builtChain.chainItems && builtChain.chainItems.length > 0) {
                        gotChains = true;
                    }
                }
            }
        } // else {
            // Campaign data not avilable...
            // Try again in a moment...
            // setTimeout(buildAllFeatureChainsAndSendToServer, 1000);
        // }

        if (gotChains) {
            console.log('UP DATA', streamId, chainObjects_ref.current)
            socket.current.emit('chainUpdateUp', {streamID:streamId, chains:chainObjects_ref.current});
        }

        isChainMaster.current = false;
    }

    const buildFeatureChainStateObject = (featureId, _campaignData = null, receivedCampaignChainData = null) => {
        const campaignData = _campaignData || campaignContext.campaignData;
        // First things first - if we have some received campaign chain data, use that!
        // The server is our ultimate source of truth, but only needs to provide the basic chain data, we can build the chain objects here.
        if (receivedCampaignChainData) {
            console.log('Updating campaign chain data: ', receivedCampaignChainData);
            campaignData.chains = receivedCampaignChainData;
            if (isChainMaster.current) {
                chainObjects_ref.current = {};
            }
        }
        // console.log('Build chain obj: ', featureId, campaignData);
        let featureData = null;
        let rawChainData = null;
        for (let i = 0; i < campaignData.features.length; i++) {
            if (campaignData.features[i]._id === featureId) {
                // our feature!
                featureData = campaignData.features[i];
                break;
            }
        }
        for (let i = 0; i < campaignData.chains.length; i++) {
            if (campaignData.chains[i].indexOf(featureId) >= 0) {
                // our chain!
                rawChainData = campaignData.chains[i];
                break;
            }
        }
        // console.log('Chain data (raw): ', rawChainData, featureData);
        let builtChainData = null;
        if (chainObjects_ref.current[featureId]) {
            // fetch it (this stuff is persistent)!
            console.log('Using persitent chain data: ', chainObjects_ref.current[featureId]);
            builtChainData = chainObjects_ref.current[featureId].chainItems;
        } else {
            // build it
            builtChainData = [];

            // now go through our feature chain, intro, feature, outro...
            for (let i = 0; i < rawChainData.length; i++) {
                const chainStepData = {};
                chainStepData.enabled = true;
                chainStepData.featureId = rawChainData[i];
                // Find the command!
                for (let j = 0; j < campaignData.features.length; j++) {
                    if (campaignData.features[j]._id === rawChainData[i]) {
                        // found the feature...
                        for (let k = 0; k < campaignData.features[j].feature.commands.length; k++) {
                            if (campaignData.features[j].feature.commands[k].isPrimaryTrigger) {
                                // Found our trigger command
                                chainStepData.command = campaignData.features[j].feature.commands[k].command;
                                break;
                            }
                        }
                        if (chainStepData.command === undefined) {
                            // We didn't find a trigger command...
                            chainStepData.command = campaignData.features[j].feature.commands[0].command;
                            break;
                        }
                    }
                }
                
                builtChainData.push(chainStepData);
            }

            chainObjects_ref.current[featureId] = {active: false, index: 0, chainItems: builtChainData};
            setChainObjects({...chainObjects_ref.current});
        }
        console.log('Built chains: ', chainObjects_ref.current, builtChainData);
        return chainObjects_ref.current && chainObjects_ref.current[featureId] ? chainObjects_ref.current[featureId] : {};
    }

    const countChainItems = () => {
        const returnData = {items: 0, lastFeatureId: null};
        const campaignData = campaignContext.campaignData;
        if (campaignData && campaignData.chains) {
            for (let i = 0; i < campaignData.chains.length; i++) {
                for (let j = 0; j < campaignData.chains[i].length; j++) {
                    returnData.items++;
                    returnData.lastFeatureId = campaignData.chains[i][j];
                }
            }
        }
        return returnData;
    }

    // Force Stop stuff
    const [showForceStopPopup, setShowForceStopPopup] = useState(false);
    const forceStopHandler = () => setShowForceStopPopup(true);
    const closeForceStopPopup = () => setShowForceStopPopup(false);
    const forceStopNow = () => {
        axios
        .post(stopStreamAnalyticsRoute, {
            streamID: streamId, 
            withCredentials:true
        })
        .then(function (response) {
            toast.success('Analytics ended, extension refreshing!');
            setStreamStarted(false);
            socket.current.emit('forceFeatureStop', {streamID: streamId});
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    }

    const getTabData = () => {
        const tabData = [{ label: 'Stream Manager' }, { label: 'Feature Control', hidden: !gotFeature() }, { label: 'Disruptors' }, { label: 'Bots' }];
        if (analyticsAvailable.current && !campaignContext.campaignData.draft) {
            tabData.push({ label: 'Analytics' });
        }
        return tabData;
    }

    const findFeatureData = (featureId) => {
        for (let i = 0; i < campaignData_ref.current.features.length; i++) {
            if (campaignData_ref.current.features[i]._id === featureId) {
                return campaignData_ref.current.features[i];
            }
        }
        return null;
    }

    const gotFeature = () => {
        if (campaignData_ref.current && campaignData_ref.current.features) {
            for (let i = 0; i < campaignData_ref.current.features.length; i++) {
                if (campaignData_ref.current.features[i].feature.featureType === 'feature') {
                    return true;
                }
            }
        }
        return false;
    }

    const selectDisruptor = (d) => {
        console.log('Select distruptor: ', d);
        setPreviewMode(false);
        changeFeature(d);
    }

    const getChainedDisruptorPanel = (item, featureData, i, mainFeatureId = null) => {
        console.log('Get disruptor panel for: ', item, featureData);

        let boxStyle = featureData.feature.featureType;
        if (LEFT_COLOR_BLOCK_STYLES[featureData.feature.featureType + '_' + featureData.feature.varKey]) {
            boxStyle = featureData.feature.featureType + '_' + featureData.feature.varKey;
        }
        if (LEFT_COLOR_BLOCK_STYLES['disruptor_' + featureData.feature.colourCategory]) {
            boxStyle = 'disruptor_' + featureData.feature.colourCategory;
        }
        if (LEFT_COLOR_BLOCK_STYLES['category_' + featureData.feature.colourCategory]) {
            boxStyle = 'category_' + featureData.feature.colourCategory;
        }
        
        return (
            <div className="fl-column compact" key={'disruptor_' + i}>
                <div className="fl-row grow">
                    <LeftColorBlockBox style={boxStyle} overrideBgColor={currentFeature === featureData ? '#DDDDEE' : ''}>
                        <div className="fl-row list-view-content">

                            <div className="grow">
                                {featureData.contentLabel || `Disruptor Name #${i + 1}`}
                                <br />
                                <span className={`bot-time-dark`}>
                                    ({featureData.feature.featureName})
                                </span>
                            </div>

                            <div className="fl-row shrink">
                                {chatConnected &&
                                    <Toggle
                                        id={'fc_tog_' + i}
                                        currentState={item.enabled}
                                        toggleFunc={(e) => {setChainItemVal(mainFeatureId, i, 'enabled', !item.enabled)}}
                                    />
                                }

                                <div className="edit-icon" onClick={(e) => selectDisruptor(featureData)}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.controls, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                </div>
                            </div>

                        </div>
                    </LeftColorBlockBox>
                </div>
            </div>
        )
    }

    const [showRatingPopup, setShowRatingPopup] = useState(false);
    const closeRatingPopup = () => setShowRatingPopup(false);
    const getCurrentRating = () => {
        let  currentRating = 0;

        if (campaignData_ref.current) {
            let streamData = null;
            for (let i = 0; i < campaignData_ref.current.streams.length; i++) {
                if (campaignData_ref.current.streams[i]._id === streamId) {
                    streamData = campaignData_ref.current.streams[i];
                    break;
                }
            }

            if (streamData && streamData.ratings) {
                let totalRating = 0;
                for (let i = 0; i < streamData.ratings.length; i++) {
                    totalRating += streamData.ratings[i].rating;
                }
                currentRating = Math.round(totalRating / streamData.ratings.length);
            }
        }

        return currentRating;
    }

    const [showingRestrictedCustomisationPopup, setShowingRestrictedCustomisationPopup] = useState(false);
    const closeRestrictedCustomisationPopup = () => setShowingRestrictedCustomisationPopup(false);
    const restrictedCustomisationTitle = useRef(null);
    const restrictedCustomisationFeatureId= useRef(null);
    const restrictedCustomisationTab = useRef(null);
    const restrictedCustomisationGroup = useRef(null);
    const restrictedCustomisationFamily = useRef(null);
    const openRestrictedCustomisationPopup = (featureId, title, tab, group, family) => {
        restrictedCustomisationTitle.current = title;
        restrictedCustomisationFeatureId.current = featureId;
        restrictedCustomisationTab.current = tab;
        restrictedCustomisationGroup.current = group;
        restrictedCustomisationFamily.current = family;
        setShowingRestrictedCustomisationPopup(true);
    }

    const [channelList, setChannelList] = useState([])
    const getChannels = () => {
   
        if(streamData)
        {
            return(
                <div className='connectedChannelList'>
                    {channelList.map((val, i) => {
                        return(
                        <div key={"chan_" + i} className='connectedChannel' style={{color: getChannelColour(val.twitchHandle), border:"2px solid "+getChannelColour(val.twitchHandle)}}>
                             <img className='user-profile-pic-small' src={val.profilePic}></img>
                            {val.twitchHandle}
                        </div>
                        )
    
                    })
                    }
                </div>
          )
        }
        else
        {
            return(<></>)
        }
       

    }
    const getChannelColour = (channel) => {

        for(let i=0; i<channelList.length; i++)
        {
            if(channelList[i].twitchHandle.toLowerCase() == channel.toLowerCase() ) return CHANNEL_COLOURS[i%8];
        }
    }

    return (
        <div className="content-page-container" onMouseMove={setTooltipPosToMouse}>
            <div className="sticky-header content-page-top">

                <div className="top-info">
                    <div className="fl-column shrink">
                        <div className="back-button" 
                            onClick={
                                (e) => {
                                    navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW_TAB.replace(':id', campaignId).replace(':tab', 'streams'))
                                }
                            }
                        >
                            {IconJsxer.GetIcon(IconJsxer.ICONS.chevronLeft, IconJsxer.ICON_STYLES.campaignPanelTop)}
                        </div>
                    </div>
                    <div className="fl-column no-gap grow">
                        <h1>{campaignContext.campaignData.campaignName}{campaignContext.campaignData.draft && <span className="light-text small-text"> (draft)</span>}</h1>
                        { getChannels()}
                    </div>
                    <div className="fl-column shrink right-status-block">
                        <div className="fl-row light-text">
                            <div className="grow"></div>
                            <div className="fl-row compact">
                                <div className={`big-status-light ${featureReady ? '' : 'in'}active`}><img src={FeatureIcon} alt="" /></div>
                                <div>
                                    Features Activated {'('+activatedFeatures+')'}
                                    {activatedFeatures > 1 &&
                                        <>
                                            <br/><span className="little-red-text-button small-text" onClick={() => setShowingKillOldFeaturesPopup(true)}>DEACTIVATE FEATURES</span>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="fl-row compact">
                                <div className={`big-status-light ${audioEnabled ? '' : 'in'}active`}><img src={AudioIcon} alt="" /></div>
                                <div>Audio/Video</div>
                            </div>
                            <div className="fl-row compact">
                                <div className={`big-status-light ${chatConnected ? '' : 'in'}active`}><img src={ConnectIcon} alt="" /></div>
                                <div>Connect</div>
                            </div>
                            <div className="fl-row compact">
                                <div className={`big-status-light ${botAuthorised ? '' : 'in'}active`}><img src={BotIcon} alt="" /></div>
                                <div>Bot</div>
                            </div>
                        </div>
                        <div className="fl-row">
                            <div className="grow"></div>
                            {!chatConnected && streamData &&
                                <>
                                    <button className={`standard-button${streamData && streamData.status === 'ended' ? ' button-inactive' : ''}`} onClick={streamData && streamData.status !== 'ended' ? activateChat : null}>Connect to Channels</button>
                                    {streamData && streamData.status !== 'ended' &&
                                        <button className="standard-button red-button" onClick={() => changeStreamStatus('ended')}>Set to Ended</button>
                                    }
                                    {streamData && streamData.status === 'ended' &&
                                        <button className="standard-button yellow-button" onClick={() => changeStreamStatus('scheduled')}>Set to Scheduled</button>
                                    }
                                </>
                            }
                            {chatConnected &&
                                <>
                                    {!streamStarted && !campaignContext.campaignData.draft &&
                                        <>
                                            <div className="label warning-text j-right">Analytics and bots are not currently active.<br />Remember to enable analytics when running a live stream!</div>
                                            <button className="standard-button" onClick={startStream}>Start Analytics / Bots</button>
                                        </>
                                    }
                                    <button className="standard-button" onClick={endStream}>{streamStarted ? 'End Campaign' : 'Disconnect'}</button>
                                </>
                            }

                        </div>
                    </div>
                </div>

            </div>
            <div className="content-page-content-container">
                <div className="content-page-main-content">

                    <TabbedPanel
                        tabs={getTabData()}
                        switchTabCallback={(tabId, tabData) => {setCurrentTab(tabId); togglePreviewMode(false); changeFeature(tabId === 1 ? countChainItems().items === 1 ? findFeatureData(countChainItems().lastFeatureId) : null : null);}}
                        extraPanelClass="ms-tabbed-panel"
                        tabWidth={analyticsAvailable.current ? '' : '20%'}
                    >
                        <div className="fl-column form-holder">
                            {currentTab === 0 &&
                                <>
                                    {/* The tab content component is completely reliant on props. 
                                        This way we can ensure all the settings remain in memory and
                                        we can have a single useEffect that sends changes up to the server.
                                        Note the leading _ this is because we can't give the prop the same name...
                                    */}
                                    {streamData &&
                                        <TabStreamManager
                                            _streamLink={process.env.REACT_APP_AXIOS_ORIGIN +'/feature/'+campaignId+'/'+streamId+'/321ABC'}
                                            _analyticsData={analyticsObj}
                                            _streamData={streamData}
                                            _streamStarted={streamStarted}
                                            _demoMode={demoMode}
                                            _draftMode={campaignContext.campaignData.draft}
                                            _streamLiveTime={streamLiveTime}
                                            _streamStartCountdown={streamStartCountdown}
                                            _setStreamStartCountdown={setStreamStartCountdown}
                                            _countdownTime={countdownTime}
                                            _setCountdownTime={setCountdownTime}
                                            _chatConnected={chatConnected}
                                            _featureReady={featureReady}
                                            _startCountdown={startCountdown}
                                            _autoHideAfterCountdown={autoHideAfterCountdown}
                                            _setAutoHideAfterCountdown={setAutoHideAfterCountdown}
                                            _startStream={startStream}
                                            _overlayState={overlayState}
                                            _setOverlayState={setOverlayState}
                                            _adBug={adBug}
                                            _setAdBug={setAdBug}
                                            _adBugPosOptions={adBugPosOptions}
                                            _adBugPos={adBugPos}
                                            _setAdBugPos={setAdBugPos}
                                            _showTooltip={showTooltip}
                                            _hideTooltip={hideTooltip}
                                            _forceStop={forceStopHandler}
                                        />
                                    }
                                </>

                                
                            }

                            {currentTab === 1 &&
                                <>
                                {
                                    campaignContext.campaignData.features.map(
                                        (val, i, arr) => {
                                            if (val.feature.featureType === 'feature') {
                                                const featureChainStateObject = buildFeatureChainStateObject(val._id);
                                                console.log('Feature chain state: ', featureChainStateObject);

                                                // Check tabs in featureData for any themeable tabs
                                                const featureData = val;
                                                let themeable = false;
                                                for (let i = 0; i < featureData.feature.customisation.length; i++) {
                                                    if (featureData.feature.customisation[i].themeable) {
                                                        themeable = true;
                                                        break;
                                                    }
                                                }

                                                return (
                                                    <div className="fl-column no-panel-shadow" key={'fcont_' + i}>


                                                        <div className="split-content">
                                                            <div className="shrink-column">

                                                                <div className="underlined-panel-heading">
                                                                    <h4>Feature Chain</h4>
                                                                    <div className="grow"></div>
                                                                    {(!chatConnected || !featureReady) &&
                                                                        <>
                                                                            <div className="shrink small-text light-text j-right tight">
                                                                                Connect and open extension page<br />to enable your feature chain.
                                                                            </div>
                                                                            <div className="edit-icon disabled">
                                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.play, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {chatConnected && featureReady && !featureChainStateObject.active &&
                                                                        <div className="edit-icon" onClick={(e) => startChain(val._id)}>
                                                                            {IconJsxer.GetIcon(IconJsxer.ICONS.play, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                                        </div>
                                                                    }
                                                                    {chatConnected && featureReady && featureChainStateObject.active &&
                                                                        <>
                                                                            <div className="edit-icon" onClick={(e) => stopChain(val._id)}>
                                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.stop, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                                            </div>
                                                                            <div className={`edit-icon${!chainSkipAllowed ? ' disabled' : ''}`} onClick={chainSkipAllowed ? (e) => skipChainItem(val._id, featureChainStateObject.index, findFeatureData(featureChainStateObject.chainItems[featureChainStateObject.index].featureId)._id) : null}>
                                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.skip, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </div>

                                                                <LeftColorBlockBox style={previewMode ? 'preview_mode' : currentFeature ? 'live_mode' : 'disabled_mode'}>
                                                                    <div className="fl-row grow">
                                                                        <div className="live-preview-toggle-content">
                                                                            <h5 className={previewMode ? 'preview' : currentFeature ? 'live' : 'disabled'}>{previewMode ? 'Preview Mode' : currentFeature ?  'Live Mode' : 'Nothing Selected'}</h5>
                                                                            <div className="grow">
                                                                                {!currentFeature &&
                                                                                    <>Please select a Feature or Disruptor to view its controls.</>
                                                                                }
                                                                                {!previewMode && currentFeature &&
                                                                                    <>You are running in live mode. Commands will be sent to all active extension pages.</>
                                                                                }
                                                                                {previewMode &&
                                                                                    <>Commands will be sent to the preview window. Note: settings changes will still be sent to active extension pages.</>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            {currentFeature &&
                                                                                <Toggle id="previewMode" currentState={!previewMode} toggleFunc={() => togglePreviewMode(!previewMode, currentFeature, currentFeature === val ? '(Main Feature)' : '(Chained Disruptor)')} enabled={currentFeature !== null} overrideBg={{on: '#FFF'}}></Toggle>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </LeftColorBlockBox>

                                                                <div className="scroll-area _42">
                                                                    <div className="fl-column compact">

                                                                        {/* FEATURE CHAIN CONTENT */}
                                                                        {featureChainStateObject && featureChainStateObject.chainItems.length >= 1 && featureChainStateObject.chainItems.map(
                                                                            (item, index, arr) => {
                                                                                const featureData = findFeatureData(item.featureId);
                                                                                if (featureData) {
                                                                                    return (
                                                                                        <div className="fl-row" key={'chain_' + index}>
                                                                                            {featureChainStateObject.chainItems.length > 1 &&
                                                                                                <div className="shrink">
                                                                                                    <div className={`feature-chain-position-holder${chatConnected && featureReady && featureChainStateObject.active && featureChainStateObject.index === index ? ' active-chain-position' : (!chatConnected || !featureReady) ? '' : ' enabled-chain-position'}`}>
                                                                                                        {index + 1}
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            <div className="grow">
                                                                                                {getChainedDisruptorPanel(item, featureData, index, val._id)}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                } else {
                                                                                    return null;
                                                                                }
                                                                            }
                                                                        )}

                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="column no-panel-shadow">
                                                                <div className="underlined-panel-heading">
                                                                    <h4>Controls</h4>
                                                                    <div className="grow"></div>
                                                                </div>
                                                                <div className="ms-full-scroll-area">


                                                                    <div className="fl-column">

                                                                        {currentFeature &&
                                                                            <FeatureControls
                                                                                feature={currentFeature}
                                                                                streamVars={featureVars.current}
                                                                                customisationVars={customisationVars.current}
                                                                                campaignId={campaignId}
                                                                                streamId={streamId}
                                                                                featureId={currentFeature._id}
                                                                                reportFeatureVarsChangedFunc={reportFeatureVarsChanged}
                                                                                saveCustomisationVarsFunc={reportCustomisationVarsChangedAndSave}
                                                                                reportCustomisationVarsChanged={reportCustomisationVarsChanged}
                                                                                sendFeatureCommandFunc={sendFeatureCommand}
                                                                                showTooltipFunc={showTooltip}
                                                                                hideTooltipFunc={hideTooltip}
                                                                                startCollapsed={false}
                                                                                forceCollapsed={false}
                                                                                chatConnected={chatConnected}
                                                                                featureReady={featureReady}
                                                                                key={currentFeature.feature._id}
                                                                                isThemeable={themeable}
                                                                                themeData={themeData}
                                                                                applyThemeFunc={applyThemeToFeature}
                                                                                openRestrictedCustomisation={openRestrictedCustomisationPopup}
                                                                                channels={streamData.channels}
                                                                            />
                                                                        }


                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            }
                                        )
                                    }
                                </>
                            }

                            {currentTab === 2 &&
                                <>
                                    {streamData &&
                                        <TabDisruptors
                                            _analyticsData={analyticsObj}
                                            _triggerBotPost={triggerBotPost}
                                            _pauseBot={pauseBot}
                                            _unpauseBot={unpauseBot}
                                            _timedBots={timedBots}
                                            _chatConnected={chatConnected}
                                            _featureReady={featureReady}
                                            _streamLiveTime = {streamLiveTime}
                                            _features={campaignContext.campaignData.features}
                                            _featureVars={featureVars}
                                            _customisationVars={customisationVars.current}
                                            _customisationVarsRef={customisationVars}
                                            _reportFeatureVarsChanged={reportFeatureVarsChanged}
                                            _reportCustomisationVarsChanged={reportCustomisationVarsChanged}
                                            _saveCustomisationVarsFunc={reportCustomisationVarsChangedAndSave}
                                            _sendFeatureCommandFunc={sendFeatureCommand}
                                            _showTooltip={showTooltip}
                                            _hideTooltip={hideTooltip}

                                            _currentDisruptor={currentFeature}
                                            _setCurrentDisruptor={changeFeature}
                                            _previewMode={previewMode}
                                            _setPreviewMode={togglePreviewMode}

                                            _postMessageToPreviewFrame={postMessageToPreviewFrame}

                                            _campaignId={campaignId}
                                            _streamId={streamId}
                                            _allQuizData={quizData_ref.current}

                                            _liveStreamServerState={liveStreamServerState || {}}
                                            _toggleChatCommandEnabled={toggleChatCommandEnabled}

                                            _themeData={themeData}
                                            _applyThemeFunc={applyThemeToFeature}
                                            openRestrictedCustomisation={openRestrictedCustomisationPopup}

                                            channels={streamData.channels}
                                        />
                                    }
                                </>
                            }

                            {currentTab === 3 &&
                                <>
                                    {streamData &&
                                        <TabBots
                                            _analyticsData={analyticsObj}
                                            _triggerBotPost={triggerBotPost}
                                            _pauseBot={pauseBot}
                                            _unpauseBot={unpauseBot}
                                            _timedBots={timedBots}
                                            _streamStarted={streamStarted}
                                            _streamLiveTime = {streamLiveTime}
                                            _features={campaignContext.campaignData.features}
                                            _featureVars={featureVars}
                                            _reportFeatureVarsChanged={reportFeatureVarsChanged}
                                            _reportCustomisationVarsChanged={reportCustomisationVarsChanged}
                                            _sendFeatureCommandFunc={sendFeatureCommand}
                                            _showTooltip={showTooltip}
                                            _hideTooltip={hideTooltip}
                                            _botsAvailable={chatConnected && botAuthorised}
                                        />
                                    }
                                </>
                            }
                            {currentTab === 4 &&
                                <>
                                    {streamData &&
                                        <TabAnalytics
                                            _analyticsAvilable={analyticsAvailable.current}
                                            _connected={chatConnected}
                                            _analyticsData={analyticsObj}
                                            _updateAnalyticsFunc = {setAnalyticsObj}
                                            _streamID={streamId}
                                            _streamData={streamData}
                                            _triggerBotPost={triggerBotPost}
                                            _pauseBot={pauseBot}
                                            _unpauseBot={unpauseBot}
                                            _timedBots={timedBots}
                                            _streamLiveTime = {streamLiveTime}
                                            _features={campaignContext.campaignData.features}
                                            _featureVars={featureVars}
                                            _reportFeatureVarsChanged={reportFeatureVarsChanged}
                                            _reportCustomisationVarsChanged={reportCustomisationVarsChanged}
                                            _sendFeatureCommandFunc={sendFeatureCommand}
                                            _showTooltip={showTooltip}
                                            _hideTooltip={hideTooltip}
                                        />
                                    }
                                </>
                            }
                        </div>
                    </TabbedPanel>


                    {/* <h1 className="mt-4 mb-2">Welcome to the Manage Stream page</h1>

                    <p><a href='' onClick={(e) => { activateChat(e) }}>ACTIVATE CHAT</a></p>
                    <p><a href='' onClick={(e) => { sendChatMsg(e, 'Just a little test message') }}>Send Test Message</a></p>

                    {campaignContext.initialising &&
                        <p className="label">Fetching Campaign Data</p>
                    }

                    {!campaignContext.initialising &&
                        <>

               
                            <p className="label">Campaign ID: {campaignId}</p>
                            <p className="label">Stream ID: {streamId}</p>
                            
                            <p>Unique Users: {analyticsObj.userList && <>{analyticsObj.userList.length}</>}</p>
                            <p>Top User: {analyticsObj.userList && analyticsObj.userList.length  > 0 && <>{analyticsObj.userList[0].username} - {analyticsObj.userList[0].amount}</>}</p>
                           
                            <h4>Raw campaign data:</h4>
                            {Object.entries(campaignContext.campaignData).map(
                                ([k, v], i) => <p key={k}>{k + ': ' + v}</p>
                            )}
                            <h4>Raw stream data:</h4>
                            {streamData && Object.entries(streamData).map(
                                ([k, v], i) => <p key={k}>{k + ': ' + v}</p>
                            )}
                        </>
                    } 
                    
                      <h4>Raw analytics data:</h4>
                            {Object.entries(analyticsObj).map(
                                ([k, v], i) => <p key={k}>{k + ': ' + v}</p>
                            )}
                    */}
                    
                   

                </div>
            </div>
            {showingTooltip &&
                <div className="tw-tooltip" style={tooltipPos} dangerouslySetInnerHTML={{__html: tooltipText}}>
                </div>
            }
            {/* <ControlBar connected={chatConnected} demoMode={demoMode} setDemoMode={setDemoMode} connectFunc={activateChat} disconnectFunc={endStream} /> */}
            {/*chatConnected &&*/
                <ChatTranscript chatHistory={chatHistory} channels={streamData ?streamData.channels  : null} />
            }
            {previewMode &&
                <FeaturePreview
                    campaignId={campaignId}

                    currentFeature={currentFeature}
                    featureVars={featureVars}
                    streamId={streamId}
                    customisationVars={customisationVars.current}
                    titlePostFix={previewTitlePostFix}
                    onLoad={() => setPreviewLoaded(true)}
                    closeFunc={() => togglePreviewMode(false)}

                    startControlsExpanded={currentTab === 1 || currentFeature.feature.featureType === 'feature'}
                    startMaximized={currentTab === 1 || currentFeature.feature.featureType === 'feature'}

                    _reportFeatureVarsChanged={reportFeatureVarsChanged}
                    _reportCustomisationVarsChanged={reportCustomisationVarsChanged}
                    _sendFeatureCommandFunc={sendFeatureCommand}
                    _showTooltip={showTooltip}
                    _hideTooltip={hideTooltip}
                />
            }
            {showWizard && streamData && streamData.status !== 'ended' &&
                <StartupWizard 
                    closePanelFunc={closeWizard} 
                    streamLink={process.env.REACT_APP_AXIOS_ORIGIN +'/feature/'+campaignId+'/'+streamId+'/321ABC'}
                    featureReady={featureReady}
                    audioEnabled={audioEnabled}
                    setOverlayState={setOverlayState}
                    chatConnected={chatConnected}
                    botAuthorised={botAuthorised}
                    activateChat={activateChat}
                    showInvitePanel={showInvitePanel}
                    invitePanelOpen={showInviteUserPanel}
                    connectingPanelOpen={connecting}
                    streamerExists={streamerExists}
                />
            }
            {showInviteUserPanel &&
                <TabbedInviteUser closePanelFunc={closeInviteUserPanel} campaignId={campaignId} hideExisting={true} showAcTypeSelector={false} acType={ACCOUNT_TYPES.streamer} acTypeFilter={[ACCOUNT_TYPES.streamer]} defaultTab={0} />
            }
            {connecting &&
                <ConnectingPopup stream={streamData} activationObj={activationObj}/>
            }
            {showForceStopPopup &&
                <ForceStopPopup closePanelFunc={closeForceStopPopup} forceStopNow={forceStopNow} />
            }
            {showRatingPopup &&
                <RateStreamPopup closePanelFunc={closeRatingPopup} campaignId={campaignId} streamId={streamId} rating={getCurrentRating()} />
            }
            {showingKillOldFeaturesPopup &&
                <KillFeaturesPopup closePanelFunc={() => setShowingKillOldFeaturesPopup(false)} killOldFeaturePages={killOldFeaturePages} activatedFeatures={activatedFeatures} />
            }
            {showingRestrictedCustomisationPopup &&
                <RestrictedCustomisationPopup
                    title={restrictedCustomisationTitle.current}
                    tab={restrictedCustomisationTab.current}
                    group={restrictedCustomisationGroup.current}
                    family={restrictedCustomisationFamily.current}
                    closePanelFunc={closeRestrictedCustomisationPopup}
                    featureId={restrictedCustomisationFeatureId.current}
                    campaignId={campaignId}
                    campaignData={campaignData_ref.current}
                    customisationVars={customisationVars.current}
                    saveCustomisationVars={reportCustomisationVarsChangedAndSave}
                />
            }
        </div>
    );
}

export default ManageStream;
