import React, { useContext, useEffect, useRef, useState } from "react";
import { CampaignContext } from "../../contexts/CampaignContext";
import {getAllFeaturesListRoute, getAnalyticCampaignListRoute, getFeatureDataByIdRoute, getFeatureListRoute, getSeederFileRoute, getSeederListRoute} from "../../api/routes";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import "./SeederEditor.scss";
import { AuthContext } from "../../contexts/AuthContext";
import CampaignAnalytics from '../../components/Analytics/CampaignAnalytics';
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { Form, useNavigate } from "react-router-dom";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import FormField from "../../components/forms/FormField/FormField";
import TextArea from "../../components/forms/TextArea/TextArea";
import CollapsibleHeadedSection, { COLLAPSIBLES_CONTROL } from "../../components/CollapsibleHeadedSection/CollapsibleHeadedSection";
import { BOX_TYPES, BUTTON_WIDTH_OPTIONS, BUTTON_COLOUR_OPTIONS, COLOUR_CATEGORIES, EVAL_CONDITION_ITEMS, FEATURE_TYPES, FORCE_WIDTH_OPTIONS, ICONS_OPTIONS, PANEL_STYLE_PRESETS, PREDEFINED_GROUPS, PREDEFINED_VAR_SCHEMA_BLOCKS } from "./editorConfig";
import Toggle from "../../components/forms/Toggle/Toggle";
import { IconJsxer } from "../../helpers/IconHelper";
import { FEATURE_CONTROLS_TYPES } from "../../helpers/FeatureControlsHelper";
import { CUSTOMISED_URL_MAP, VAR_TYPES } from "../../config/FeatureDashboardVarMap";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import ColorPicker from "../../components/forms/ColorPicker/ColorPicker";
import CustomisationControls from "../../components/CustomisationControls/CustomisationControls";
import { getFeatureAssetBaseUrl } from "../../helpers/FeatureAssets";
import CustomisationPreviewPopup from "./CustomisationPreviewPopup";

import DefaultSeeder from "./default_seeder.json";
import { getShortDateTimeString } from "../../helpers/Dates";
import FeatureControls from "../../components/FeatureControls/FeatureControls";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";

const SeederEditor = () => {
    const [authContext, setAuthContext] = useContext(AuthContext);

    const [busy, setBusy] = useState(false);
    useEffect(() => {
        skipAutoSave.current = true;
        liveControlsData_ref.current = {preview: {}};
        resetExpandedCollapsiblesRecord();
    }, [busy]);

    /* OPTIMISATION, KEEP TRACK OF CERTAIN COLLAPSIBLE SECTIONS FOR MORE EFFICIENT RENDERING */
    const expandedCollapsibles_ref = useRef([]);
    const [expandedCollapsibles, setExpandedCollapsibles] = useState([]);
    const onCollapseToggle = (id, expanded) => {
        if (expanded) {
            expandedCollapsibles_ref.current.push(id);
        } else {
            let i = expandedCollapsibles_ref.current.indexOf(id);
            if (i !== -1) {
                expandedCollapsibles_ref.current.splice(i, 1);
            }
        }
        setExpandedCollapsibles([...expandedCollapsibles_ref.current]);
    }
    const resetExpandedCollapsiblesRecord = () => {
        expandedCollapsibles_ref.current = [];
        setExpandedCollapsibles([]);
    }

    /* SEEDERS */
    const [selectedSeeder, setSelectedSeeder] = useState({index: 0});
    const selectSeeder = (e, id, item) => {
        setSelectedSeeder(item);
    }

    const [seederListDropDownData, setSeederListDropDownData] = useState([]);
    const getSeederList = () => {
        axios
        .get(getSeederListRoute, {withCredentials:true})
        .then(function (response) {
            console.log(response);
            // toast.success("Features Seeded!");
            // navigate("/");
            const seederListDropDownData = [{value: '', label: 'Select a seeder', index: 0}];
            for (let i = 0; i < response.data.seederList.length; i++) {
                let labelSplit = response.data.seederList[i].split('/');
                let label = labelSplit[labelSplit.length - 1].split('.')[0];
                seederListDropDownData.push({value: response.data.seederList[i], label: label});
            }
            setSeederListDropDownData(seederListDropDownData);
            setSelectedSeeder(seederListDropDownData[0]);
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    const getSeederData = () => {
        axios
        .get(getSeederFileRoute, {withCredentials:true, params: {fileIndex: selectedSeeder.index - 1}})
        .then(function (response) {
            console.log(response);
            const parsedData = JSON.parse(response.data.file);
            console.log(parsedData);
            setFeatureData({...parsedData});
            featureData_ref.current = parsedData;
            setEditingFeatureDbId('');
            setBusy(false);
            COLLAPSIBLES_CONTROL.resetToDefaults();

            scheduleTimedSave();
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
            setBusy(false);
        });
    }
    const editSeeder = (e) => {
        e.preventDefault();
        console.log(selectedSeeder);
        if (selectedSeeder.index !== 0) {
            setBusy(true);
            getSeederData();
        }
    }

    /* FEATURE DATA FROM DB */
    const [selectedFeature, setSelectedFeature] = useState({index: 0});
    const [editingFeatureDbId, setEditingFeatureDbId] = useState('');
    const selectFeature = (e, id, item) => {
        setSelectedFeature(item);
    }

    const [featureListDropDownData, setFeatureListDropDownData] = useState([]);
    const getFeatureList = () => {
        axios
        .get(getAllFeaturesListRoute, {withCredentials:true})
        .then(function (response) {
            console.log(response);
            const featureListDropDownData = [{value: '', label: 'Select a feature', index: 0}];
            for (let i = 0; i < response.data.featureList.length; i++) {
                let label = response.data.featureList[i].featureName;
                let value = response.data.featureList[i]._id;
                featureListDropDownData.push({value: value, label: label});
            }
            setFeatureListDropDownData(featureListDropDownData);
            setSelectedFeature(featureListDropDownData[0]);
        })
        .catch(function (error) {
            toast.error('Error - ' + error.response.data.message);
        });
    }

    const getFeatureData = () => {
        axios
        .get(getFeatureDataByIdRoute, {withCredentials:true, params: {featureId: selectedFeature.value}})
        .then(function (response) {
            console.log(response);
            const parsedData = response.data.feature;
            console.log(parsedData);
            setFeatureData({...parsedData});
            featureData_ref.current = parsedData;
            setEditingFeatureDbId(selectedFeature.value);
            setBusy(false);
            COLLAPSIBLES_CONTROL.resetToDefaults();

            scheduleTimedSave();
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
            setBusy(false);
        });
    }
    const editFeature = (e) => {
        e.preventDefault();
        console.log(selectedFeature);
        if (selectedFeature.index !== 0) {
            setBusy(true);
            getFeatureData();
        }
    }

    /* Save / Load to Local Storage */
    const getLocalStorageList = () => {
        let localStorageList = [{value: '', label: 'Select a feature', index: 0}];
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).indexOf('koko_fe__') === 0 && localStorage.key(i).indexOf('__date') === -1) {
                let dateString = '';
                let date = localStorage.getItem(localStorage.key(i) + '__date');
                if (date) {
                    dateString = ' (' + getShortDateTimeString(Number(date)) + ')';
                }
                localStorageList.push({value: localStorage.key(i).replace('koko_fe__', ''), label: localStorage.key(i).replace('koko_fe__', '') + dateString});
            }
        }
        return localStorageList;
    }
    const [localStorageList, setLocalStorageList] = useState(getLocalStorageList());

    const saveToLocalStorage = (e, auto = false) => {
        if (featureData_ref.current && featureData_ref.current.varKey) {
            let localStorageId = 'koko_fe__' + featureData_ref.current.varKey + (auto ? '__auto' : '');
            localStorageId = localStorageId.replace(/[^a-zA-Z0-9_]/g, '_');
            localStorage.setItem(localStorageId, JSON.stringify(featureData_ref.current));
            localStorage.setItem(localStorageId + '__date', Date.now());
            if (!showPreview) {
                setLocalStorageList(getLocalStorageList());
            }
        }
    }

    const deleteAutoSaves = () => {
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i).indexOf('koko_fe__') === 0 && localStorage.key(i).indexOf('__auto') !== -1 && localStorage.key(i).indexOf('__date') === -1) {
                localStorage.removeItem(localStorage.key(i));
                localStorage.removeItem(localStorage.key(i) + '__date');
            }
        }
        setLocalStorageList(getLocalStorageList());
    }

    const timedSaveToLocalStorage = () => {
        if (featureData_ref.current && featureData_ref.current.varKey) {
            let localStorageId = 'koko_fe__' + featureData_ref.current.varKey + '__auto_timed';
            localStorageId = localStorageId.replace(/[^a-zA-Z0-9_]/g, '_');
            localStorage.setItem(localStorageId, JSON.stringify(featureData_ref.current));
            localStorage.setItem(localStorageId + '__date', Date.now());
            if (!showPreview) {
                setLocalStorageList(getLocalStorageList());
            }
        }
    }
    // Timed save every 2 minutes
    const timedSaveTimeout = useRef(null);
    const scheduleTimedSave = () => {
        clearTimeout(timedSaveTimeout.current);
        timedSaveTimeout.current = setTimeout(() => {
            timedSaveToLocalStorage();
            scheduleTimedSave();
        }, 120000);
    }

    const [selectedLocalStorageItem, setSelectedLocalStorageItem] = useState({index: 0});
    const selectLocalStorageItem = (item) => {
        setSelectedLocalStorageItem(item);
    }
    const loadFromLocalStorage = (e) => {
        const varKey = selectedLocalStorageItem.value;
        skipAutoSave.current = true;
        setBusy(true);
        let data = localStorage.getItem('koko_fe__' + varKey);
        if (data) {
            data = JSON.parse(data);
            setFeatureData({...data});
            featureData_ref.current = data;
            setEditingFeatureDbId('');
            COLLAPSIBLES_CONTROL.resetToDefaults();
            skipAutoSave.current = true;
        }
        setBusy(false);

        generateLiveControlsValsDataForPreview();
        scheduleTimedSave();
    }

    /* Import JSON file */
    const jsonInputRef = useRef(null);
    const inputType = useRef('seeder');
    const chooseJsonFile = (e) => {
        e.preventDefault();
        if (!busy) {
            inputType.current = 'seeder';
            jsonInputRef.current.click();
        }
    }
    const handleJsonFile = (e) => {
        if (inputType.current === 'seeder') {
            setBusy(true);
            console.log('handleJsonFile', e);
            console.log('Json File', e.target.files[0]);

            try {
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    const importedData = JSON.parse(fileReader.result);
                    console.log(importedData);
                    setFeatureData({...importedData});
                    featureData_ref.current = importedData;
                    setEditingFeatureDbId('');
                    COLLAPSIBLES_CONTROL.resetToDefaults();
                    setBusy(false);

                    scheduleTimedSave();
                }
                fileReader.readAsText(e.target.files[0]);   
                // Clear the file input
                e.target.value = null;
            } catch (e) {
                toast.error('Error - ' + e.message);
                console.log(e);
                setBusy(false);
            }
        } else {
            if (inputType.current === 'defaults') {
                handleDefaultsJsonFile(e);
            }
        }
    }

    /* Import defaults from JSON file */
    const chooseDefaultsJsonFile = (e) => {
        e.preventDefault();
        if (!busy) {
            inputType.current = 'defaults';
            jsonInputRef.current.click();
        }
    }
    const handleDefaultsJsonFile = (e) => {
        setBusy(true);
        console.log('handleDefaultsJsonFile', e);
        console.log('Json File', e.target.files[0]);

        try {
            const fileReader = new FileReader();
            fileReader.onload = () => {
                const importedData = JSON.parse(fileReader.result);
                console.log(importedData);
                for (let tab in importedData) {
                    for (let group in importedData[tab]) {
                        for (let variable in importedData[tab][group]) {
                            const tabData = featureData_ref.current.customisation.find((tabData) => tabData.tabKey === tab);
                            if (tabData) {
                                const groupData = tabData.options.find((groupData) => groupData.varKey === group);
                                if (groupData) {
                                    const variableData = groupData.variables.find((variableData) => variableData.varName === variable);
                                    if (variableData) {
                                        for (let valSchemaProp in importedData[tab][group][variable][0]) {
                                            if (variableData && variableData.valSchema && variableData.valSchema[valSchemaProp]) {
                                                variableData.valSchema[valSchemaProp].default = JSON.parse(JSON.stringify(importedData[tab][group][variable][0][valSchemaProp]));
                                                if (variableData.valSchema[valSchemaProp].default.filename) {
                                                    // remove anything in the filename after __ retain the file extension after the .
                                                    variableData.valSchema[valSchemaProp].default.filename = variableData.valSchema[valSchemaProp].default.filename.split('__')[0] + '.' + variableData.valSchema[valSchemaProp].default.filename.split('.').pop();
                                                }
                                            }
                                        }
                                        variableData.vals = JSON.parse(JSON.stringify(importedData[tab][group][variable]));
                                        // go through each val checking for filename props and remove anything in the filename after __ retain the file extension after the .
                                        for (let i = 0; i < variableData.vals.length; i++) {
                                            let val = variableData.vals[i];
                                            for (let valSchemaProp in val) {
                                                if (val[valSchemaProp].filename) {
                                                    val[valSchemaProp].filename = val[valSchemaProp].filename.split('__')[0] + '.' + val[valSchemaProp].filename.split('.').pop();
                                                }
                                            }
                                            val.isDefault = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                setFeatureData({...featureData_ref.current});
                setBusy(false);
            }
            fileReader.readAsText(e.target.files[0]);
            // Clear the file input
            e.target.value = null;
        } catch (e) {
            toast.error('Error - ' + e.message);
            console.log(e);
            setBusy(false);
        }
    }

    /* The data we are editing */
    const featureData_ref = useRef(null);
    const [featureData, setFeatureData] = useState(null);
    
    const skipAutoSave = useRef(true);
    useEffect(() => {
        if (!skipAutoSave.current) {
            saveToLocalStorage(null, true);
        } else {
            skipAutoSave.current = false;
        }
        if (featureData) {
            generateCustomisationPreviewData(true);
        }
    }, [featureData]);

    const clearFeatureData = () => {
        featureData_ref.current = JSON.parse(JSON.stringify(DefaultSeeder));
        /* featureData_ref.current = {
            commands: [],
            defaultVars: [],
            customisation: [],
        };
        addCommand();
        featureData_ref.current.commands[0].isPrimaryTrigger = true;
        featureData_ref.current.commands[0].command = "START_FEATURE";
        addCommand();
        featureData_ref.current.commands[1].isEndTrigger = true;
        featureData_ref.current.commands[1].command = "END_FEATURE"; */
        setFeatureData({...featureData_ref.current});
        setEditingFeatureDbId('');
    }

    const changeObjectValue = (object, prop, newValue, parse = true, forceRerender = false) => {
        // console.log('Changing value: ', object, prop, newValue, parse, forceRerender);
        if (prop === 'default') {
            const controlData = getControlTypeData(getCustomisationControlTypes(), object.type);
            // console.log('Control Data: ', controlData);
            if (typeof controlData.fixedDefaultValue !== 'undefined') {
                newValue = controlData.fixedDefaultValue;
            }
        }
        const parsedVal = parse ? parseValue(newValue) : newValue;
        if (object[prop] !== parsedVal) {
            object[prop] = parsedVal;
            if (forceRerender || (!forceRerender && !showLiveEditVarFamilyPopup)) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }

    const parseValue = (value) => {
        try {
            return JSON.parse(value.toString());
        } catch (e) {
            return value;
        }
    }

    const convertFilenameToObject = (valSchema, prop, variable, varKey) => {
        // console.log('Converting filename to object: ', valSchema, prop, variable, varKey);
        if (valSchema[prop]) {
            const type = valSchema[prop].type.toLowerCase();
            if (CUSTOMISED_URL_MAP.hasOwnProperty(type)) {
                // Reformat legacy string based filenames to be objects...
                // SrcUrl has to be part of the object, meaning all feature customisations are going to break...
                let filename = variable[varKey];
                if (filename.updated !== true) {
                    if (typeof filename === 'string' || typeof filename === 'undefined' || filename === null) {
                        filename = { filename: (variable[varKey] || "") };
                        variable[varKey] = filename;
                        filename.isDefault = true;
                    }

                    const srcUrl = filename.filename !== "" ?
                        getFeatureAssetBaseUrl(true) + featureData.defaultAssetFolder + '/' + CUSTOMISED_URL_MAP[type].dir + '/' + filename.filename
                        : null;

                    filename.srcUrl = srcUrl;
                    filename.originalFile = filename.filename;
                    filename.type = CUSTOMISED_URL_MAP[type].dir;
                }

                // console.log('Converted filename to object: ', filename);
            }
        }
    }

    /* The data we need when developing our feature */
    const logOutFeatureDataForDev = () => {
        console.log('--- FEATURE DATA FOR DEV ---');
        console.log('>> The following data structures are useful when developing your feature / disruptor <<');

        const liveControlData = {};
        for (let i = 0; i < featureData.defaultVars.length; i++) {
            const group = featureData.defaultVars[i];
            liveControlData[group.varKey] = {};
            for (let j = 0; j < group.variables.length; j++) {
                const variable = group.variables[j];
                liveControlData[group.varKey][variable.varName] = variable.defaultVal ? JSON.parse(JSON.stringify(variable.defaultVal)) : variable.defaultVal;
            }
        }

        console.log('Live Control Data (copy this structure into your Bootstrap.js state): ', liveControlData);

        // Find all image file, videos, audio, fonts object paths from customisations
        const imageFilesToPreload = [];
        const videoFilesToPreload = [];
        const audioFilesToPreload = [];
        const fontFilesToPreload = [];
        const assetsDependancies = [];
        const defaultImageFiles = [];
        const defaultVideoFiles = [];
        const defaultAudioFiles = [];
        for (let i = 0; i < featureData.customisation.length; i++) {
            const tab = featureData.customisation[i];
            for (let j = 0; j < tab.options.length; j++) {
                const group = tab.options[j];
                for (let k = 0; k < group.variables.length; k++) {
                    const variable = group.variables[k];
                    for (let valSchemaProp in variable.valSchema) {
                        const varPath = tab.tabKey + '.' + group.varKey + '.' + variable.varName + '.' + valSchemaProp;
                        let preloadableAsset = false;
                        if (variable.valSchema[valSchemaProp].type.toLowerCase() === FEATURE_CONTROLS_TYPES.ImageUpload.type.toLocaleLowerCase()) {
                            imageFilesToPreload.push(varPath);
                            preloadableAsset = true;

                            const defaultImage = variable.valSchema[valSchemaProp].default;
                            if (defaultImage && defaultImage.filename && defaultImage.filename !== '' && defaultImageFiles.indexOf(defaultImage.filename) === -1) {
                                defaultImageFiles.push(defaultImage.filename);
                            }
                            for (let i = 0; i < variable.vals.length; i++) {
                                const val = variable.vals[i][valSchemaProp];
                                if (val.filename && val.filename !== '' && defaultImageFiles.indexOf(val.filename) === -1) {
                                    defaultImageFiles.push(val.filename);
                                }
                            }
                        }
                        if (variable.valSchema[valSchemaProp].type.toLowerCase() === FEATURE_CONTROLS_TYPES.VideoUpload.type.toLocaleLowerCase()) {
                            videoFilesToPreload.push(varPath);
                            preloadableAsset = true;

                            const defaultVideo = variable.valSchema[valSchemaProp].default;
                            if (defaultVideo && defaultVideo.filename && defaultVideo.filename !== '' && defaultVideoFiles.indexOf(defaultVideo.filename) === -1) {
                                defaultVideoFiles.push(defaultVideo.filename);
                            }
                            for (let i = 0; i < variable.vals.length; i++) {
                                const val = variable.vals[i][valSchemaProp];
                                if (val.filename && val.filename !== '' && defaultVideoFiles.indexOf(val.filename) === -1) {
                                    defaultVideoFiles.push(val.filename);
                                }
                            }
                        }
                        if (variable.valSchema[valSchemaProp].type.toLowerCase() === FEATURE_CONTROLS_TYPES.AudioUpload.type.toLocaleLowerCase()) {
                            audioFilesToPreload.push(varPath);
                            preloadableAsset = true;

                            const defaultAudio = variable.valSchema[valSchemaProp].default;
                            if (defaultAudio && defaultAudio.filename && defaultAudio.filename !== '' && defaultAudioFiles.indexOf(defaultAudio.filename) === -1) {
                                defaultAudioFiles.push(defaultAudio.filename);
                            }
                            for (let i = 0; i < variable.vals.length; i++) {
                                const val = variable.vals[i][valSchemaProp];
                                if (val.filename && val.filename !== '' && defaultAudioFiles.indexOf(val.filename) === -1) {
                                    defaultAudioFiles.push(val.filename);
                                }
                            }
                        }
                        if (variable.valSchema[valSchemaProp].type.toLowerCase() === FEATURE_CONTROLS_TYPES.Font.type.toLocaleLowerCase()) {
                            fontFilesToPreload.push(varPath);
                            preloadableAsset = true;
                        }
                        // Does this var have a displayCondition? If so we can add that as a dependancy so we only preload what we need based on those conditions
                        if (preloadableAsset) {
                            const dependancy = {
                                assetLocation: varPath,
                                "dependsOn": []
                            };
                            if (variable.valSchema[valSchemaProp].displayCondition && variable.valSchema[valSchemaProp].displayCondition.length > 0) {
                                for (let l = 0; l < variable.valSchema[valSchemaProp].displayCondition.length; l++) {
                                    const condition = variable.valSchema[valSchemaProp].displayCondition[l];
                                    let ourVar = condition.var;
                                    let varSplit = ourVar.split('.');
                                    if (varSplit.length < 4) {
                                        ourVar = tab.tabKey + '.' + (varSplit.length < 3 ? group.varKey : varSplit.shift()) + '.' + (varSplit.length < 2 ? variable.varName : varSplit.shift()) + '.' + varSplit[0];
                                    }
                                    const ourVal = String(condition.val);
                                    dependancy.dependsOn.push({
                                        var: ourVar,
                                        val: ourVal,
                                        evalCondition: condition.evalCondition
                                    });
                                }
                            }
                            if (variable.displayCondition && variable.displayCondition.length > 0) {
                                for (let l = 0; l < variable.displayCondition.length; l++) {
                                    const condition = variable.displayCondition[l];
                                    let ourVar = condition.var;
                                    let varSplit = ourVar.split('.');
                                    if (varSplit.length < 4) {
                                        ourVar = tab.tabKey + '.' + (varSplit.length < 3 ? group.varKey : varSplit.shift()) + '.' + (varSplit.length < 2 ? variable.varName : varSplit.shift()) + '.' + varSplit[0];
                                    }
                                    const ourVal = String(condition.val);
                                    dependancy.dependsOn.push({
                                        var: ourVar,
                                        val: ourVal,
                                        evalCondition: condition.evalCondition
                                    });
                                }
                            }
                            if (dependancy.dependsOn.length > 0) {
                                assetsDependancies.push(dependancy);
                            }
                        }
                    }
                }
            }
        }

        console.log('Image Files to preload: ', imageFilesToPreload);
        console.log('Video Files to preload: ', videoFilesToPreload);
        console.log('Audio Files to preload: ', audioFilesToPreload);
        console.log('Font Files to preload: ', fontFilesToPreload);
        console.log('Assets Dependancies: ', assetsDependancies);

        console.log('>> The following files need uploading to s3 in your default assets folder for this feature <<');
        console.log('Default Image Files: ', defaultImageFiles);
        console.log('Default Video Files: ', defaultVideoFiles);
        console.log('Default Audio Files: ', defaultAudioFiles);

        console.log('--- END OF FEATURE DATA FOR DEV ---');
    }

    /* Drop downs */
    const getSelectedIndex = (dropDownData, object, prop) => {
        let index = 0;
        for (let i = 0; i < dropDownData.length; i++) {
            if (dropDownData[i].value === object[prop]) {
                index = i;
                break;
            }
            if (object[prop] && object[prop].toLowerCase) {
                if (dropDownData[i].value === object[prop].toLowerCase()) {
                    index = i;
                    break;
                }
            
            }
        }
        return index;
    }

    const setValFromDropDown = (item, object, prop) => {
        object[prop] = item.value;
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const controlTypes_ref = useRef(null);
    const getControlTypes = () => {
        if (controlTypes_ref.current === null) {
            controlTypes_ref.current = [];
            for (let key in FEATURE_CONTROLS_TYPES) {
                if (FEATURE_CONTROLS_TYPES[key].liveControl) {
                    controlTypes_ref.current.push({value: FEATURE_CONTROLS_TYPES[key].type, label: FEATURE_CONTROLS_TYPES[key].label, defaultVarType: FEATURE_CONTROLS_TYPES[key].defaultVarType});
                }
            }
        }
        return controlTypes_ref.current;
    }

    const customisationControlTypes_ref = useRef(null);
    const getCustomisationControlTypes = () => {
        if (customisationControlTypes_ref.current === null) {
            customisationControlTypes_ref.current = [];
            for (let key in FEATURE_CONTROLS_TYPES) {
                customisationControlTypes_ref.current.push({value: FEATURE_CONTROLS_TYPES[key].type, ...FEATURE_CONTROLS_TYPES[key]});
            }
        }
        return customisationControlTypes_ref.current;
    }

    const getControlTypeData = (controlsList, type) => {
        for (let i = 0; i < controlsList.length; i++) {
            if (controlsList[i].value === type) {
                return controlsList[i];
            }
            if (type && type.toLowerCase) {
                if (controlsList[i].value === type.toLowerCase()) {
                    return controlsList[i];
                }
            
            }
        }
        return null;
    }

    const varTypes_ref = useRef(null);
    const getVarTypes = () => {
        if (varTypes_ref.current === null) {
            varTypes_ref.current = [];
            for (let key in VAR_TYPES) {
                varTypes_ref.current.push({value: VAR_TYPES[key], label: VAR_TYPES[key]});
            }
        }
        return varTypes_ref.current;
    }

    const getEvalConditionIndex = (value) => {
        let index = 0;
        for (let i = 0; i < EVAL_CONDITION_ITEMS.length; i++) {
            if (EVAL_CONDITION_ITEMS[i].value === value) {
                index = i;
                break;
            }
        }
        return index;
    }

    const getCommandsDropdownData = () => {
        let commandsDropdownData = [{value: -1, label: 'Command Not Linked'}];
        for (let i = 0; i < featureData_ref.current.commands.length; i++) {
            commandsDropdownData.push({value: i, label: featureData_ref.current.commands[i].command});
        }
        return commandsDropdownData;
    }

    const findCommandDropDownIndex = (command) => {
        let dropDownData = getCommandsDropdownData();
        for (let i = 0; i < dropDownData.length; i++) {
            if (command === dropDownData[i].value) {
                return i;
            }
        }
        return 0;
    }

    const getCommandLinkage = (command) => {
        if (typeof command === 'number') {
            return {
                isLinked: true,
                commandIndex: command,
                command: featureData_ref.current.commands[command].command,
            }
        } else {
            return {
                isLinked: false,
                commandIndex: -1,
                command: command,
            }
        }
    }

    /* Commands */
    const removeCommand = (e, index) => {
        featureData_ref.current.commands.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }

    const addCommand = (e) => {
        featureData_ref.current.commands.push({
            title: '',
            description: '',
            command: '',
            isTestCommand: false,
            isHashtagEnabled: false,
            defaultHashtag: '',
            isPrimaryTrigger: false,
            triggerParam: 0,
            isEndTrigger: false,
            previewQuickCommand: false,
            previewQuickIcon: '',
            previewQuickParam: '',
            param: ''
        });
        setFeatureData({...featureData_ref.current});
    }

    /* Live Controls */
    const liveControlsData_ref = useRef({preview: {}});
    const generateLiveControlsValsDataForPreview = () => {
        const previewData = liveControlsData_ref.current.preview;
        for (let i = 0; i < featureData_ref.current.defaultVars.length; i++) {
            const group = featureData_ref.current.defaultVars[i];
            if (typeof previewData[group.varKey] === 'undefined') {
                previewData[group.varKey] = {};
            }
            for (let j = 0; j < group.variables.length; j++) {
                const variable = group.variables[j];
                if (typeof previewData[group.varKey][variable.varName] === 'undefined') {
                    previewData[group.varKey][variable.varName] = variable.defaultVal ? JSON.parse(JSON.stringify(variable.defaultVal)) : variable.defaultVal;
                }
            }
        }
        console.log("--- GENERATED LIVE CONTROLS DATA ---", previewData);
        return liveControlsData_ref.current;
    }


    const removeVarGroup = (e, index) => {
        featureData_ref.current.defaultVars.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }
    const addVarGroup = (e) => {
        featureData_ref.current.defaultVars.push({
            varKey: '',
            groupTitle: '',
            startExpanded: false,
            variables: []
        });
        setFeatureData({...featureData_ref.current});
    }

    const copiedVarGroupData = useRef(null);
    const copyVarGroup = (e, index) => {
        copiedVarGroupData.current = JSON.parse(JSON.stringify(featureData_ref.current.defaultVars[index]));
        toast.success('Group copied');
    }
    const pasteVarGroup = (e) => {
        if (copiedVarGroupData.current) {
            featureData_ref.current.defaultVars.push(JSON.parse(JSON.stringify(copiedVarGroupData.current)));
            setFeatureData({...featureData_ref.current});
        }
    }

    const moveVarGroup = (e, index, direction) => {
        if ( (direction === -1 && index > 0) || (direction === 1 && index < featureData_ref.current.defaultVars.length - 1)) {
            let temp = featureData_ref.current.defaultVars[index + direction];
            featureData_ref.current.defaultVars[index + direction] = featureData_ref.current.defaultVars[index];
            featureData_ref.current.defaultVars[index] = temp;
        }
        setFeatureData({...featureData_ref.current});
    }

    const removeVariable = (e, groupIndex, index) => {
        featureData_ref.current.defaultVars[groupIndex].variables.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }
    const addVariable = (e, groupIndex) => {
        featureData_ref.current.defaultVars[groupIndex].variables.push({
            varName: '',
            description: '',
            label: '',
            defaultVal: '',
            chainRestrictedValue: '',
            demoModeOnly: false,
            varType: 'String',
            maxLength: 0,
            maxVal: 0,
            minVal: 0,
            valOptions: [{label: 'Option 1', val: 'option1'}],
            dashboardDisplayType: 'input',
            displayCondition: []
        });
        setFeatureData({...featureData_ref.current});
    }

    const moveVariable = (e, groupIndex, index, direction) => {
        if ( (direction === -1 && index > 0) || (direction === 1 && index < featureData_ref.current.defaultVars[groupIndex].variables.length - 1)) {
            let temp = featureData_ref.current.defaultVars[groupIndex].variables[index + direction];
            featureData_ref.current.defaultVars[groupIndex].variables[index + direction] = featureData_ref.current.defaultVars[groupIndex].variables[index];
            featureData_ref.current.defaultVars[groupIndex].variables[index] = temp;
        }
        setFeatureData({...featureData_ref.current});
    }

    const copiedVarData = useRef(null);
    const copyVariable = (e, groupIndex, index) => {
        copiedVarData.current = JSON.parse(JSON.stringify(featureData_ref.current.defaultVars[groupIndex].variables[index]));
        toast.success('Variable copied');
    }
    const pasteVariable = (e, groupIndex) => {
        if (copiedVarData.current) {
            featureData_ref.current.defaultVars[groupIndex].variables.push(JSON.parse(JSON.stringify(copiedVarData.current)));
            setFeatureData({...featureData_ref.current});
        }
    }

    const removeDisplayCondition = (e, groupIndex, varIndex, index) => {
        featureData_ref.current.defaultVars[groupIndex].variables[varIndex].displayCondition.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }
    const addDisplayCondition = (e, groupIndex, varIndex) => {
        if (!Array.isArray(featureData_ref.current.defaultVars[groupIndex].variables[varIndex].displayCondition)) {
            featureData_ref.current.defaultVars[groupIndex].variables[varIndex].displayCondition = [];
        }
        featureData_ref.current.defaultVars[groupIndex].variables[varIndex].displayCondition.push({
            var: '',
            val: '',
            evalCondition: '=='
        });
        setFeatureData({...featureData_ref.current});
    }

    const removeDisabledCondition = (e, groupIndex, varIndex, index) => {
        featureData_ref.current.defaultVars[groupIndex].variables[varIndex].disabledCondition.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }
    const addDisabledCondition = (e, groupIndex, varIndex) => {
        if (!Array.isArray(featureData_ref.current.defaultVars[groupIndex].variables[varIndex].disabledCondition)) {
            featureData_ref.current.defaultVars[groupIndex].variables[varIndex].disabledCondition = [];
        }
        featureData_ref.current.defaultVars[groupIndex].variables[varIndex].disabledCondition.push({
            var: '',
            val: '',
            evalCondition: '=='
        });
        setFeatureData({...featureData_ref.current});
    }

    const removeButtonDisplayCondition = (e, button, index) => {
        button.displayCondition.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }
    const addButtonDisplayCondition = (e, button) => {
        if (!Array.isArray(button.displayCondition)) {
            button.displayCondition = [];
        }
        button.displayCondition.push({
            var: '',
            val: '',
            evalCondition: '=='
        });
        setFeatureData({...featureData_ref.current});
    }

    const removeButtonDisabledCondition = (e, button, index) => {
        button.disabledCondition.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }
    const addButtonDisabledCondition = (e, button) => {
        if (!Array.isArray(button.disabledCondition)) {
            button.disabledCondition = [];
        }
        button.disabledCondition.push({
            var: '',
            val: '',
            evalCondition: '=='
        });
        setFeatureData({...featureData_ref.current});
    }

    const removeLiveGroupDisplayCondition = (e, groupIndex, index) => {
        featureData_ref.current.defaultVars[groupIndex].displayCondition.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }
    const addLiveGroupDisplayCondition = (e, groupIndex) => {
        if (!Array.isArray(featureData_ref.current.defaultVars[groupIndex].displayCondition)) {
            featureData_ref.current.defaultVars[groupIndex].displayCondition = [];
        }
        featureData_ref.current.defaultVars[groupIndex].displayCondition.push({
            var: '',
            val: '',
            evalCondition: '=='
        });
        setFeatureData({...featureData_ref.current});
    }

    const removeCommandButton = (e, groupIndex, index) => {
        featureData_ref.current.defaultVars[groupIndex].commandButtons.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }
    const addCommandButton = (e, groupIndex) => {
        if (typeof featureData_ref.current.defaultVars[groupIndex].commandButtons === 'undefined') {
            featureData_ref.current.defaultVars[groupIndex].commandButtons = [];
        }
        featureData_ref.current.defaultVars[groupIndex].commandButtons.push({
            label: '',
            command: '',
            index: 0,
            mainControl: false,
            useIcon: false,
            icon: '',
            width: 0
        });
        setFeatureData({...featureData_ref.current});
    }

    const addValOption = (e, groupIndex, varIndex) => {
        featureData_ref.current.defaultVars[groupIndex].variables[varIndex].valOptions.push({label: '', val: ''});
        setFeatureData({...featureData_ref.current});
    }

    const moveValOption = (valOptionsArray, varIndex, direction) => {
        if ( (direction === -1 && varIndex > 0) || (direction === 1 && varIndex < valOptionsArray.length - 1)) {
            let temp = valOptionsArray[varIndex + direction];
            valOptionsArray[varIndex + direction] = valOptionsArray[varIndex];
            valOptionsArray[varIndex] = temp;
        }
        setFeatureData({...featureData_ref.current});
    }

    /* Customisations */
    const [schemaHighlighKey, setSchemaHighlightKey] = useState('');

    // Generate preview data for the customisation controls these need to be pulled from the vals arrays
    // Format:
    // {
    //      current: {
    //          dummyID: {
    //              ...Generated data
    //          }
    //      },
    //      default: {
    //          dummyID: {
    //              ...The same generated data
    //          }
    //      }
    // }
    const preGeneratedPreviewData = useRef(null);
    const generateCustomisationPreviewData = (force = true) => {
        if (force || !preGeneratedPreviewData.current) {
            const previewData = {};
            for (let i = 0; i < featureData_ref.current.customisation.length; i++) {
                const tabData = {};
                previewData[featureData_ref.current.customisation[i].tabKey] = tabData;
                for (let j = 0; j < featureData_ref.current.customisation[i].options.length; j++) {
                    tabData[featureData_ref.current.customisation[i].options[j].varKey] = {};
                    for (let k = 0; k < featureData_ref.current.customisation[i].options[j].variables.length; k++) {
                        let variable = featureData_ref.current.customisation[i].options[j].variables[k];
                        tabData[featureData_ref.current.customisation[i].options[j].varKey][variable.varName] = [];
                        for (let l = 0; l < variable.vals.length; l++) {
                            tabData[featureData_ref.current.customisation[i].options[j].varKey][variable.varName].push(JSON.parse(JSON.stringify(variable.vals[l])));
                        }
                    }
                }
            }
            console.log("--- GENERATED PREVIEW DATA ---", previewData);
            preGeneratedPreviewData.current = {
                current: {previewData: previewData},
                default: {previewData: previewData},
            }
        }
        return preGeneratedPreviewData.current;
    }

                    

    const addCustomisationTab = (e) => {
        featureData_ref.current.customisation.push({
            tabTitle: 'New Tab ' + (featureData_ref.current.customisation.length + 1),
            tabKey: 'new_tab_' + (featureData_ref.current.customisation.length + 1),
            themeable: false,
            options: []
        });
        setFeatureData({...featureData_ref.current});
    }

    const removeCustomisationTab = (e, index) => {
        featureData_ref.current.customisation.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }

    const moveCustomisationTab = (e, index, direction) => {
        if ( (direction === -1 && index > 0) || (direction === 1 && index < featureData_ref.current.customisation.length - 1)) {
            let temp = featureData_ref.current.customisation[index + direction];
            featureData_ref.current.customisation[index + direction] = featureData_ref.current.customisation[index];
            featureData_ref.current.customisation[index] = temp;
        }
        setFeatureData({...featureData_ref.current});
    }

    const copiedTabData = useRef(null);
    const copyCustomisationTab = (e, index) => {
        copiedTabData.current = JSON.parse(JSON.stringify(featureData_ref.current.customisation[index]));
        toast.success('Tab copied');
    }
    const pasteCustomisationTab = (e) => {
        if (copiedTabData.current) {
            featureData_ref.current.customisation.push(JSON.parse(JSON.stringify(copiedTabData.current)));
            setFeatureData({...featureData_ref.current});
        }
    }

    const addCustomisationGroup = (e, index) => {
        featureData_ref.current.customisation[index].options.push({
            groupTitle: 'New Group ' + (featureData_ref.current.customisation[index].options.length + 1),
            varKey: 'new_group_' + (featureData_ref.current.customisation[index].options.length + 1),
            forceWidth: '',
            padEnd: '',
            displayCondition: [],
            advancedMode: false,
            variables: []
        });
        setFeatureData({...featureData_ref.current});
    }

    const removeCustomisationGroup = (e, tabIndex, groupIndex) => {
        featureData_ref.current.customisation[tabIndex].options.splice(groupIndex, 1);
        setFeatureData({...featureData_ref.current});
    }

    const moveCustomisationGroup = (e, tabIndex, groupIndex, direction) => {
        if ( (direction === -1 && groupIndex > 0) || (direction === 1 && groupIndex < featureData_ref.current.customisation[tabIndex].options.length - 1)) {
            let temp = featureData_ref.current.customisation[tabIndex].options[groupIndex + direction];
            featureData_ref.current.customisation[tabIndex].options[groupIndex + direction] = featureData_ref.current.customisation[tabIndex].options[groupIndex];
            featureData_ref.current.customisation[tabIndex].options[groupIndex] = temp;
        }
        setFeatureData({...featureData_ref.current});
    }

    const copiedGroupData = useRef(null);
    const copyCustomisationGroup = (e, tabIndex, groupIndex) => {
        copiedGroupData.current = JSON.parse(JSON.stringify(featureData_ref.current.customisation[tabIndex].options[groupIndex]));
        toast.success('Group copied');
    }
    const pasteCustomisationGroup = (e, tabIndex) => {
        if (copiedGroupData.current) {
            featureData_ref.current.customisation[tabIndex].options.push(JSON.parse(JSON.stringify(copiedGroupData.current)));
            setFeatureData({...featureData_ref.current});
        }
    }

    const predefinedGroupDropDownOptions = [];
    for (let key in PREDEFINED_GROUPS) {
        predefinedGroupDropDownOptions.push({value: key, label: key, index: 0});
    }
    const selectedPredefinedGroupKey = useRef(predefinedGroupDropDownOptions[0].value);
    const [selectedPredefinedGroupIndex, setSelectedPredefinedGroupIndex] = useState(0);
    const selectPredefinedGroup = (item) => {
        selectedPredefinedGroupKey.current = item.value;
        setSelectedPredefinedGroupIndex(item.index);
    }
    const addPredefinedCustomisationGroup = (e, tabIndex, predefinedGroupKey = null) => {
        if (!predefinedGroupKey) {
            predefinedGroupKey = selectedPredefinedGroupKey.current;
        }
        if (PREDEFINED_GROUPS[predefinedGroupKey]) {
            const groupData = PREDEFINED_GROUPS[predefinedGroupKey];
            featureData_ref.current.customisation[tabIndex].options.push(JSON.parse(JSON.stringify(groupData)));
            setFeatureData({...featureData_ref.current});
            generateCustomisationPreviewData(true);
        }
    }

    const addCustomisationGroupDisplayCondition = (e, tabIndex, groupIndex) => {
        if (!featureData_ref.current.customisation[tabIndex].options[groupIndex].displayCondition) {
            featureData_ref.current.customisation[tabIndex].options[groupIndex].displayCondition = [];
        }
        featureData_ref.current.customisation[tabIndex].options[groupIndex].displayCondition.push({
            var: '',
            val: '',
            evalCondition: '=='
        });
        setFeatureData({...featureData_ref.current});
    }

    const removeCustomisationGroupDisplayCondition = (e, tabIndex, groupIndex, index) => {
        featureData_ref.current.customisation[tabIndex].options[groupIndex].displayCondition.splice(index, 1);
        setFeatureData({...featureData_ref.current});
    }

    const copiedGroupDisplayConditionData = useRef(null);
    const copyCustomisationGroupDisplayConditionsList = (e, tabIndex, groupIndex) => {
        copiedGroupDisplayConditionData.current = JSON.parse(JSON.stringify(featureData_ref.current.customisation[tabIndex].options[groupIndex].displayCondition));
        toast.success('Display Conditions copied');
    }
    const pasteCustomisationGroupDisplayConditionsList = (e, tabIndex, groupIndex) => {
        if (copiedGroupDisplayConditionData.current) {
            if (typeof featureData_ref.current.customisation[tabIndex].options[groupIndex].displayCondition === 'undefined') {
                featureData_ref.current.customisation[tabIndex].options[groupIndex].displayCondition = [];
            }
            for (let i = 0; i < copiedGroupDisplayConditionData.current.length; i++) {
                featureData_ref.current.customisation[tabIndex].options[groupIndex].displayCondition.push(JSON.parse(JSON.stringify(copiedGroupDisplayConditionData.current[i])));
            }
            setFeatureData({...featureData_ref.current});
        }
    }

    const addCustomisationVariable = (e, tabIndex, groupIndex) => {
        featureData_ref.current.customisation[tabIndex].options[groupIndex].variables.push({
            label: 'New Var Family ' + (featureData_ref.current.customisation[tabIndex].options[groupIndex].variables.length + 1),
            description: '',
            varName: 'new_var_family_' + (featureData_ref.current.customisation[tabIndex].options[groupIndex].variables.length + 1),
            forceWidth: '',
            groupWidth: '',
            padEnd: '',
            addBox: false,
            addOutlineBox: false,
            boxColour: null,
            addVarBox: false,
            addVarOutlineBox: false,
            varBoxColour: null,
            preventDelete: false,
            allowMultiple: false,
            maxMultiples: -1,
            chainedMaxMultiples: -1,
            valSchema: {},
            vals: [],
            presets: [],
            displayCondition: [],
        });
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const removeCustomisationVariable = (e, tabIndex, groupIndex, varIndex) => {
        featureData_ref.current.customisation[tabIndex].options[groupIndex].variables.splice(varIndex, 1);
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const moveCustomisationVariable = (e, tabIndex, groupIndex, varIndex, direction) => {
        if ( (direction === -1 && varIndex > 0) || (direction === 1 && varIndex < featureData_ref.current.customisation[tabIndex].options[groupIndex].variables.length - 1)) {
            let temp = featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex + direction];
            featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex + direction] = featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex];
            featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex] = temp;
        }
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const copiedCustomisationVarData = useRef(null);
    const copyCustomisationVariable = (e, tabIndex, groupIndex, varIndex) => {
        copiedCustomisationVarData.current = JSON.parse(JSON.stringify(featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex]));
        toast.success('Variable copied');
    }
    const pasteCustomisationVariable = (e, tabIndex, groupIndex) => {
        if (copiedCustomisationVarData.current) {
            featureData_ref.current.customisation[tabIndex].options[groupIndex].variables.push(JSON.parse(JSON.stringify(copiedCustomisationVarData.current)));
            if (!showLiveEditVarFamilyPopup) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }

    const addCustomsationVariableDisplayCondition = (e, tabIndex, groupIndex, varIndex) => {
        featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex].displayCondition.push({
            var: '',
            val: '',
            evalCondition: '=='
        });
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const removeCustomisationVariableDisplayCondition = (e, tabIndex, groupIndex, varIndex, index) => {
        featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex].displayCondition.splice(index, 1);
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const copiedCustomisationVarDisplayConditionData = useRef(null);
    const copyCustomisationVariableDisplayConditionsList = (e, tabIndex, groupIndex, varIndex) => {
        console.log('Copy display conditions for: ', tabIndex, groupIndex, varIndex, featureData_ref.current.customisation[tabIndex].options[groupIndex].variables);
        copiedCustomisationVarDisplayConditionData.current = JSON.parse(JSON.stringify(featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex].displayCondition));
        console.log('Copied display conditions: ', copiedCustomisationVarDisplayConditionData.current);
        toast.success('Display Conditions copied');
    }
    const pasteCustomisationVariableDisplayConditionsList = (e, tabIndex, groupIndex, varIndex) => {
        if (copiedCustomisationVarDisplayConditionData.current) {
            if (typeof featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex].displayCondition === 'undefined') {
                featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex].displayCondition = [];
            }
            for (let i = 0; i < copiedCustomisationVarDisplayConditionData.current.length; i++) {
                featureData_ref.current.customisation[tabIndex].options[groupIndex].variables[varIndex].displayCondition.push(JSON.parse(JSON.stringify(copiedCustomisationVarDisplayConditionData.current[i])));
            }
            if (!showLiveEditVarFamilyPopup) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }

    const stylePresetsDropDownData = useRef(null);
    const getVarBoxStylePresetsDropDownData = () => {
        if (stylePresetsDropDownData.current === null) {
            stylePresetsDropDownData.current = [];
            for (let key in PANEL_STYLE_PRESETS) {
                stylePresetsDropDownData.current.push({value: key, label: PANEL_STYLE_PRESETS[key].name});
            }
        }
        return stylePresetsDropDownData.current;
    }

    const selectedVarBoxStyle = useRef(PANEL_STYLE_PRESETS[getVarBoxStylePresetsDropDownData()[0].value]);
    const [selectedVarBoxStyleIndex, setSelectedVarBoxStyleIndex] = useState(0);
    const selectVarBoxStyle = (e, id, item) => {
        selectedVarBoxStyle.current = PANEL_STYLE_PRESETS[item.value];
        setSelectedVarBoxStyleIndex(item.index);
    }

    const applyCustomisationVariableBoxSettings = (variable, settings = null) => {
        if (settings === null) {
            settings = selectedVarBoxStyle.current.style;
        }
        for (let key in settings) {
            variable[key] = settings[key];
        }
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const getDefaultValsObject = (valSchema) => {
        let defaultVals = {isDefault: true};
        for (let key in valSchema) {
            const featureControlTypeData = getControlTypeData(getControlTypes(), valSchema[key].type);
            if (typeof valSchema[key].default !== 'undefined' && featureControlTypeData && !featureControlTypeData.isCosmetic) {
                defaultVals[key] = valSchema[key].default;
            }
        }
        return defaultVals;
    }

    const changeSchemaKey = (variable, oldKey, newKey) => {
        if (newKey === oldKey) {
            return;
        }

        const valSchema = variable.valSchema;

        // check if the new key already exists
        if (valSchema[newKey]) {
            toast.error('Key already exists');
            newKey = generateValSchemaVarKey(variable, newKey + '_');
        }

        let newSchema = {};
        for (let key in valSchema) {
            if (key === oldKey) {
                newSchema[newKey] = valSchema[key];
                newSchema[newKey].__ed__schemaKey = newKey;
            } else {
                newSchema[key] = valSchema[key];
            }
        }
        variable.valSchema = newSchema;
        // Update the vals array to match the new schema
        for (let i = 0; i < variable.vals.length; i++) {
            variable.vals[i][newKey] = variable.vals[i][oldKey];
        }
        
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const addCustomisationValOption = (e, valOptionsArray) => {
        valOptionsArray.push({label: '', val: ''});
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const generateValSchemaVarKey = (variable, keyBaseName = 'new_var_') => {
        let newVarKey = keyBaseName + (Object.keys(variable.valSchema).length + 1);
        let vkOff = 1;
        let keyExists = true;
        while (keyExists) {
            keyExists = false;
            for (let key in variable.valSchema) {
                if (key === newVarKey) {
                    keyExists = true;
                    newVarKey = keyBaseName + (Object.keys(variable.valSchema).length + 1 + vkOff);
                    vkOff++;
                    break;
                }
            }
        }
        return newVarKey;
    }

    const addValSchemaItem = (e, variable) => {
        let varKey = generateValSchemaVarKey(variable);

        variable.valSchema[varKey] = {
            label: '',
            type: 'string', 
            default: '',
            valOptions: [{label: 'Option 1', val: 'option1'}],
        };
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const insertValSchemaItemAbove = (e, variable, key) => {
        let newKey = generateValSchemaVarKey(variable);
        let newSchema = {};
        let inserted = false;
        for (let schemaKey in variable.valSchema) {
            if (schemaKey === key) {
                newSchema[newKey] = {
                    label: '',
                    type: 'string',
                    default: '',
                    valOptions: [{label: 'Option 1', val: 'option1'}],
                };
                inserted = true;
            }
            newSchema[schemaKey] = variable.valSchema[schemaKey];
        }
        if (!inserted) {
            newSchema[newKey] = {
                label: '',
                type: 'string',
                default: '',
                valOptions: [{label: 'Option 1', val: 'option1'}],
            };
        }
        variable.valSchema = newSchema;
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const removeValSchemaItem = (e, variable, key) => {
        delete variable.valSchema[key];
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const moveValSchemaItem = (e, variable, key, direction) => {
        /* let keys = Object.keys(variable.valSchema);
        let index = keys.indexOf(key);
        if ( (direction === -1 && index > 0) || (direction === 1 && index < keys.length - 1)) {
            let temp = variable.valSchema[keys[index + direction]];
            variable.valSchema[keys[index + direction]] = variable.valSchema[keys[index]];
            variable.valSchema[keys[index]] = temp;
        } */

        // We need to make sure our keys come out in the same order as when we render it
        const keysArray = Object.keys(variable.valSchema).map(
            (schemaKey, schemaIndex) => {
                return schemaKey;
            }
        );
        const keyIndex = keysArray.indexOf(key);
        if ( (direction === -1 && keyIndex > 0) || (direction === 1 && keyIndex < keysArray.length - 1)) {
            let temp = keysArray[keyIndex + direction];
            keysArray[keyIndex + direction] = keysArray[keyIndex];
            keysArray[keyIndex] = temp;
        }
        // Make a new object with the new keys order (otherwise this won't rerender properly)
        let newSchema = {};
        keysArray.forEach(
            (schemaKey, schemaIndex) => {
                newSchema[schemaKey] = variable.valSchema[schemaKey];
            }
        );
        variable.valSchema = newSchema;
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const copiedSchemaItemData = useRef(null);
    const copyValSchemaItem = (e, variable, key) => {
        copiedSchemaItemData.current = JSON.parse(JSON.stringify(variable.valSchema[key]));
        toast.success('Schema item copied');
    }
    const pasteValSchemaItem = (e, variable) => {
        if (copiedSchemaItemData.current) {
            const copiedValKey = generateValSchemaVarKey(variable, copiedSchemaItemData.current.__ed__schemaKey); // copiedSchemaItemData.current.__ed__schemaKey +  '_' + (Object.keys(variable.valSchema).length + 1);
            variable.valSchema[copiedValKey] = JSON.parse(JSON.stringify(copiedSchemaItemData.current));
            variable.valSchema[copiedValKey].__ed__schemaKey = copiedValKey;
            if (!showLiveEditVarFamilyPopup) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }
    const pasteValSchemaItemAbove = (e, variable, key) => {
        if (copiedSchemaItemData.current) {
            const copiedValKey = generateValSchemaVarKey(variable, copiedSchemaItemData.current.__ed__schemaKey); // copiedSchemaItemData.current.__ed__schemaKey +  '_' + (Object.keys(variable.valSchema).length + 1);
            // copiedSchemaItemData.current.__ed__schemaKey = copiedValKey;
            let newSchema = {};
            let inserted = false;
            for (let schemaKey in variable.valSchema) {
                if (schemaKey === key) {
                    newSchema[copiedValKey] = JSON.parse(JSON.stringify(copiedSchemaItemData.current));
                    inserted = true;
                }
                newSchema[schemaKey] = variable.valSchema[schemaKey];
            }
            if (!inserted) {
                newSchema[copiedValKey] = JSON.parse(JSON.stringify(copiedSchemaItemData.current));
            }
            newSchema[copiedValKey].__ed__schemaKey = copiedValKey;
            variable.valSchema = newSchema;
            if (!showLiveEditVarFamilyPopup) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }

    const addValSchemaItemDisplayCondition = (e, variable, key) => {
        if (typeof variable.valSchema[key].displayCondition === 'undefined') {
            variable.valSchema[key].displayCondition = [];
        }
        variable.valSchema[key].displayCondition.push({
            var: '',
            evalCondition: '==',
            val: ''
        });
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const removeValSchemaItemDisplayCondition = (e, variable, key, index) => {
        variable.valSchema[key].displayCondition.splice(index, 1);
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const copiedSchemaItemDisplayConditionData = useRef(null);
    const copyValSchemaItemDisplayCondition = (e, variable, key, index) => {
        copiedSchemaItemDisplayConditionData.current = JSON.parse(JSON.stringify(variable.valSchema[key].displayCondition[index]));
        toast.success('Display condition copied');
    }
    const pasteValSchemaItemDisplayCondition = (e, variable, key) => {
        if (copiedSchemaItemDisplayConditionData.current) {
            if (typeof variable.valSchema[key].displayCondition === 'undefined') {
                variable.valSchema[key].displayCondition = [];
            }
            variable.valSchema[key].displayCondition.push(JSON.parse(JSON.stringify(copiedSchemaItemDisplayConditionData.current)));
            if (!showLiveEditVarFamilyPopup) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }

    const copiedDisplayConditionsArrayData = useRef(null);
    const copyValSchemaItemDisplayConditionsList = (e, variable, key) => {
        copiedDisplayConditionsArrayData.current = JSON.parse(JSON.stringify(variable.valSchema[key].displayCondition));
        toast.success('Display conditions copied');
    }
    const pasteValSchemaItemDisplayConditionsList = (e, variable, key) => {
        if (copiedDisplayConditionsArrayData.current) {
            if (typeof variable.valSchema[key].displayCondition === 'undefined') {
                variable.valSchema[key].displayCondition = [];
            }
            for (let i = 0; i < copiedDisplayConditionsArrayData.current.length; i++) {
                variable.valSchema[key].displayCondition.push(JSON.parse(JSON.stringify(copiedDisplayConditionsArrayData.current[i])));
            }
            if (!showLiveEditVarFamilyPopup) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }

    const predefinedBlockDropDownOptions = [];
    for (let key in PREDEFINED_VAR_SCHEMA_BLOCKS) {
        predefinedBlockDropDownOptions.push({value: key, label: key, index: 0});
    }
    const selectedPredefinedBlockKey = useRef(predefinedBlockDropDownOptions[0].value);
    const [selectedPredefinedBlockIndex, setSelectedPredefinedBlockIndex] = useState(0);
    const selectPredefinedBlock = (item) => {
        selectedPredefinedBlockKey.current = item.value;
        setSelectedPredefinedBlockIndex(item.index);
    }

    const addVarSchemaPredefinedBlock = (e, variable, key, predefinedBlockKey = null) => {
        if (!predefinedBlockKey) {
            predefinedBlockKey = selectedPredefinedBlockKey.current;
        }
        if (PREDEFINED_VAR_SCHEMA_BLOCKS[predefinedBlockKey]) {
            for (let p in PREDEFINED_VAR_SCHEMA_BLOCKS[predefinedBlockKey]) {
                const newKey = generateValSchemaVarKey(variable, p + '_') // key + '_' + p + '_' + (Object.keys(variable.valSchema).length + 1);
                variable.valSchema[newKey] = JSON.parse(JSON.stringify(PREDEFINED_VAR_SCHEMA_BLOCKS[predefinedBlockKey][p]));
            }
            if (!showLiveEditVarFamilyPopup) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }

    const addVarSchemaPredefinedBlockAbove = (e, variable, familyKey, varKeyToAddAbove, predefinedBlockKey = null) => {
        if (!predefinedBlockKey) {
            predefinedBlockKey = selectedPredefinedBlockKey.current;
        }
        if (PREDEFINED_VAR_SCHEMA_BLOCKS[predefinedBlockKey]) {
            let newSchema = {};
            let inserted = false;
            for (let schemaKey in variable.valSchema) {
                if (schemaKey === varKeyToAddAbove) {
                    for (let p in PREDEFINED_VAR_SCHEMA_BLOCKS[predefinedBlockKey]) {
                        const newKey = generateValSchemaVarKey(variable, p + '_'); // familyKey + '_' + p + '_' + (Object.keys(variable.valSchema).length + 1);
                        newSchema[newKey] = JSON.parse(JSON.stringify(PREDEFINED_VAR_SCHEMA_BLOCKS[predefinedBlockKey][p]));
                    }
                    inserted = true;
                }
                newSchema[schemaKey] = variable.valSchema[schemaKey];
            }
            if (!inserted) {
                for (let p in PREDEFINED_VAR_SCHEMA_BLOCKS[predefinedBlockKey]) {
                    const newKey = generateValSchemaVarKey(variable, p + '_'); // familyKey + '_' + p + '_' + (Object.keys(variable.valSchema).length + 1);
                    newSchema[newKey] = JSON.parse(JSON.stringify(PREDEFINED_VAR_SCHEMA_BLOCKS[predefinedBlockKey][p]));
                }
            }
            variable.valSchema = newSchema;
            if (!showLiveEditVarFamilyPopup) {
                setFeatureData({...featureData_ref.current});
            }
        }
    }

    const addInitialSetting = (valsArray, valSchema) => {
        let newSetting = JSON.parse(JSON.stringify(getDefaultValsObject(valSchema)));
        valsArray.push(newSetting);
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const removeInitialSetting = (valsArray, index) => {
        valsArray.splice(index, 1);
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const resetInitialSettings = (valsArray, valSchema, rerenderNow = true) => {
        valsArray.splice(0, valsArray.length);
        let newSetting = JSON.parse(JSON.stringify(getDefaultValsObject(valSchema)));
        valsArray.push(newSetting);
        if (rerenderNow && !showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const resetAllIntialSettings = (e) => {
        e.stopPropagation();

        for (let i = 0; i < featureData_ref.current.customisation.length; i++) {
            for (let j = 0; j < featureData_ref.current.customisation[i].options.length; j++) {
                for (let k = 0; k < featureData_ref.current.customisation[i].options[j].variables.length; k++) {
                    let variable = featureData_ref.current.customisation[i].options[j].variables[k];
                    resetInitialSettings(variable.vals, variable.valSchema, false);
                }
            }
        }
        setFeatureData({...featureData_ref.current});
        toast.success('All initial settings reset');
    }

    const addPreset = (variable) => {
        variable.presets.push({name: 'New Preset ' + (variable.presets.length + 1), vals: [{}]});
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const removePreset = (variable, index) => {
        variable.presets.splice(index, 1);
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const addPresetSetting = (presetsArray) => {
        let newSetting = {};
        presetsArray.push(newSetting);
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    const removePresetSetting = (presetsArray, index) => {
        presetsArray.splice(index, 1);
        if (!showLiveEditVarFamilyPopup) {
            setFeatureData({...featureData_ref.current});
        }
    }

    /* Save changes */
    const buildSeederData = (e) => {
        e.preventDefault();

        let seederData = JSON.parse(JSON.stringify(featureData_ref.current));

        delete(seederData._id);
        delete(seederData.__v);

        // Iterate recusively though the data removing any properties called _id or __v or ones used just internally by the editor
        // Also while we are at it, convert any filename strings to objects
        // And parse those stringified trigger params
        const processDataAndSanitize = (data) => {
            for (let key in data) {
                /* if (Array.isArray(data[key])) {
                    for (let i = 0; i < data[key].length; i++) {
                        if (data[key][i] && data[key][i].filename && data[key][i].filename.filename) {
                            data[key][i].filename = data[key][i].filename.filename;
                        } else {
                            processDataAndSanitize(data[key][i]);
                        }
                    }
                } else */
                if (data[key] && typeof data[key].filename !== 'undefined') {
                    // Deal with keys that are called filename, but not necessarily the filename object (check for .filename inside of it)
                    /* if (key === 'default') {
                        if (typeof data[key].filename.filename !== 'undefined') {
                            data[key].filename = data[key].filename.filename;
                        }
                    } */
                    if (key === 'valSchema') {
                        // This is a property that just happens to be named filename!
                    } else
                    if (typeof data[key].default !== 'undefined') {
                        // This is a property that just happens to be named filename!
                        if (typeof data[key].default.filename !== 'undefined') {
                            data[key].default = data[key].default.filename;
                        }
                    } else
                    if (typeof data[key].filename.filename !== 'undefined') {
                        data[key].filename = data[key].filename.filename;
                    } else {
                        // We don't want to be replacing a whole vals array element...
                        if (!Array.isArray(data)) {
                            data[key] = data[key].filename;
                        }
                    }
                    if (typeof data[key] === 'object') {
                        processDataAndSanitize(data[key]);
                    }
                } else
                if (key === 'valOptions') {
                    // Thes are dummy val options that we don't need to save
                    if (data[key].length === 1 && data[key][0].label === 'Option 1' && data[key][0].val === 'option1') {
                        delete(data[key]);
                    }
                } else
                if (key === 'vals') {
                    if (!data.valSchema) {
                        console.warn('No valSchema for: ', data);
                    } else {
                        // Remove any vals that don't have a corresponding key in the valSchema,
                        // these could belong to old vars that have been deleted or renamed and will only cause bloat if left in
                        for (let i = 0; i < data[key].length; i++) {
                            for (let valKey in data[key][i]) {
                                if (!data.valSchema.hasOwnProperty(valKey) && valKey !== 'isDefault') {
                                    delete(data[key][i][valKey]);
                                } else {
                                    // Make sure colours and fonts have valid data
                                    if (data.valSchema[valKey] && data.valSchema[valKey]?.type.toLowerCase() !== 'string' && data.valSchema[valKey].type !== 'subtitle' && data.valSchema[valKey].type !== 'column-break' && data.valSchema[valKey].type !== 'spacer') {
                                        console.log('Checking: ', valKey, data[key][i], data[key][i][valKey], data.valSchema[valKey]);
                                        switch (data.valSchema[valKey].type) {
                                            case 'colour':
                                                if (!data[key][i][valKey]?.startsWith('#') || typeof data[key][i][valKey] !== 'string') {
                                                    data[key][i][valKey] = '#9146FF';
                                                    console.log('FIXED COLOUR: ', data[key][i][valKey]);
                                                }
                                                break;
                                            case 'font':
                                                if (data[key][i][valKey] === '' || typeof data[key][i][valKey] !== 'string') {
                                                    data[key][i][valKey] = 'Roboto';
                                                    console.log('FIXED FONT: ', data[key][i][valKey]);
                                                }
                                                break;
                                            case 'toggle':
                                                if (typeof data[key][i][valKey] !== 'boolean') {
                                                    data[key][i][valKey] = data.valSchema[valKey].default || false;
                                                    console.log('FIXED TOGGLE: ', data[key][i][valKey]);
                                                }
                                                break;
                                            case 'slider':
                                                if (typeof data.valSchema[valKey].default !== 'number') {
                                                    console.log('Slider default not a number: ', data.valSchema[valKey].default);
                                                    data.valSchema[valKey].default = Number(data.valSchema[valKey].default);
                                                    if (isNaN(data.valSchema[valKey].default)) {
                                                        data.valSchema[valKey].default = 0;
                                                    }
                                                    console.log('Fixed default: ', data.valSchema[valKey].default);
                                                }
                                                if (typeof data[key][i][valKey] !== 'number') {
                                                    console.log('Slider value not a number: ', data[key][i][valKey]);
                                                    data[key][i][valKey] = Number(data[key][i][valKey]);
                                                    if (isNaN(data[key][i][valKey])) {
                                                        data[key][i][valKey] = data.valSchema[valKey].default || 0;
                                                    }
                                                    console.log('Fixed value: ', data[key][i][valKey]);
                                                }
                                                break;
                                            case 'number':
                                                if (typeof data.valSchema[valKey].default !== 'number') {
                                                    console.log('Number default not a number: ', data.valSchema[valKey].default);
                                                    data.valSchema[valKey].default = Number(data.valSchema[valKey].default);
                                                    if (isNaN(data.valSchema[valKey].default)) {
                                                        data.valSchema[valKey].default = 0;
                                                    }
                                                    console.log('Fixed default: ', data.valSchema[valKey].default);
                                                }
                                                if (typeof data[key][i][valKey] !== 'number') {
                                                    console.log('Number value not a number: ', data[key][i][valKey]);
                                                    data[key][i][valKey] = Number(data[key][i][valKey]);
                                                    if (isNaN(data[key][i][valKey])) {
                                                        data[key][i][valKey] = data.valSchema[valKey].default || 0;
                                                    }
                                                    console.log('Fixed value: ', data[key][i][valKey]);
                                                }
                                                break;
                                            
                                            default:
                                                break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    processDataAndSanitize(data[key]);
                } else
                if (key === 'triggerParamStringify') {
                    if (data.triggerParamStringify) {
                        data.triggerParam = JSON.parse(data.triggerParamStringify);
                    }
                    delete(data.triggerParamStringify);
                } else
                if (key === '_id' || key === '__v' || key === '__ed__commandLinkage' || key === '__ed__relB_commandLinkage' || key === '__ed__setChainRestrictedValue' || key === 'originalFile' || key === 'srcUrl' || key === '__ed__schemaKey') {
                    if (key === '__ed__setChainRestrictedValue') {
                        if (data[key] !== true && data.chainRestrictedValue) {
                            delete(data.chainRestrictedValue);
                        }
                    }
                    delete(data[key]);
                } else
                // Delete empty basicModePresets
                if (key === 'basicModePresets' && data[key].length === 0) {
                    delete(data[key]);              
                } else if (typeof data[key] === 'object') {
                    processDataAndSanitize(data[key]);
                }
            }
        }
        processDataAndSanitize(seederData);

        console.log('Built seeder data: ', seederData);

        // Now save a stringified version of the data to a file
        let JSONFileData = JSON.stringify(seederData, null, 2);
        let blob = new Blob([JSONFileData], {type: 'application/json'});
        let url = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = featureData.varKey + '_generated.json';
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(url);
    }

    useEffect(
        () => {
            clearFeatureData();
            getSeederList();
            getFeatureList();
        }, []
    )

    /* PREVIEW */
    const [showPreview, setShowPreview] = useState(false);
    const openPreview = (e) => {
        e.stopPropagation();

        setShowPreview(true);
        resetExpandedCollapsiblesRecord();
    }
    const closePreview = () => {
        setShowPreview(false);
    }
    useEffect(
        () => {
            setLocalStorageList(getLocalStorageList());
            setFeatureData({...featureData_ref.current});
        }, [showPreview]
    );

    const LiveGroupLayout = (props) => {
        const [open, setOpen] = useState(false);
        const [forceWidth, setForceWidth] = useState(props.option.forceWidth);
        const [padEnd, setPadEnd] = useState(props.option.padEnd);
        const [label, setLabel] = useState(props.option.groupTitle);
        const [key, setKey] = useState(props.option.varKey);
        const index = props.index;
        const tabIndex = props.tabIndex;
        const option = props.option;
        return (
            <div className="edit-layout">
                {IconJsxer.GetIcon(IconJsxer.ICONS.cog, IconJsxer.ICON_STYLES.roundPanelButton, 'icon', (e) => {e.stopPropagation(); setOpen(!open);})}
                <div className="move-icons group-move-icons">
                    <div className="move-icon" onClick={(e) => {moveCustomisationGroup(e, tabIndex, index, -1); e.stopPropagation();}}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.standard)}</div>
                    <div className="move-icon" onClick={(e) => {moveCustomisationGroup(e, tabIndex, index, 1); e.stopPropagation();}}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.standard)}</div>
                </div>
                {open &&
                    <div className="layout-settings-holder" onClick={(e) => e.stopPropagation()}>
                        <FormField id={"option" + index + "_label"} label="Label" placeholder="Enter Label" value={label} setFunc={setLabel} confirmChangeFunc={(value) => changeObjectValue(option, 'groupTitle', value)} showError={false} />
                        <FormField id={"option" + index + "_varKey"} label="Var Key" placeholder="Enter Var Key" value={key} setFunc={setKey} confirmChangeFunc={(value) => changeObjectValue(option, 'varKey', value)} showError={false} />
                        <FormField id={"option" + index + "_forceWidth"} label="Force Width" placeholder="Enter Force Width" value={forceWidth} setFunc={(value) => setForceWidth(value)} confirmChangeFunc={(value) => changeObjectValue(option, 'forceWidth', value)} showError={false} />
                        <div className="fl-row force-width-options">
                            <FormDropDown id={"option" + index + "_forceWidthOptions"} className="grow" label="Force Width Options" items={FORCE_WIDTH_OPTIONS} currentIndex={getSelectedIndex(FORCE_WIDTH_OPTIONS, option, 'forceWidth')} selectFunc={(e, id, item) => setValFromDropDown(item, option, 'forceWidth')} showErrors={false} />
                        </div>
                        <FormField id={"option" + index + "_padEnd"} label="Pad End" placeholder="Enter Pad End" value={padEnd} setFunc={(value) => setPadEnd(value)} confirmChangeFunc={(value) => changeObjectValue(option, 'padEnd', value)} showError={false} />
                        {/* <div className="fl-row">
                            <ColorPicker
                                label="Header Bg Colour"
                                id="header_bg_colour"
                                setFunc={(newValue) => changeObjectValue(option, 'headerBgColour', newValue)}
                                value={option.headerBgColour || '#FFFFFF'}
                            />
                        </div>
                        <div className="fl-row">
                            <ColorPicker
                                label="Group Bg Colour"
                                id="group_bg_colour"
                                setFunc={(newValue) => changeObjectValue(option, 'bgColour', newValue)}
                                value={option.bgColour || '#FFFFFF'}
                            />
                        </div> */}
                    </div>
                }
            </div>
        )
    }

    const getGroupLayoutSettings = (option, index, tabIndex) => {
        return (
            <LiveGroupLayout option={option} index={index} tabIndex={tabIndex} />
        )
    }

    const LiveVarFamilyLayout = (props) => {
        const [open, setOpen] = useState(false);
        const [forceWidth, setForceWidth] = useState(props.variable.forceWidth);
        const [padEnd, setPadEnd] = useState(props.variable.padEnd);
        const [groupWidth, setGroupWidth] = useState(props.variable.groupWidth);
        const [label, setLabel] = useState(props.variable.label);
        const [key, setKey] = useState(props.variable.varName);
        const index = props.index;

        const variable = props.variable;

        const customisation = props.customisation;
        const option = props.option;
        const index1 = props.index1;
        const index2 = props.index2;
        const index3 = props.index3;
        return (
            <div className="edit-layout">
                <div className="fl-row compact">
                    {IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.roundPanelButton, 'icon', () => openLiveEditVarFamilyPopup(variable, customisation, option, index1, index2, index3))}
                    <div className="move-icons">
                        <div className="move-icon" onClick={(e) => {moveCustomisationVariable(e, index1, index2, index3, -1); e.stopPropagation();}}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.standard)}</div>
                        <div className="move-icon" onClick={(e) => {moveCustomisationVariable(e, index1, index2, index3, 1); e.stopPropagation();}}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.standard)}</div>
                    </div>
                    {IconJsxer.GetIcon(IconJsxer.ICONS.cog, IconJsxer.ICON_STYLES.roundPanelButton, 'icon', () => setOpen(!open))}
                </div>
                {open &&
                    <div className="layout-settings-holder">
                        <FormField id={"option" + index + "_label"} label="Label" placeholder="Enter Label" value={label} setFunc={setLabel} confirmChangeFunc={(value) => changeObjectValue(variable, 'label', value)} showError={false} />
                        <FormField id={"option" + index + "_varKey"} label="Family Key" placeholder="Enter Family Key" value={key} setFunc={setKey} confirmChangeFunc={(value) => changeObjectValue(variable, 'varName', value)} showError={false} />
                        {variable.allowMultiple &&
                            <div className="fl-column">
                                <FormField id={"option" + index + "_groupWidth"} label="Group Width" placeholder="Enter Group Width" value={groupWidth} setFunc={(value) => setGroupWidth(value)} confirmChangeFunc={(value) => changeObjectValue(variable, 'groupWidth', value)} showError={false} />
                                <div className="fl-row force-width-options">
                                    <FormDropDown id={"option" + index + "_groupWidthOptions"} className="grow" label="Group Width Options" items={FORCE_WIDTH_OPTIONS} currentIndex={getSelectedIndex(FORCE_WIDTH_OPTIONS, variable, 'groupWidth')} selectFunc={(e, id, item) => setValFromDropDown(item, variable, 'groupWidth')} showErrors={false} />
                                </div>
                            </div>
                        }
                        <div className="fl-column">
                            <FormField id={"option" + index + "_forceWidth"} label={`${variable.allowMultiple ? 'Item' : 'Force'} Width`} placeholder={`Enter ${variable.allowMultiple ? 'Item' : 'Force'} Width`} value={forceWidth} setFunc={(value) => setForceWidth(value)} confirmChangeFunc={(value) => changeObjectValue(variable, 'forceWidth', value)} showError={false} />
                            <div className="fl-row force-width-options">
                                <FormDropDown id={"option" + index + "_forceWidthOptions"} className="grow" label={`${variable.allowMultiple ? 'Item' : 'Force'} Width Options`} items={FORCE_WIDTH_OPTIONS} currentIndex={getSelectedIndex(FORCE_WIDTH_OPTIONS, variable, 'forceWidth')} selectFunc={(e, id, item) => setValFromDropDown(item, variable, 'forceWidth')} showErrors={false} />
                            </div>
                            <FormField id={"option" + index + "_padEnd"} label="Pad End" placeholder="Enter Pad End" value={padEnd} setFunc={(value) => setPadEnd(value)} confirmChangeFunc={(value) => changeObjectValue(variable, 'padEnd', value)} showError={false} />
                        </div>
                        
                        <div className="fl-row">
                            <FormDropDown className="grow" id={"variable" + index + "_stylePresets"} label="Style Presets" showLabel={false} items={getVarBoxStylePresetsDropDownData()} currentIndex={0} selectFunc={(e, id, item) => {selectVarBoxStyle(e, id, item); applyCustomisationVariableBoxSettings(variable)}} showErrors={false} />
                        </div>
                        {!variable.allowMultiple &&
                            <div className="fl-row">
                                <div className="label no-wrap">Add Box</div>
                                <div className="grow"></div>
                                <div className="shrink">
                                    <Toggle id={"option" + index + "_addBox"} currentState={variable.addBox || false} toggleFunc={(e, id) => changeObjectValue(variable, 'addBox', !(variable.addBox || false))} />
                                </div>
                            </div>
                        }
                        <div className="fl-row">
                            <div className="label no-wrap">Outline Box</div>
                            <div className="grow"></div>
                            <div className="shrink">
                                <Toggle id={"option" + index + "_addOutlineBox"} currentState={variable.addOutlineBox || false} toggleFunc={(e, id) => changeObjectValue(variable, 'addOutlineBox', !(variable.addOutlineBox || false))} />
                            </div>
                        </div>
                        {!variable.allowMultiple &&
                            <>
                                <div className="fl-row">
                                    <div className="label no-wrap">Item Box</div>
                                    <div className="grow"></div>
                                    <div className="shrink">
                                        <Toggle id={"option" + index + "_addVarBox"} currentState={variable.addVarBox || false} toggleFunc={(e, id) => changeObjectValue(variable, 'addVarBox', !(variable.addVarBox || false))} />
                                    </div>
                                </div>
                                <div className="fl-row">
                                    <div className="label no-wrap">Outline Box</div>
                                    <div className="grow"></div>
                                    <div className="shrink">
                                        <Toggle id={"option" + index + "_addVarOutlineBox"} currentState={variable.addVarOutlineBox || false} toggleFunc={(e, id) => changeObjectValue(variable, 'addVarOutlineBox', !(variable.addVarOutlineBox || false))} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                }
            </div>
        )
    }

    const getVarFamilyLayoutSettings = (variable, index, customisation, option, index1, index2, index3) => {
        return (
            <LiveVarFamilyLayout variable={variable} index={index} customisation={customisation} option={option} index1={index1} index2={index2} index3={index3} />
        )
    }

    const EditVarFamily = (props) => {
        const variable = props.variable;
        const i = props.index1;
        const j = props.index2;
        const k = props.index3;
        const customisation = props.customisation;
        const option = props.option;
        const rerenderImmediately = props.rerenderImmediately;

        const [schemaHighlighKey_int, setSchemaHighlightKey_int] = useState('');

        const [rerender, setRerender] = useState(false);
        const changeObjectValue_int = (_var, _varKey, _varVal) => {
            changeObjectValue(_var, _varKey, _varVal, true, rerenderImmediately);
            setRerender(!rerender);
        }
        const forceRerender = () => {
            setRerender(!rerender);
        }

        return (
            <div className="seeder-content-box full-width">

                <div className="fl-row equal">
                    <FormField id={"variable" + k + "_label"} label="Label" placeholder="Enter Label" value={variable.label || ''} setFunc={(value) => changeObjectValue_int(variable, 'label', value)} showError={false} />
                    <FormField id={"variable" + k + "_varName"} label="Family Key" placeholder="Enter Family Key" value={variable.varName || ''} setFunc={(value) => changeObjectValue_int(variable, 'varName', value)} showError={false} />
                    <div className="fl-row">
                        <div className="label no-wrap">Advanced Mode</div>
                        <div className="grow"></div>
                        <div className="shrink">
                            <Toggle id={"option" + j + "_advancedMode"} currentState={variable.advancedMode || false} toggleFunc={(e, id) => changeObjectValue_int(variable, 'advancedMode', !(variable.advancedMode || false))} />
                        </div>
                    </div>
                </div>

                {/* <div className="fl-row"></div> */}


                <CollapsibleHeadedSection title={`More Settings`} open={false}>
                    <div className="fl-column full-width">
                        <div className="fl-row">
                            <div className="fl-row">
                                <div className="label no-wrap">Force To New Row</div>
                                <div className="grow"></div>
                                <div className="shrink">
                                    <Toggle id={"option" + j + "_displayOnNewRow"} currentState={variable.displayOnNewRow || false} toggleFunc={(e, id) => changeObjectValue_int(variable, 'displayOnNewRow', !(variable.displayOnNewRow || false))} />
                                </div>
                            </div>
                            <div className="fl-row grow">
                            </div>
                            <div className="fl-row">
                                <FormDropDown className="grow" id={"variable" + k + "_stylePresets"} label="Style Presets" showLabel={false} items={getVarBoxStylePresetsDropDownData()} currentIndex={selectedVarBoxStyleIndex} selectFunc={selectVarBoxStyle} showErrors={false} />
                                <div className="shrink">
                                    <button className="standard-button tight" onClick={() => {applyCustomisationVariableBoxSettings(variable); forceRerender()}}>Apply</button>
                                </div>
                            </div>
                        </div>
                        <div className="fl-row equal">
                            <div className="grow">
                                <TextArea id={"variable" + k + "_description"} rows={3} label="Description" placeholder="Enter Description" value={variable.description || ''} setFunc={(value) => changeObjectValue_int(variable, 'description', value)} showError={false} />
                            </div>
                            <div className="fl-column">
                                <div className="fl-row">
                                    <div className="label no-wrap">Add Box</div>
                                    <div className="grow"></div>
                                    <div className="shrink">
                                        <Toggle id={"option" + j + "_addBox"} currentState={variable.addBox || false} toggleFunc={(e, id) => changeObjectValue_int(variable, 'addBox', !(variable.addBox || false))} />
                                    </div>
                                </div>
                                <div className="fl-row">
                                    <div className="label no-wrap">Outline Box</div>
                                    <div className="grow"></div>
                                    <div className="shrink">
                                        <Toggle id={"option" + j + "_addOutlineBox"} currentState={variable.addOutlineBox || false} toggleFunc={(e, id) => changeObjectValue_int(variable, 'addOutlineBox', !(variable.addOutlineBox || false))} />
                                    </div>
                                </div>
                            </div>
                            <div className="fl-row">
                                <ColorPicker
                                    label="Box Colour"
                                    id="box_colour"
                                    setFunc={(newValue) => changeObjectValue_int(variable, 'boxColour', newValue)}
                                    value={variable.boxColour || '#EFF0F6'}
                                />
                            </div>
                        </div>
                        <div className="fl-row equal">
                            <div className="fl-column grow">
                                <div className="fl-row grow">
                                    <div className="fl-column">
                                        <div className="fl-row">
                                            <div className="label no-wrap">Prevent Delete</div>
                                            <div className="grow"></div>
                                            <div className="shrink">
                                                <Toggle id={"option" + j + "_preventDelete"} currentState={variable.preventDelete || false} toggleFunc={(e, id) => changeObjectValue_int(variable, 'preventDelete', !(variable.preventDelete || false))} />
                                            </div>
                                        </div>
                                        <div className="fl-row">
                                            <div className="label no-wrap">Allow Multiple</div>
                                            <div className="grow"></div>
                                            <div className="shrink">
                                                <Toggle id={"option" + j + "_allowMultiple"} currentState={variable.allowMultiple || false} toggleFunc={(e, id) => changeObjectValue_int(variable, 'allowMultiple', !(variable.allowMultiple || false))} />
                                            </div>
                                        </div>
                                    </div>
                                    <FormField id={"variable" + k + "_maxMultiples"} type="number" label="Max Multiples" placeholder="Enter Max Multiples" value={variable.maxMultiples} setFunc={(value) => changeObjectValue_int(variable, 'maxMultiples', value)} showError={false} />
                                    {featureData.isChainable &&
                                        <FormField id={"variable" + k + "_chainedMaxMultiples"} type="number" label="Chained Max" placeholder="Enter Chained Max Multiples" value={variable.chainedMaxMultiples} setFunc={(value) => changeObjectValue_int(variable, 'chainedMaxMultiples', value)} showError={false} />
                                    }
                                </div>

                            </div>
                            <div className="fl-row">
                                <div className="fl-column">
                                    <div className="fl-row">
                                        <div className="label no-wrap">Item Box</div>
                                        <div className="grow"></div>
                                        <div className="shrink">
                                            <Toggle id={"option" + j + "_addVarBox"} currentState={variable.addVarBox || false} toggleFunc={(e, id) => changeObjectValue_int(variable, 'addVarBox', !(variable.addVarBox || false))} />
                                        </div>
                                    </div>
                                    <div className="fl-row">
                                        <div className="label no-wrap">Outline Box</div>
                                        <div className="grow"></div>
                                        <div className="shrink">
                                            <Toggle id={"option" + j + "_addVarOutlineBox"} currentState={variable.addVarOutlineBox || false} toggleFunc={(e, id) => changeObjectValue_int(variable, 'addVarOutlineBox', !(variable.addVarOutlineBox || false))} />
                                        </div>
                                    </div>
                                </div>
                                <div className="fl-row">
                                    <ColorPicker
                                        label="Item Box Colour"
                                        id="box_colour"
                                        setFunc={(newValue) => changeObjectValue_int(variable, 'varBoxColour', newValue)}
                                        value={variable.varBoxColour || '#EFF0F6'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="fl-row">
                            <div className="fl-row equal half-width">
                                <FormField id={"option" + j + "_forceWidth"} label={`${variable.allowMultiple ? 'Item' : 'Force'} Width`} placeholder={`Enter ${variable.allowMultiple ? 'Item' : 'Force'} Width`} value={variable.forceWidth} setFunc={(value) => changeObjectValue_int(variable, 'forceWidth', value)} showError={false} />
                                <div className="fl-row force-width-options">
                                    <FormDropDown id={"option" + j + "_forceWidthOptions"} className="grow" label={`${variable.allowMultiple ? 'Item' : 'Force'} Width Options`} items={FORCE_WIDTH_OPTIONS} currentIndex={getSelectedIndex(FORCE_WIDTH_OPTIONS, variable, 'forceWidth')} selectFunc={(e, id, item) => setValFromDropDown(item, variable, 'forceWidth')} showErrors={false} />
                                </div>
                                <FormField id={"option" + j + "_padEnd"} label="Pad End" placeholder="Enter Pad End" value={variable.padEnd} setFunc={(value) => changeObjectValue_int(variable, 'padEnd', value)} showError={false} />
                            </div>
                            {variable.allowMultiple &&
                                <div className="fl-row equal half-width">
                                    <FormField id={"option" + j + "_groupWidth"} label="Group Width" placeholder="Enter Group Width" value={variable.groupWidth} setFunc={(value) => changeObjectValue_int(variable, 'groupWidth', value)} showError={false} />
                                    <div className="fl-row force-width-options">
                                        <FormDropDown id={"option" + j + "_groupWidthOptions"} className="grow" label="Group Width Options" items={FORCE_WIDTH_OPTIONS} currentIndex={getSelectedIndex(FORCE_WIDTH_OPTIONS, variable, 'groupWidth')} selectFunc={(e, id, item) => setValFromDropDown(item, variable, 'groupWidth')} showErrors={false} />
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </CollapsibleHeadedSection>


                {/* <div className="fl-row"></div> */}

                <div className="seeder-content-box full-width outline">
                    <CollapsibleHeadedSection title={`Variables (${variable.valSchema ? Object.keys(variable.valSchema).length : '0'})`} open={false}>
                        <div className="fl-row">
                            <div className="fl-column half-width fl-start customisation-controls-holder">
                                <div className="control-group">
                                    <CustomisationControls
                                        renderSingleControlBlock={true}
                                        isPreview={true}
                                        vals={[getDefaultValsObject(variable.valSchema)]}
                                        displaySettings={variable}
                                        controlsData={featureData.customisation}
                                        rootVals={null}
                                        objRef={null}
                                        varBoxWidthStyle={null}
                                        updateFunction={
                                            (newVal, valsArray, varSchema, index, valKey) => {
                                                // console.log('Val changed: ', newVal);
                                                if (CUSTOMISED_URL_MAP.hasOwnProperty(variable.valSchema[valKey].type.toLowerCase())) {
                                                    // File!
                                                    const fileNameSplit = newVal.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                                                    // fileNameSplit[0] += '__' + Date.now();
                                                    variable.valSchema[valKey].default.filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                                                    newVal.uniqueFilename = fileNameSplit.join('.');
                                                    variable.valSchema[valKey].default.newFile = newVal;
                                                    variable.valSchema[valKey].default.srcUrl = newVal ? URL.createObjectURL(newVal) : null;
                                                    variable.valSchema[valKey].default.isDefault = true;
                                                    variable.valSchema[valKey].default.updated = true;
                                                    // valsArray[index].isDefault = false;
                                                    // valsArray[index].updated = true;
                                                    // console.log('File handled: ', variable.valSchema[valKey].default);
                                                } else {
                                                    // Everything else!
                                                    changeObjectValue_int(valsArray[index], valKey, newVal);
                                                    // variable.valSchema[valKey].default = newVal;
                                                }
                                                if (!showLiveEditVarFamilyPopup) {
                                                    setFeatureData({ ...featureData_ref.current });
                                                } else {
                                                    forceRerender();
                                                }
                                            }
                                        }
                                        highlightKey={schemaHighlighKey_int}
                                        highlightNoValSet={true}
                                    />
                                </div>
                                <div className="grow"></div>
                            </div>
                            <div className="fl-row half-width fl-start">
                                <div className="seeder-content-box full-width">
                                    {Object.keys(variable.valSchema).map(
                                        (schemaKey, schemaIndex) => {
                                            // console.log('Render setup: ', schemaKey, schemaIndex, variable.valSchema[schemaKey]);
                                            const controlData = getControlTypeData(getCustomisationControlTypes(), variable.valSchema[schemaKey].type);
                                            // console.log('Control Data: ', controlData);
                                            if (typeof variable.valSchema[schemaKey].__ed__schemaKey === 'undefined') {
                                                variable.valSchema[schemaKey].__ed__schemaKey = schemaKey;
                                            }
                                            if (!variable.valSchema[schemaKey].valOptions || variable.valSchema[schemaKey].valOptions.length === 0) {
                                                variable.valSchema[schemaKey].valOptions = [{ label: 'Option 1', val: 'option1' }];
                                            }
                                            if (typeof variable.valSchema[schemaKey].default === 'undefined') {
                                                variable.valSchema[schemaKey].default = '';
                                            }
                                            if (controlData && controlData.type === FEATURE_CONTROLS_TYPES.Font.type) {
                                                if (!variable.valSchema[schemaKey].default) {
                                                    variable.valSchema[schemaKey].default = 'Roboto';
                                                }
                                            }
                                            for (let v = 0; v < variable.vals.length; v++) {
                                                if (typeof variable.vals[v][schemaKey] === 'undefined') {
                                                    variable.vals[v][schemaKey] = '';
                                                }
                                            }
                                            return (
                                                <div key={"valSchema_" + schemaIndex + "_" + schemaKey} className={`seeder-content-box outline${controlData && controlData.isCosmetic ? ' cosmetic-var' : ''} full-width fl-start`} onMouseEnter={(e) => {setSchemaHighlightKey_int(schemaKey)}} onMouseLeave={(e) => {setSchemaHighlightKey_int('')}}>
                                                    <CollapsibleHeadedSection title={schemaKey} open={false}>
                                                        <div className="fl-column">
                                                            <FormField id={"schema" + schemaIndex + "_varName"} label="Var Name" placeholder="Enter Var Name" value={variable.valSchema[schemaKey].__ed__schemaKey || ''} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], '__ed__schemaKey', value)} confirmChangeFunc={(value) => {changeSchemaKey(variable, schemaKey, value); forceRerender()}} showError={false} />
                                                            <div className="label-note">{`${customisation.tabKey}.${option.varKey}.${variable.varName}.${schemaKey}`}</div>
                                                            {controlData && controlData.noLabel !== true &&
                                                                <>
                                                                    <FormField id={"schema" + schemaIndex + "_label"} type="text" label="Label" placeholder="Enter Label" value={variable.valSchema[schemaKey].label || ''} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'label', value)} showError={false} />
                                                                    {controlData && controlData.noLabelNote !== true &&
                                                                        <TextArea id={"schema" + schemaIndex + "_labelNote"} rows={3} label="Label Note" placeholder="Enter Label Note" value={variable.valSchema[schemaKey].labelNote || ''} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'labelNote', value)} showError={false} />
                                                                    }
                                                                </>
                                                            }
                                                            {controlData && (controlData.noVal !== true && controlData.noDefaultVal !== true) &&
                                                                <>
                                                                    {controlData.defaultVarType === VAR_TYPES.booleanType &&
                                                                        <div className="fl-row">
                                                                            <div className="label no-wrap">Default<br /><span className="label-note">Used when adding new items to multiples</span></div>
                                                                            <div className="grow"></div>
                                                                            <div className="shrink">
                                                                                <Toggle id={"option" + j + "_advancedMode"} currentState={variable.valSchema[schemaKey].default || false} toggleFunc={(e, id) => changeObjectValue_int(variable.valSchema[schemaKey], 'default', !(variable.valSchema[schemaKey].default || false))} />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    {controlData.defaultVarType !== VAR_TYPES.booleanType &&
                                                                        <>
                                                                            {CUSTOMISED_URL_MAP.hasOwnProperty(controlData.type) &&
                                                                                <FormField id={"schema" + schemaIndex + "_default"} label="Default" readonly={true} labelNote="Used when adding new items to multiples" placeholder="Currently Empty" value={variable.valSchema[schemaKey].default.filename} setFunc={null} showError={false} />
                                                                            }
                                                                            {!CUSTOMISED_URL_MAP.hasOwnProperty(controlData.type) &&
                                                                                <FormField id={"schema" + schemaIndex + "_default"} label="Default" labelNote="Used when adding new items to multiples" placeholder="Enter Default" value={variable.valSchema[schemaKey].default} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'default', value)} showError={false} />
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                            <div className="fl-row">
                                                                <div className="label no-wrap">Advanced Mode</div>
                                                                <div className="grow"></div>
                                                                <div className="shrink">
                                                                    <Toggle id={"option" + j + "_advancedMode"} currentState={variable.valSchema[schemaKey].advancedMode || false} toggleFunc={(e, id) => changeObjectValue_int(variable.valSchema[schemaKey], 'advancedMode', !(variable.valSchema[schemaKey].advancedMode || false))} />
                                                                </div>
                                                            </div>
                                                            <FormDropDown id={"schema" + schemaIndex + "_varType"} label="Var Type" items={getCustomisationControlTypes()} currentIndex={getSelectedIndex(getCustomisationControlTypes(), variable.valSchema[schemaKey], 'type')} selectFunc={(e, id, item) => {setValFromDropDown(item, variable.valSchema[schemaKey], 'type'); forceRerender()}} showErrors={false} />
                                                            {controlData && (controlData.type === FEATURE_CONTROLS_TYPES.DescriptionString.type || controlData.type === FEATURE_CONTROLS_TYPES.LightDescriptionString.type || controlData.type === FEATURE_CONTROLS_TYPES.WarningDescriptionString.type || controlData.type === FEATURE_CONTROLS_TYPES.ConfirmDescriptionString.type || controlData.type === FEATURE_CONTROLS_TYPES.AttentionDescriptionString.type) &&
                                                                <TextArea id={"schema" + schemaIndex + "_description"} rows={3} label="Info Text" placeholder="Enter Text" value={variable.valSchema[schemaKey].description || ''} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'description', value)} showError={false} />
                                                            }
                                                            {controlData && controlData.defaultVarType && controlData.defaultVarType === VAR_TYPES.numberType && controlData.noMinMax !== true &&
                                                                <>
                                                                    <FormField id={"schema" + schemaIndex + "_minVal"} label="Min Value" type="number" placeholder="Enter Min Value" value={variable.valSchema[schemaKey].minVal} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'minVal', value)} showError={false} />
                                                                    <FormField id={"schema" + schemaIndex + "_maxVal"} label="Max Value" type="number" placeholder="Enter Max Value" value={variable.valSchema[schemaKey].maxVal} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'maxVal', value)} showError={false} />
                                                                </>
                                                            }
                                                            {controlData && controlData.defaultVarType && controlData.defaultVarType === VAR_TYPES.stringType && controlData.noMaxLength !== true &&
                                                                <FormField id={"schema" + schemaIndex + "_maxLength"} label="Max Length" placeholder="Enter Max Length" value={variable.valSchema[schemaKey].maxLength} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'maxLength', value)} showError={false} />
                                                            }
                                                            {controlData && controlData.type === FEATURE_CONTROLS_TYPES.Range.type &&
                                                                <>
                                                                    <div className="fl-row">
                                                                        <div className="label no-wrap">Show Value</div>
                                                                        <div className="grow"></div>
                                                                        <div className="shrink">
                                                                            <Toggle id={"option" + j + "_showCurrentValue"} currentState={typeof variable.valSchema[schemaKey].showCurrentValue !== 'undefined' ? variable.valSchema[schemaKey].showCurrentValue : true} toggleFunc={(e, id) => changeObjectValue_int(variable.valSchema[schemaKey], 'showCurrentValue', !(variable.valSchema[schemaKey].showCurrentValue || false))} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="fl-row">
                                                                        <div className="label no-wrap">Use Text Labels</div>
                                                                        <div className="grow"></div>
                                                                        <div className="shrink">
                                                                            <Toggle id={"option" + j + "_useTextLabels"} currentState={variable.valSchema[schemaKey].useTextLabels || false} toggleFunc={(e, id) => changeObjectValue_int(variable.valSchema[schemaKey], 'useTextLabels', !(variable.valSchema[schemaKey].useTextLabels || false))} />
                                                                        </div>
                                                                    </div>
                                                                    {variable.valSchema[schemaKey].useTextLabels &&
                                                                        <>
                                                                            <FormField id={"schema" + schemaIndex + "_minLabel"} label="Min Label" placeholder="Enter Min Label" value={variable.valSchema[schemaKey].minLabel} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'minLabel', value)} showError={false} />
                                                                            <FormField id={"schema" + schemaIndex + "_midLabel"} label="Mid Label" placeholder="Enter Mid Label" value={variable.valSchema[schemaKey].midLabel} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'midLabel', value)} showError={false} />
                                                                            <FormField id={"schema" + schemaIndex + "_maxLabel"} label="Max Label" placeholder="Enter Max Label" value={variable.valSchema[schemaKey].maxLabel} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'maxLabel', value)} showError={false} />
                                                                        </>
                                                                    }
                                                                </>
                                                            }

                                                            {controlData && controlData.type === FEATURE_CONTROLS_TYPES.ImagePreview.type &&
                                                                <FormField id={"schema" + schemaIndex + "_previewFor"} label="Preview For" labelNote="The drop down to pull preview image from (see object zapper)" placeholder="Enter Var Name" value={variable.valSchema[schemaKey].previewFor || ''} setFunc={(value) => changeObjectValue_int(variable.valSchema[schemaKey], 'previewFor', value)} showError={false} />
                                                            }

                                                            {controlData && (controlData.type === FEATURE_CONTROLS_TYPES.DropDown.type || controlData.type === FEATURE_CONTROLS_TYPES.Radio.type || controlData.type === FEATURE_CONTROLS_TYPES.RadioVertical.type) &&
                                                                <CollapsibleHeadedSection title={`Options (${variable.valSchema[schemaKey].valOptions ? variable.valSchema[schemaKey].valOptions.length : '0'})`} open={false}>
                                                                    <div className="fl-column">
                                                                        {variable.valSchema[schemaKey].valOptions && variable.valSchema[schemaKey].valOptions.map(
                                                                            (option, k) => {
                                                                                const valKey = option.val ? 'val' : 'value';
                                                                                return (
                                                                                    <div key={"option" + k} className={`seeder-content-box full-width`}>
                                                                                        <div className="fl-row equal">
                                                                                            <FormField id={"option" + k + "_option"} label="Label" placeholder="Enter Label" value={option.label || ''} setFunc={(value) => changeObjectValue_int(option, 'label', value)} showError={false} />
                                                                                            <FormField id={"option" + k + "_val"} type='text' label="Value" placeholder="Enter Value" value={option[valKey]} setFunc={(value) => changeObjectValue_int(option, valKey, value)} showError={false} />
                                                                                        </div>
                                                                                        <CollapsibleHeadedSection title="More..." open={false}>
                                                                                            <>
                                                                                                <div className="fl-row">
                                                                                                    <FormField id={"option" + k + "_previewFile"} label="Preview File" labelNote="Preview files must exist in public/customise-previews/<br />(see object zapper)" placeholder={`Enter File Path`} value={option.previewFile || ''} setFunc={(value) => changeObjectValue_int(option, 'previewFile', value)} showError={false} />
                                                                                                </div>
                                                                                                <CollapsibleHeadedSection title="Basic Mode Preset" open={false}>
                                                                                                    <>
                                                                                                        {option.basicModePreset &&
                                                                                                            <div className="fl-column full-width fl-start customisation-controls-holder rel">
                                                                                                                <div className="control-group">
                                                                                                                    <CustomisationControls
                                                                                                                        renderSingleControlBlock={true}
                                                                                                                        highlightUndefined={true}
                                                                                                                        showUndefinedToggle={true}
                                                                                                                        toggleUndefinedFunc={
                                                                                                                            (presetObject, defaultValue, key) => {
                                                                                                                                if (typeof presetObject[key] === 'undefined') {
                                                                                                                                    try {
                                                                                                                                        presetObject[key] = JSON.parse(JSON.stringify(defaultValue));
                                                                                                                                    } catch (error) {
                                                                                                                                        presetObject[key] = defaultValue;
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    delete presetObject[key];
                                                                                                                                }
                                                                                                                                if (!showLiveEditVarFamilyPopup) {
                                                                                                                                    setFeatureData({ ...featureData_ref.current });
                                                                                                                                } else {
                                                                                                                                    forceRerender();
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        vals={[option.basicModePreset]}
                                                                                                                        displaySettings={variable}
                                                                                                                        controlsData={featureData.customisation}
                                                                                                                        rootVals={null}
                                                                                                                        objRef={null}
                                                                                                                        varBoxWidthStyle={null}
                                                                                                                        updateFunction={
                                                                                                                            (newVal, valsArray, varSchema, index, valKey) => {
                                                                                                                                // console.log('Val changed: ', newVal);
                                                                                                                                if (CUSTOMISED_URL_MAP.hasOwnProperty(variable.valSchema[valKey].type.toLowerCase())) {
                                                                                                                                    // File!
                                                                                                                                    const fileNameSplit = newVal.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                                                                                                                                    // fileNameSplit[0] += '__' + Date.now();
                                                                                                                                    option.basicModePreset[valKey].filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                                                                                                                                    newVal.uniqueFilename = fileNameSplit.join('.');
                                                                                                                                    option.basicModePreset[valKey].newFile = newVal;
                                                                                                                                    option.basicModePreset[valKey].srcUrl = newVal ? URL.createObjectURL(newVal) : null;
                                                                                                                                    option.basicModePreset[valKey].isDefault = true;
                                                                                                                                    option.basicModePreset[valKey].updated = true;
                                                                                                                                    // valsArray[index].isDefault = false;
                                                                                                                                    // valsArray[index].updated = true;
                                                                                                                                    // console.log('File handled: ', option.basicModePreset[valKey]);
                                                                                                                                } else {
                                                                                                                                    // Everything else!
                                                                                                                                    option.basicModePreset[valKey] = newVal;
                                                                                                                                }
                                                                                                                                if (!showLiveEditVarFamilyPopup) {
                                                                                                                                    setFeatureData({ ...featureData_ref.current });
                                                                                                                                } else {
                                                                                                                                    forceRerender();
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                        highlightKey={schemaHighlighKey_int}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {!option.basicModePreset &&
                                                                                                            <div className="fl-row">
                                                                                                                <div className="shrink">
                                                                                                                    <button className="standard-button tight" onClick={(e) => { option.basicModePreset = {}; setFeatureData({ ...featureData_ref.current }); forceRerender() }}>Add Basic Mode Preset</button>
                                                                                                                </div>
                                                                                                                <div className="grow"></div>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </>
                                                                                                </CollapsibleHeadedSection>
                                                                                            </>
                                                                                        </CollapsibleHeadedSection>
                                                                                        <div className="move-icons">
                                                                                            <div className='move-icon' onClick={(e) => {moveValOption(variable.valSchema[schemaKey].valOptions, k, -1); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                            <div className='move-icon' onClick={(e) => {moveValOption(variable.valSchema[schemaKey].valOptions, k, 1); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                        </div>
                                                                                        <div className='del-icon' onClick={(e) => { variable.valSchema[schemaKey].valOptions.splice(k, 1); setFeatureData({ ...featureData_ref.current }) }}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )}

                                                                        <div className="fl-row">
                                                                            <div className="shrink">
                                                                                <button className="standard-button tight" onClick={(e) => {addCustomisationValOption(e, variable.valSchema[schemaKey].valOptions); forceRerender()}}>Add Option</button>
                                                                            </div>
                                                                            <div className="grow"></div>
                                                                        </div>
                                                                    </div>
                                                                </CollapsibleHeadedSection>
                                                            }
                                                            <div className={`seeder-content-box full-width fl-start`}>
                                                                <div className="move-icons">
                                                                    <div className='move-icon' onClick={(e) => copyValSchemaItemDisplayConditionsList(e, variable, schemaKey)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                </div>
                                                                <CollapsibleHeadedSection title={`Disp Conds (${variable.valSchema[schemaKey].displayCondition ? variable.valSchema[schemaKey].displayCondition.length : '0'})`} open={false}>
                                                                    <div className="fl-column">
                                                                        {variable.valSchema[schemaKey].displayCondition && variable.valSchema[schemaKey].displayCondition.map(
                                                                            (condition, k) => {
                                                                                return (
                                                                                    <div key={"condition" + k} className={`seeder-content-box outline full-width`}>
                                                                                        <div className="fl-row equal">
                                                                                            <FormField id={"condition" + k + "_var"} label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue_int(condition, 'var', value)} showError={false} />
                                                                                            <div className="eval-width">
                                                                                                <FormDropDown id={"condition" + k + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue_int(condition, 'evalCondition', item.value)} showErrors={false} />
                                                                                            </div>
                                                                                            <FormField id={"condition" + k + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue_int(condition, 'val', value)} showError={false} />
                                                                                        </div>
                                                                                        <div className="move-icons">
                                                                                            <div className='move-icon' onClick={(e) => copyValSchemaItemDisplayCondition(e, variable, schemaKey, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                        </div>
                                                                                        <div className='del-icon' onClick={(e) => {removeValSchemaItemDisplayCondition(e, variable, schemaKey, k); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )}
                                                                        <div className="fl-row">
                                                                            <div className="shrink">
                                                                                <button className="standard-button tight" onClick={(e) => {addValSchemaItemDisplayCondition(e, variable, schemaKey); forceRerender()}}>Add Display Condition</button>
                                                                            </div>
                                                                            <div className="shrink">
                                                                                <button className="standard-button tight" onClick={(e) => {pasteValSchemaItemDisplayCondition(e, variable, schemaKey); forceRerender()}}>Paste Display Condition</button>
                                                                            </div>
                                                                            <div className="grow"></div>
                                                                        </div>
                                                                        <div className="fl-row">
                                                                            <div className="shrink">
                                                                                <button className="standard-button tight" onClick={(e) => {pasteValSchemaItemDisplayConditionsList(e, variable, schemaKey); forceRerender()}}>Paste Display Conditions List</button>
                                                                            </div>
                                                                            <div className="grow"></div>
                                                                        </div>
                                                                    </div>
                                                                </CollapsibleHeadedSection>
                                                            </div>
                                                        </div>
                                                    </CollapsibleHeadedSection>
                                                    <div className="insert-above-icons">
                                                        <div className="insert-above-icon" onClick={(e) => {insertValSchemaItemAbove(e, variable, schemaKey); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.share, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                        <div className="insert-above-icon" onClick={(e) => {pasteValSchemaItemAbove(e, variable, schemaKey); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.paste, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                        <div className="insert-above-icon" onClick={(e) => {addVarSchemaPredefinedBlockAbove(e, variable, variable.varName, schemaKey); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.snippet, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                    </div>
                                                    <div className="move-icons">
                                                        <div className="move-icon" onClick={(e) => copyValSchemaItem(e, variable, schemaKey)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                        <div className='move-icon' onClick={(e) => {moveValSchemaItem(e, variable, schemaKey, -1); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                        <div className='move-icon' onClick={(e) => {moveValSchemaItem(e, variable, schemaKey, 1); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                    </div>
                                                    <div className='del-icon' onClick={(e) => {removeValSchemaItem(e, variable, schemaKey); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                </div>
                                            )
                                        }
                                    )}
                                    <div className="fl-row">
                                        <div className="shrink">
                                            <button className="standard-button tight" onClick={(e) => {addValSchemaItem(e, variable); forceRerender()}}>Add Variable</button>
                                        </div>
                                        <div className="shrink">
                                            <button className="standard-button tight" onClick={(e) => {pasteValSchemaItem(e, variable); forceRerender()}}>Paste Variable</button>
                                        </div>
                                        <div className="grow"></div>
                                    </div>
                                    <div className="fl-row">
                                        <div className="grow">
                                            <FormDropDown id={"select_blockType_" + j} label="" showLabel={false} showErrors={false} items={predefinedBlockDropDownOptions} currentIndex={selectedPredefinedBlockIndex} selectFunc={(e, id, item) => selectPredefinedBlock(item)} />
                                        </div>
                                        <div className="shrink">
                                            <button className="standard-button tight" onClick={(e) => {addVarSchemaPredefinedBlock(e, variable, variable.varName); forceRerender()}}>Add Var Block</button>
                                        </div>
                                        {/* <div className="shrink">
                                                                                                                                                    <button className="standard-button tight" onClick={(e) => addVarSchemaPredefinedBlock(e, variable, variable.varName, 'fontSettings')}>Add Font Options</button>
                                                                                                                                                </div>
                                                                                                                                                <div className="shrink">
                                                                                                                                                    <button className="standard-button tight" onClick={(e) => addVarSchemaPredefinedBlock(e, variable, variable.varName, 'animateSettings')}>Add Anim Options</button>
                                                                                                                                                </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CollapsibleHeadedSection>
                </div>

                {/* <div className="fl-row"></div> */}

                <div className="seeder-content-box full-width outline">
                    <CollapsibleHeadedSection title={`Initial Settings (${variable.vals ? variable.vals.length : '0'})`} open={false}>
                        <div className="fl-row fl-wrap">
                            {variable.vals && variable.vals.map(
                                (val, l) => {
                                    return (
                                        <div className="fl-column half-width fl-start customisation-controls-holder rel" key={"init_set_" + l}>
                                            <div className="control-group">
                                                <CustomisationControls
                                                    renderSingleControlBlock={true}
                                                    vals={[val]}
                                                    displaySettings={variable}
                                                    controlsData={featureData.customisation}
                                                    rootVals={null}
                                                    objRef={null}
                                                    varBoxWidthStyle={null}
                                                    updateFunction={
                                                        (newVal, valsArray, varSchema, index, valKey) => {
                                                            // console.log('Val changed: ', newVal);
                                                            if (CUSTOMISED_URL_MAP.hasOwnProperty(variable.valSchema[valKey].type.toLowerCase())) {
                                                                // File!
                                                                const fileNameSplit = newVal.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                                                                // fileNameSplit[0] += '__' + Date.now();
                                                                val[valKey].filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                                                                newVal.uniqueFilename = fileNameSplit.join('.');
                                                                val[valKey].newFile = newVal;
                                                                val[valKey].srcUrl = newVal ? URL.createObjectURL(newVal) : null;
                                                                val[valKey].isDefault = true;
                                                                val[valKey].updated = true;
                                                                // valsArray[index].isDefault = false;
                                                                // valsArray[index].updated = true;
                                                                // console.log('File handled: ', val[valKey]);
                                                            } else {
                                                                // Everything else!
                                                                val[valKey] = newVal;
                                                            }
                                                            if (!showLiveEditVarFamilyPopup) {
                                                                setFeatureData({ ...featureData_ref.current });
                                                            } else {
                                                                forceRerender();
                                                            }
                                                        }
                                                    }
                                                    highlightKey={schemaHighlighKey_int}
                                                    highlightNoValSet={true}
                                                />
                                            </div>
                                            <div className="grow"></div>
                                            {l > 0 &&
                                                <div className="del-icon" onClick={(e) => {removeInitialSetting(variable.vals, l); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                            }
                                        </div>
                                    )
                                }
                            )}
                            <div className="fl-row fl-start">
                                {(variable.allowMultiple || variable.vals.length === 0) && (variable.maxMultiples === -1 || variable.vals.length < variable.maxMultiples) &&
                                    <div className="shrink">
                                        <button className="standard-button tight" onClick={(e) => {addInitialSetting(variable.vals, variable.valSchema); forceRerender()}}>Add Initial Setting</button>
                                    </div>
                                }
                                {
                                    <div className="shrink">
                                        <button className="standard-button tight" onClick={(e) => {resetInitialSettings(variable.vals, variable.valSchema); forceRerender()}}>Reset Initial Settings</button>
                                    </div>
                                }
                                <div className="grow"></div>
                            </div>
                        </div>
                    </CollapsibleHeadedSection>
                </div>

                {/* <div className="fl-row"></div> */}

                <div className="seeder-content-box full-width outline">
                    <CollapsibleHeadedSection title={`Presets (${variable.presets ? variable.presets.length : '0'})`} open={false}>

                        <div className="fl-column">

                            {variable.presets && variable.presets.map(
                                (preset, l) => {
                                    return (
                                        <div className="seeder-content-box full-width outline" key={"preset_" + l}>
                                            <CollapsibleHeadedSection title={`${preset.name} (${preset.vals ? preset.vals.length : '0'})`} open={false}>
                                                <>
                                                    <div className="fl-column">
                                                        <FormField id={"preset" + l + "_name"} label="Name" placeholder="Enter Name" value={preset.name || ''} setFunc={(value) => changeObjectValue_int(preset, 'name', value)} showError={false} />
                                                    </div>
                                                    <div className="fl-row fl-wrap">
                                                        {preset.vals && preset.vals.map(
                                                            (val, l) => {
                                                                return (
                                                                    <div className="fl-column half-width fl-start customisation-controls-holder rel" key={"preset_val_" + l}>
                                                                        <div className="control-group">
                                                                            <CustomisationControls
                                                                                renderSingleControlBlock={true}
                                                                                highlightUndefined={true}
                                                                                showUndefinedToggle={true}
                                                                                toggleUndefinedFunc={
                                                                                    (presetObject, defaultValue, key) => {
                                                                                        if (typeof presetObject[key] === 'undefined') {
                                                                                            try {
                                                                                                presetObject[key] = JSON.parse(JSON.stringify(defaultValue));
                                                                                            } catch (error) {
                                                                                                presetObject[key] = defaultValue;
                                                                                            }
                                                                                        } else {
                                                                                            delete presetObject[key];
                                                                                        }
                                                                                        if (!showLiveEditVarFamilyPopup) {
                                                                                            setFeatureData({ ...featureData_ref.current });
                                                                                        } else {
                                                                                            forceRerender();
                                                                                        }
                                                                                    }
                                                                                }
                                                                                vals={[val]}
                                                                                displaySettings={variable}
                                                                                controlsData={featureData.customisation}
                                                                                rootVals={null}
                                                                                objRef={null}
                                                                                varBoxWidthStyle={null}
                                                                                updateFunction={
                                                                                    (newVal, valsArray, varSchema, index, valKey) => {
                                                                                        // console.log('Val changed: ', newVal);
                                                                                        if (CUSTOMISED_URL_MAP.hasOwnProperty(variable.valSchema[valKey].type.toLowerCase())) {
                                                                                            // File!
                                                                                            const fileNameSplit = newVal.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                                                                                            // fileNameSplit[0] += '__' + Date.now();
                                                                                            val[valKey].filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                                                                                            newVal.uniqueFilename = fileNameSplit.join('.');
                                                                                            val[valKey].newFile = newVal;
                                                                                            val[valKey].srcUrl = newVal ? URL.createObjectURL(newVal) : null;
                                                                                            val[valKey].isDefault = true;
                                                                                            val[valKey].updated = true;
                                                                                            // valsArray[index].isDefault = false;
                                                                                            // valsArray[index].updated = true;
                                                                                            // console.log('File handled: ', val[valKey]);
                                                                                        } else {
                                                                                            // Everything else!
                                                                                            val[valKey] = newVal;
                                                                                        }
                                                                                        if (!showLiveEditVarFamilyPopup) {
                                                                                            setFeatureData({ ...featureData_ref.current });
                                                                                        } else {
                                                                                            forceRerender();
                                                                                        }
                                                                                    }
                                                                                }
                                                                                highlightKey={schemaHighlighKey_int}
                                                                            />
                                                                        </div>
                                                                        <div className="grow"></div>
                                                                        {l > 0 &&
                                                                            <div className="del-icon" onClick={(e) => {removePresetSetting(preset.vals, l); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        )}
                                                        {(variable.allowMultiple || preset.vals.length === 0) && (variable.maxMultiples === -1 || preset.vals.length < variable.maxMultiples) &&
                                                            <div className="fl-row fl-start">
                                                                <div className="shrink">
                                                                    <button className="standard-button tight" onClick={(e) => {addPresetSetting(preset.vals, variable.valSchema); forceRerender()}}>Add Preset Setting</button>
                                                                </div>
                                                                <div className="grow"></div>
                                                            </div>
                                                        }
                                                    </div>
                                                </>
                                            </CollapsibleHeadedSection>

                                            <div className="del-icon" onClick={(e) => {removePreset(variable, l); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                        </div>
                                    )
                                }
                            )}

                            <div className="fl-row fl-start">
                                <div className="shrink">
                                    <button className="standard-button tight" onClick={(e) => {addPreset(variable); forceRerender()}}>Add Preset</button>
                                </div>
                                <div className="grow"></div>
                            </div>

                        </div>

                    </CollapsibleHeadedSection>
                </div>


                {/* <div className="fl-row"></div> */}

                <div className="seeder-content-box full-width outline">
                    <CollapsibleHeadedSection title={`Display Conditions (${variable.displayCondition ? variable.displayCondition.length : '0'})`} open={false}>
                        <div className="fl-column">
                            {variable.displayCondition && variable.displayCondition.map(
                                (condition, l) => {
                                    return (
                                        <div key={"condition" + l} className={`seeder-content-box full-width`}>
                                            <div className="fl-row equal">
                                                <FormField id={"condition" + l + "_var"} className="eval-var-width" label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue_int(condition, 'var', value)} showError={false} />
                                                <div className="eval-width">
                                                    <FormDropDown id={"condition" + l + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue_int(condition, 'evalCondition', item.value)} showErrors={false} />
                                                </div>
                                                <FormField id={"condition" + l + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue_int(condition, 'val', value)} showError={false} />
                                            </div>
                                            <div className="del-icon" onClick={(e) => {removeCustomisationVariableDisplayCondition(e, i, j, k, l); forceRerender()}}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                        </div>
                                    )
                                }
                            )}
                            <div className="fl-row">
                                <div className="shrink">
                                    <button className="standard-button tight" onClick={(e) => {addCustomsationVariableDisplayCondition(e, i, j, k); forceRerender()}}>Add Display Condition</button>
                                </div>
                                <div className="shrink">
                                    <button className="standard-button tight" onClick={(e) => {pasteCustomisationVariableDisplayConditionsList(e, i, j, k); forceRerender()}}>Paste Display Conditions List</button>
                                </div>
                                <div className="grow"></div>
                            </div>
                        </div>
                    </CollapsibleHeadedSection>
                    <div className="move-icons">
                        <div className="move-icon" onClick={(e) => copyCustomisationVariableDisplayConditionsList(e, i, j, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                    </div>
                </div>

                <div className="fl-row">
                    <div className="grow"></div>
                    <div className="shrink">
                        <button className="standard-button tight" onClick={(e) => console.log(variable)}>Log Out Data</button>
                    </div>
                    <div className="shrink">
                        <button className="standard-button tight" onClick={(e) => copyCustomisationVariable(e, i, j, k)}>Copy Var Family</button>
                    </div>

                </div>

            </div>
        )
    }

    const [showLiveEditVarFamilyPopup, setShowLiveEditVarFamilyPopup] = useState(false);
    const [liveEditVarFamilyPopupData, setLiveEditVarFamilyPopupData] = useState(null);
    const openLiveEditVarFamilyPopup = (variable, customisation, option, index1, index2, index3, tab) => {
        setLiveEditVarFamilyPopupData({ tab, variable, customisation, option, index1, index2, index3 });
        setShowLiveEditVarFamilyPopup(true);
    }
    const closeLiveEditVarFamilyPopup = () => {
        setShowLiveEditVarFamilyPopup(false);
    }
    const LiveEditVarFamilyPopup = (props) => {
        // console.log('Live fam pop: ', liveEditVarFamilyPopupData.option, liveEditVarFamilyPopupData.variable);
        return (
            <PopUpPanel closePanelFunc={closeLiveEditVarFamilyPopup} className="live-edit-var-family-pop-up">
                <div className="live-edit-var-family-popup-content form-holder">

                    <div className="fl-column compact">
                        <h4>
                            {liveEditVarFamilyPopupData.customisation.tabTitle} &gt; {liveEditVarFamilyPopupData.option.groupTitle} &gt; {liveEditVarFamilyPopupData.variable.label}
                        </h4>
                        <div className="fl-row light-text">
                            {liveEditVarFamilyPopupData.customisation.tabKey}.{liveEditVarFamilyPopupData.option.varKey}.{liveEditVarFamilyPopupData.variable.varName}
                        </div>
                        <div className="fl-row"></div>
                    </div>

                    <EditVarFamily variable={liveEditVarFamilyPopupData.variable} customisation={liveEditVarFamilyPopupData.customisation} option={liveEditVarFamilyPopupData.option} index1={liveEditVarFamilyPopupData.index1} index2={liveEditVarFamilyPopupData.index2} index3={liveEditVarFamilyPopupData.index3} rerenderImmediately={false} />

                </div>
            </PopUpPanel>
        )
    }

    return (
        <>
        {!showPreview &&
        <div className="content-page-container customise-holder form-holder">
            <div className="content-page-top">
                <div className="fl-column grow">
                    <div className="fl-row grow">
                        <div>
                            <h1>Feature Seeder Editor</h1>
                        </div>
                        <div className="grow"></div>
                        <div className="main-top-options" onClick={logOutFeatureDataForDev}>Log Out Dev Data |0|</div>
                        <div className="main-top-options" onClick={saveToLocalStorage}>Save to Local Storage {IconJsxer.GetIcon(IconJsxer.ICONS.localStorage, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>                      
                        <div className="main-top-options" onClick={buildSeederData}>Export Seeder JSON File {IconJsxer.GetIcon(IconJsxer.ICONS.save, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>
                        {/*<div className={`main-top-options${editingFeatureDbId === '' ? ' light-text not-allowed' : ''}`} onClick={() =>{}}>Save Changes To DB {IconJsxer.GetIcon(IconJsxer.ICONS.upload, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>*/}
                    </div>
                    <div className="fl-row">
                        {seederListDropDownData && seederListDropDownData.length > 0 && featureListDropDownData && featureListDropDownData.length > 0 &&
                            <>
                                <div className="fl-column">
                                    <FormDropDown id="seeder-list" label="Edit existing seeder" items={seederListDropDownData} currentIndex={selectedSeeder.index} selectFunc={selectSeeder} showErrors={false} />
                                </div>
                                <div className="fl-column no-gap align-bottom">
                                    <button className={`standard-button tight${(busy || selectedSeeder.index === 0) ? ' button-inactive' : ''}`} onClick={(busy || selectedSeeder.index === 0) ? (e) => {e.preventDefault()} : editSeeder}>Edit</button>
                                </div>
                                <div className="fl-column">
                                    <FormDropDown id="feature-list" label="Edit feature from Db" items={featureListDropDownData} currentIndex={selectedFeature.index} selectFunc={selectFeature} showErrors={false} />
                                </div>
                                <div className="fl-column no-gap align-bottom">
                                    <button className={`standard-button tight${(busy || selectedFeature.index === 0) ? ' button-inactive' : ''}`} onClick={(busy || selectedFeature.index === 0) ? (e) => {e.preventDefault()} : editFeature}>Edit</button>
                                </div>
                                <div className="fl-column">
                                    <FormDropDown id="local-storage-list" label="Load from local storage" items={localStorageList} currentIndex={selectedLocalStorageItem.index} selectFunc={(e, id, item) => selectLocalStorageItem(item)} showErrors={false} />
                                </div>
                                <div className="fl-column no-gap align-bottom">
                                    <button className={`standard-button tight${(busy || selectedLocalStorageItem.index === 0) ? ' button-inactive' : ''}`} onClick={(busy || selectedLocalStorageItem.index === 0) ? (e) => {e.preventDefault()} : loadFromLocalStorage}>Edit</button>
                                </div>
                                <div className="fl-column no-gap align-bottom">
                                    <button className={`standard-button tight${busy ? ' button-inactive' : ''}`} onClick={chooseJsonFile}>Import JSON File</button>
                                </div>
                                <div className="fl-column no-gap align-bottom">
                                    <button className={`standard-button tight${busy ? ' button-inactive' : ''}`} onClick={chooseDefaultsJsonFile}>Import Defaults JSON File</button>
                                </div>
                                <div className="fl-column grow"></div>
                                <div className="fl-column no-gap align-bottom">
                                    <button className={`standard-button tight${busy ? ' button-inactive' : ''}`} onClick={busy ? (e) => {e.preventDefault()} : clearFeatureData}>Clear Data and Start Over</button>
                                </div>
                                <div className="fl-column no-gap align-bottom">
                                    <button className={`standard-button tight${busy ? ' button-inactive' : ''}`} onClick={busy ? (e) => {e.preventDefault()} : deleteAutoSaves}>Delete Auto Saves</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>


            {featureData &&
                <>
                    <div className="content-page-content-container">
                        <div className="content-page-main-content seeder-editor-panel">

                            <div className="co-tab-content fl-column">
                                <div className="fl-row">
                                    <p className="light-text bold-text">{featureData.featureName ? featureData.featureName + ':' : ''} {editingFeatureDbId === '' ? 'Feature currently has no ID (not pulled from DB)' : 'Editing Feature From DB (ID: ' + editingFeatureDbId + ')'}</p>
                                </div>
                                <div className="fl-row"></div>
                            </div>

                            <div className="co-tab-content fl-column">

                                <div className={`co-tab-content-area fl-equal`}>
                                    <CollapsibleHeadedSection title="Basics" open={true}>
                                        <>
                                            <div className="fl-column">
                                                <div className="fl-row"></div>
                                                <div className="fl-row equal">
                                                    <FormField id="featureName" label="Feature Name" placeholder="Enter Feature Name" value={featureData.featureName || ''} setFunc={(value) => changeObjectValue(featureData_ref.current, 'featureName', value)} />
                                                    <FormField id="varKey" label="Var Key" placeholder="Enter Var Key" value={featureData.varKey || ''} setFunc={(value) => {skipAutoSave.current = true; changeObjectValue(featureData_ref.current, 'varKey', value)}} />
                                                    <FormField id="featureUrl" label="Feature URL" labelNote="Make sure to include trailing /, so /disruptor/folder/" placeholder="Enter Feature URL" value={featureData.featureURL || ''} setFunc={(value) => changeObjectValue(featureData_ref.current, 'featureURL', value)} />
                                                    <FormField id="assetFolder" label="Default Asset Folder" labelNote="No slashes - just the folder name on s3" placeholder="Enter Default Asset Folder" value={featureData.defaultAssetFolder || ''} setFunc={(value) => changeObjectValue(featureData_ref.current, 'defaultAssetFolder', value)} />
                                                </div>
                                                <div className="fl-row equal">

                                                    <div className="fl-row fl-50-perc">
                                                        <TextArea id="featureDescription" label="Feature Description" placeholder="Enter Feature Description" value={featureData.featureDescription || ''} setFunc={(value) => changeObjectValue(featureData_ref.current, 'featureDescription', value)} />
                                                    </div>

                                                    <div className="fl-row grow">
                                                        <div className="fl-column grow">
                                                            <FormDropDown id="featureType" label="Feature Type" items={FEATURE_TYPES} currentIndex={getSelectedIndex(FEATURE_TYPES, featureData_ref.current, 'featureType')} selectFunc={(e, id, item) => setValFromDropDown(item, featureData_ref.current, 'featureType')} />
                                                            <div className="fl-row">
                                                                <div className="label">Chainable?</div>
                                                                <div className="grow"></div>
                                                                <div className="shrink">
                                                                    <Toggle id="isChainable" currentState={featureData.isChainable || false} toggleFunc={(e, id) => changeObjectValue(featureData_ref.current, 'isChainable', !(featureData.isChainable || false))} />
                                                                </div>
                                                                
                                                                <div className="label"> </div>
                                                                <div className="grow"></div>
                                                                <div className="shrink">
                                                                    {/* <Toggle id="tritchArcade" currentState={featureData.isTwitchArcade !== undefined ? featureData.isTwitchArcade : false} toggleFunc={(e, id) => changeObjectValue(featureData_ref.current, 'isTwitchArcade', !(featureData.isTwitchArcade !== undefined ? featureData.isTwitchArcade : false))} /> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="fl-column grow">
                                                            <FormDropDown id="colourCategory" label="Colour Category" items={COLOUR_CATEGORIES} currentIndex={getSelectedIndex(COLOUR_CATEGORIES, featureData_ref.current, 'colourCategory')} selectFunc={(e, id, item) => setValFromDropDown(item, featureData_ref.current, 'colourCategory')} />
                                                            <div className="fl-row">
                                                                <div className="label">Requires Pixi?</div>
                                                                <div className="grow"></div>
                                                                <div className="shrink">
                                                                    <Toggle id="requiresPixi" currentState={featureData.requiresPixi !== undefined ? featureData.requiresPixi : true} toggleFunc={(e, id) => changeObjectValue(featureData_ref.current, 'requiresPixi', !(featureData.requiresPixi !== undefined ? featureData.requiresPixi : true))} />
                                                                </div>
                                                                
                                                                <div className="label">Twitch Arcade?</div>
                                                                <div className="grow"></div>
                                                                <div className="shrink">
                                                                    <Toggle id="tritchArcade" currentState={featureData.isTwitchArcade !== undefined ? featureData.isTwitchArcade : false} toggleFunc={(e, id) => changeObjectValue(featureData_ref.current, 'isTwitchArcade', !(featureData.isTwitchArcade !== undefined ? featureData.isTwitchArcade : false))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <h1>{featureData.featureName}</h1>
                                            <h2>{featureData.featureType}</h2>
                                            <p>{featureData.varKey}</p>
                                            <p>{featureData.featureURL}</p> */}
                                        </>
                                    </CollapsibleHeadedSection>
                                </div>

                                <div className="fl-row"></div>

                                <div className={`co-tab-content-area fl-equal`}>
                                    <CollapsibleHeadedSection title={`Commands (${featureData.commands ? featureData.commands.length : '0'})`} open={false} id="commands_collapsible" onCollapseToggle={onCollapseToggle}>
                                        <>
                                        {expandedCollapsibles.indexOf('commands_collapsible') !== -1 &&
                                        <>
                                            

                                            <div className="fl-column">

                                                <div className="fl-row"></div>

                                                <div className="fl-row fl-wrap">
                                                    {featureData.commands && featureData.commands.map(
                                                        (command, i) => {
                                                            // console.log('Command: ', command);
                                                            if (typeof command.triggerParamStringify === 'undefined') {
                                                                // These will need parsing and then removing when we save
                                                                command.triggerParamStringify = JSON.stringify(command.triggerParam, null, 2);
                                                            }
                                                            return (
                                                                <div key={"command_" + i} className={`seeder-content-box quarter-width fl-start${command.isTestCommand ? ' test-command' : ''}`}>
                                                                    <FormField id={"command_" + i + "_title"} label="Title" placeholder="Enter Command Title" value={command.title || ''} setFunc={(value) => changeObjectValue(command, 'title', value)} showError={false} />
                                                                    <TextArea id={"command_" + i + "_description"} rows={3} label="Description" placeholder="Enter Description" value={command.description || ''} setFunc={(value) => changeObjectValue(command, 'description', value)} showError={false} />
                                                                    <FormField id={"command_" + i + "_command"} label="Command" placeholder="Enter Command" value={command.command || ''} setFunc={(value) => changeObjectValue(command, 'command', value)} showError={false} />
                                                                    
                                                                    <div className={`seeder-content-box full-width outline fl-start`}>
                                                                        <CollapsibleHeadedSection title="Configure" open={false}>
                                                                        <>

                                                                            <div className="fl-row">
                                                                                <div className="label">Test Command</div>
                                                                                <div className="grow"></div>
                                                                                <div className="shrink">
                                                                                    <Toggle id={"command_" + i + "_isTestCommand"} currentState={command.isTestCommand || false}
                                                                                        toggleFunc={
                                                                                            (e, id) => {
                                                                                                changeObjectValue(command, 'isTestCommand', !(command.isTestCommand || false));
                                                                                                if (command.isTestCommand) {
                                                                                                    changeObjectValue(command, 'isHashtagEnabled', false);
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div className="fl-row">
                                                                                <div className="label">Hashtag Enabled</div>
                                                                                <div className="grow"></div>
                                                                                <div className="shrink">
                                                                                    <Toggle id={"command_" + i + "_isHashtagEnabled"} enabled={!command.isTestCommand} currentState={command.isHashtagEnabled || false} toggleFunc={(e, id) => changeObjectValue(command, 'isHashtagEnabled', !(command.isHashtagEnabled || false))} />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                                {command.isHashtagEnabled &&
                                                                                    <>
                                                                                        <div className="fl-row">
                                                                                            <div className="label">Hashtag Required</div>
                                                                                            <div className="grow"></div>
                                                                                            <div className="shrink">
                                                                                                <Toggle id={"command_" + i + "_isRequired"} enabled={!command.isTestCommand} currentState={command.isRequired || false} toggleFunc={(e, id) => changeObjectValue(command, 'isRequired', !(command.isRequired || false))} />
                                                                                            </div>

                                                                                        </div>
                                                                                        {!command.isRequired &&
                                                                                            <div className="fl-row">
                                                                                                <div className="label">Add Hashtag By Default</div>
                                                                                                <div className="grow"></div>
                                                                                                <div className="shrink">
                                                                                                    <Toggle id={"command_" + i + "_addByDefault"} enabled={!command.isTestCommand} currentState={command.addByDefault || false} toggleFunc={(e, id) => changeObjectValue(command, 'addByDefault', !(command.addByDefault || false))} />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        <div className="fl-row">
                                                                                            <div className="label">Restricted Hashtag By Default</div>
                                                                                            <div className="grow"></div>
                                                                                            <div className="shrink">
                                                                                                <Toggle id={"command_" + i + "_defaultRestricted"} enabled={!command.isTestCommand} currentState={command.defaultRestricted || false} toggleFunc={(e, id) => changeObjectValue(command, 'defaultRestricted', !(command.defaultRestricted || false))} />
                                                                                            </div>

                                                                                        </div>

                                                                                        <FormField id={"command_" + i + "_defaultHashtag"} label="Default Hashtag" placeholder="Enter Default Hashtag" value={command.defaultHashtag || ''} setFunc={(value) => changeObjectValue(command, 'defaultHashtag', value)} showError={false} />
                                                                                    </>
                                                                                }
                                                                            <div className="fl-row">
                                                                                <div className="label">Start Trigger</div>
                                                                                <div className="grow"></div>
                                                                                <div className="shrink">
                                                                                    <Toggle id={"command_" + i + "_isPrimaryTrigger"} enabled={!command.isEndTrigger && !command.isTestCommand} currentState={command.isPrimaryTrigger || false} toggleFunc={(e, id) => changeObjectValue(command, 'isPrimaryTrigger', !(command.isPrimaryTrigger || false))} />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            {command.isPrimaryTrigger &&
                                                                                <TextArea id={"command_" + i + "_triggerParam"} label="Trigger Param" labelNote="Can be an object (see Sub Bomb).<br />Usually number or string (0 if not needed)." placeholder="Enter Trigger Param" value={command.triggerParamStringify || ''} setFunc={(value) => changeObjectValue(command, 'triggerParamStringify', value, false)} showError={false} />
                                                                            }
                                                                            <div className="fl-row">
                                                                                <div className="label">End Trigger</div>
                                                                                <div className="grow"></div>
                                                                                <div className="shrink">
                                                                                    <Toggle id={"command_" + i + "_isEndTrigger"} enabled={!command.isPrimaryTrigger && !command.isTestCommand} currentState={command.isEndTrigger || false} toggleFunc={(e, id) => changeObjectValue(command, 'isEndTrigger', !(command.isEndTrigger || false))} />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            <div className="fl-row">
                                                                                <div className="label">Preview Quick Command</div>
                                                                                <div className="grow"></div>
                                                                                <div className="shrink">
                                                                                    <Toggle id={"command_" + i + "_previewQuickCommand"} currentState={command.previewQuickCommand || false} toggleFunc={(e, id) => changeObjectValue(command, 'previewQuickCommand', !(command.previewQuickCommand || false))} />
                                                                                </div>
                                                                                
                                                                            </div>
                                                                            {command.previewQuickCommand &&
                                                                                <>
                                                                                    <div className="fl-row equal">
                                                                                        <div className="fl-column grow">
                                                                                            <FormField id={"button" + i + "_icon"} label="Icon" placeholder="Enter Icon" value={command.previewQuickIcon || ''} setFunc={(value) => changeObjectValue(command, 'previewQuickIcon', value)} showError={false} />
                                                                                        </div>
                                                                                        <div className="fl-column grow">
                                                                                            <FormDropDown id={"button" + i + "_iconString"} label="Choose" items={ICONS_OPTIONS} currentIndex={getSelectedIndex(ICONS_OPTIONS, command, 'previewQuickIcon')} selectFunc={(e, id, item) => item.index !== 0 && setValFromDropDown(item, command, 'previewQuickIcon')} showErrors={false} />
                                                                                        </div>
                                                                                        {command.previewQuickIcon && command.previewQuickIcon !== '' &&
                                                                                            <div className="fl-column shrink icon-preview">
                                                                                                {IconJsxer.GetIcon(command.previewQuickIcon, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    {/* <FormField id={"command_" + i + "_previewQuickIcon"} label="Preview Quick Icon" placeholder="Enter Preview Quick Icon" value={command.previewQuickIcon || ''} setFunc={(value) => changeObjectValue(command, 'previewQuickIcon', value)} showError={false} /> */}
                                                                                    <FormField id={"command_" + i + "_previewQuickParam"} label="Preview Quick Param" placeholder="Enter Preview Quick Param" value={command.previewQuickParam} setFunc={(value) => changeObjectValue(command, 'previewQuickParam', value)} showError={false} />
                                                                                </>
                                                                            }
                                                                            <FormField id={"command_" + i + "_param"} label="Param" labelNote="String or number, enter a range as '1-100'.<br />Enter a varKey for auto buttons (see Audio)." placeholder="Enter Param" value={command.param} setFunc={(value) => changeObjectValue(command, 'param', value)} showError={false} />


                                                                        </>
                                                                        </CollapsibleHeadedSection>
                                                                    </div>

                                                                    {!command.isPrimaryTrigger && !command.isEndTrigger && 
                                                                        <div className='del-icon' onClick={(e) => removeCommand(e, i)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                    )}
                                                    <div className="shrink fl-start">
                                                        <button className="standard-button tight" onClick={addCommand}>Add Command</button>
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                        }
                                        </>
                                    </CollapsibleHeadedSection>
                                </div>

                                <div className="fl-row"></div>

                                <div className={`co-tab-content-area fl-equal`}>
                                    <CollapsibleHeadedSection title={`Live Controls (${featureData.defaultVars ? featureData.defaultVars.length : '0'})`} open={false} id="livecontrols_collapsible" onCollapseToggle={onCollapseToggle}>
                                        <>
                                        {expandedCollapsibles.indexOf('livecontrols_collapsible') !== -1 &&
                                        <div className="fl-row">
                                        <div className="fl-column fe-three-quarter-width fl-start">

                                                <div className="fl-row"></div>

                                                <div className="fl-row fl-wrap">

                                                    {featureData.defaultVars && featureData.defaultVars.map(
                                                        (varGroup, i) => {
                                                            return (
                                                                    <div key={"varGroup" + i} className={`seeder-content-box width-99 fl-start`}>
                                                                        <div className="fl-row">
                                                                            <div className="grow">
                                                                                <FormField id={"varGroup" + i + "_groupTitle"} label="Group Title" placeholder="Enter Group Title" value={varGroup.groupTitle || ''} setFunc={(value) => changeObjectValue(varGroup, 'groupTitle', value)} showError={false} />
                                                                            </div>
                                                                            <div className="grow">
                                                                                <FormField id={"varGroup" + i + "_varKey"} label="Var Key" placeholder="Enter Var Key" value={varGroup.varKey || ''} setFunc={(value) => changeObjectValue(varGroup, 'varKey', value)} showError={false} />
                                                                            </div>
                                                                            <div className="fl-row shrink">
                                                                                <div className="label">Preview Only</div>
                                                                                <div className="shrink">
                                                                                    <Toggle id={"varGroup" + i + "_previewModeOnly"} currentState={varGroup.previewModeOnly || false} toggleFunc={(e, id) => changeObjectValue(varGroup, 'previewModeOnly', !(varGroup.previewModeOnly || false))} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="fl-row shrink">
                                                                                <div className="label">Start Expanded</div>
                                                                                <div className="shrink">
                                                                                    <Toggle id={"varGroup" + i + "_startExpanded"} currentState={varGroup.startExpanded || false} toggleFunc={(e, id) => changeObjectValue(varGroup, 'startExpanded', !(varGroup.startExpanded || false))} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                       {/*  <div className="fl-row"></div> */}
                                                                        
                                                                        <div className="seeder-content-box full-width outline">
                                                                        <CollapsibleHeadedSection title={`Variables (${featureData.defaultVars[i].variables ? featureData.defaultVars[i].variables.length : '0'})`} open={false}>
                                                                            <>
                                                                                <div className="fl-column">

                                                                                    <div className="fl-row"></div>

                                                                                    <div className="fl-row fl-wrap">

                                                                                        {varGroup.variables && varGroup.variables.map(
                                                                                            (variable, j) => {
                                                                                                if (typeof variable.__ed__setChainRestrictedValue === 'undefined') {
                                                                                                    if (variable.chainRestrictedValue) {
                                                                                                        variable.__ed__setChainRestrictedValue = true;
                                                                                                    } else {
                                                                                                        variable.__ed__setChainRestrictedValue = false;
                                                                                                    }
                                                                                                }
                                                                                                if (!variable.valOptions || variable.valOptions.length === 0) {
                                                                                                    variable.valOptions = [{label: 'Option 1', val: 'option1'}];
                                                                                                }
                                                                                                /* if (variable.dashboardDisplayType === FEATURE_CONTROLS_TYPES.ColourPicker.type) {
                                                                                                    variable.varType = VAR_TYPES.stringType;
                                                                                                    if (typeof variable.defaultVal !== 'string') {
                                                                                                        variable.defaultVal = String(variable.defaultVal);
                                                                                                    }
                                                                                                } */
                                                                                                return (
                                                                                                    <div key={"variable" + j} className={`seeder-content-box third-width fl-start`}>

                                                                                                        <FormField id={"variable" + j + "_label"} label="Label" placeholder="Enter Label" value={variable.label || ''} setFunc={(value) => changeObjectValue(variable, 'label', value)} showError={false} />
                                                                                                        <TextArea id={"variable" + j + "_description"} rows={3} label="Description" placeholder="Enter Description" value={variable.description || ''} setFunc={(value) => changeObjectValue(variable, 'description', value)} showError={false} />
                                                                                                        <FormField id={"variable" + j + "_varName"} label="Var Name" placeholder="Enter Var Name" value={variable.varName || ''} setFunc={(value) => changeObjectValue(variable, 'varName', value)} showError={false} />
                                                                                                        <div className="label-note">{`${varGroup.varKey}.${variable.varName}`}</div>
                                                                                                        
                                                                                                        <div className={`seeder-content-box full-width outline fl-start`}>
                                                                                                            <CollapsibleHeadedSection title="Configure" open={false}>
                                                                                                            <>
                                                                                                                <div className="fl-row equal">
                                                                                                                    <FormDropDown id={"variable" + j + "_dashboardDisplayType"} className="grow" label="Dashboard Display Type" items={getControlTypes()} currentIndex={getSelectedIndex(getControlTypes(), variable, 'dashboardDisplayType')} 
                                                                                                                        selectFunc={
                                                                                                                            (e, id, item) => {
                                                                                                                                setValFromDropDown(item, variable, 'dashboardDisplayType');
                                                                                                                                changeObjectValue(variable, 'varType', item.defaultVarType);
                                                                                                                            }
                                                                                                                        } 
                                                                                                                        showErrors={false} 
                                                                                                                    />
                                                                                                                    <FormDropDown id={"variable" + j + "_varType"} className="grow" label="Var Type" items={getVarTypes()} currentIndex={getSelectedIndex(getVarTypes(), variable, 'varType')} selectFunc={(e, id, item) => setValFromDropDown(item, variable, 'varType')} showErrors={false} />
                                                                                                                </div>

                                                                                                                <div className="fl-row">
                                                                                                                    <div className="label no-wrap">Reset Default On Load<br /><span className="label-note">When previewing / initial pulling in of vars,<br />reset to default.</span></div>
                                                                                                                    <div className="grow"></div>
                                                                                                                    <div className="shrink">
                                                                                                                        <Toggle id={"option" + j + "_resetDefaultOnLoad"} currentState={variable.resetDefaultOnLoad || false} toggleFunc={(e, id) => changeObjectValue(variable, 'resetDefaultOnLoad', !(variable.resetDefaultOnLoad || false))} />
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                {variable.resetDefaultOnLoad &&
                                                                                                                    <div className="fl-row equal">
                                                                                                                        <FormField id={"variable" + j + "_useCustomisationValueOnLoad"} type='text' label="Set Default from Customisations" placeholder="Enter Var Location" value={variable.useCustomisationValueOnLoad || ''} setFunc={(value) => changeObjectValue(variable, 'useCustomisationValueOnLoad', value)} showError={false} />
                                                                                                                    </div>
                                                                                                                }
                                                                                                                
                                                                                                                {(variable.varType === VAR_TYPES.stringType || variable.varType === VAR_TYPES.numberType) &&
                                                                                                                    <div className="fl-row equal">
                                                                                                                        <FormField id={"variable" + j + "_defaultVal"} type={`${variable.varType === VAR_TYPES.numberType ? 'number' : 'text'}`} label="Default Value" placeholder="Enter Value" value={variable.defaultVal} setFunc={(value) => changeObjectValue(variable, 'defaultVal', value)} showError={false} />
                                                                                                                        {featureData.isChainable &&
                                                                                                                            <FormField id={"variable" + j + "_chainRestrictedValue"} type={`${variable.varType === VAR_TYPES.numberType ? 'number' : 'text'}`} label="Chain Restricted Value" placeholder="Not Set" value={variable.chainRestrictedValue} setFunc={(value) => changeObjectValue(variable, 'chainRestrictedValue', value)} showError={false} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                }
                                                                                                                {variable.varType === VAR_TYPES.booleanType &&
                                                                                                                    <>
                                                                                                                        <div className="fl-row">
                                                                                                                            <div className="label">Default Value</div>
                                                                                                                            <div className="grow"></div>
                                                                                                                            <div className="shrink">
                                                                                                                                <Toggle id={"variable" + j + "_defaultVal"} currentState={variable.defaultVal || false} toggleFunc={(e, id) => changeObjectValue(variable, 'defaultVal', !(variable.defaultVal || false))} />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        {featureData.isChainable &&
                                                                                                                        <>
                                                                                                                            <div className="fl-row">
                                                                                                                                <div className="label">Set Chain Restricted Value</div>
                                                                                                                                <div className="grow"></div>
                                                                                                                                <div className="shrink">
                                                                                                                                    <Toggle id={"variable" + j + "_setChainRestrictedValue"} currentState={variable.__ed__setChainRestrictedValue || false} 
                                                                                                                                        toggleFunc={
                                                                                                                                            (e, id) => {
                                                                                                                                                const newValue = !(variable.__ed__setChainRestrictedValue || false);
                                                                                                                                                changeObjectValue(variable, '__ed__setChainRestrictedValue', newValue);
                                                                                                                                                // Check if we have a chain restricted value set, if not, set it to the default value
                                                                                                                                                if (newValue && typeof variable.chainRestrictedValue === 'undefined') {
                                                                                                                                                    changeObjectValue(variable, 'chainRestrictedValue', variable.defaultVal || false);
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        } 
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="fl-row">
                                                                                                                                <div className="label">Chain Restricted Value</div>
                                                                                                                                <div className="grow"></div>
                                                                                                                                <div className="shrink">
                                                                                                                                    <Toggle id={"variable" + j + "_chainRestrictedValue"} enabled={variable.__ed__setChainRestrictedValue} currentState={variable.chainRestrictedValue || false} toggleFunc={(e, id) => changeObjectValue(variable, 'chainRestrictedValue', !(variable.chainRestrictedValue || false))} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="fl-row">
                                                                                                                                <div className="label">Important (highlight)</div>
                                                                                                                                <div className="grow"></div>
                                                                                                                                <div className="shrink">
                                                                                                                                    <Toggle id={"variable" + j + "_fullHighlight"} currentState={variable.fullHighlight || false} toggleFunc={(e, id) => changeObjectValue(variable, 'fullHighlight', !(variable.fullHighlight || false))} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                }
                                                                                                                
                                                                                                                {variable.dashboardDisplayType !== FEATURE_CONTROLS_TYPES.ColourPicker.type && variable.dashboardDisplayType !== FEATURE_CONTROLS_TYPES.DropDown.type && variable.dashboardDisplayType !== FEATURE_CONTROLS_TYPES.Radio.type && variable.dashboardDisplayType !== FEATURE_CONTROLS_TYPES.RadioVertical.type && variable.varType === VAR_TYPES.stringType &&
                                                                                                                    <FormField id={"variable" + j + "_maxLength"} label="Max Length" placeholder="Enter Max Length" value={variable.maxLength} setFunc={(value) => changeObjectValue(variable, 'maxLength', value)} showError={false} />
                                                                                                                }
                                                                                                                
                                                                                                                {variable.dashboardDisplayType !== FEATURE_CONTROLS_TYPES.DropDown.type && variable.dashboardDisplayType !== FEATURE_CONTROLS_TYPES.Radio.type && variable.dashboardDisplayType !== FEATURE_CONTROLS_TYPES.RadioVertical.type && variable.varType === VAR_TYPES.numberType &&
                                                                                                                    <div className="fl-row equal">
                                                                                                                        <FormField id={"variable" + j + "_minVal"} label="Min Value" type="number" placeholder="Enter Min Value" value={variable.minVal} setFunc={(value) => changeObjectValue(variable, 'minVal', value)} showError={false} />
                                                                                                                        <FormField id={"variable" + j + "_maxVal"} label="Max Value" type="number" placeholder="Enter Max Value" value={variable.maxVal} setFunc={(value) => changeObjectValue(variable, 'maxVal', value)} showError={false} />
                                                                                                                    </div>
                                                                                                                }

                                                                                                                    {variable.dashboardDisplayType === FEATURE_CONTROLS_TYPES.Range.type &&
                                                                                                                        <>
                                                                                                                            <div className="fl-row">
                                                                                                                                <div className="label no-wrap">Show Value</div>
                                                                                                                                <div className="grow"></div>
                                                                                                                                <div className="shrink">
                                                                                                                                    <Toggle id={"variable" + j + "_showCurrentValue"} currentState={typeof variable.showCurrentValue !== 'undefined' ? variable.showCurrentValue : true} toggleFunc={(e, id) => changeObjectValue(variable, 'showCurrentValue', !(variable.showCurrentValue || false))} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="fl-row">
                                                                                                                                <div className="label no-wrap">Use Text Labels</div>
                                                                                                                                <div className="grow"></div>
                                                                                                                                <div className="shrink">
                                                                                                                                    <Toggle id={"variable" + j + "_useTextLabels"} currentState={variable.useTextLabels || false} toggleFunc={(e, id) => changeObjectValue(variable, 'useTextLabels', !(variable.useTextLabels || false))} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            {variable.useTextLabels &&
                                                                                                                                <>
                                                                                                                                    <FormField id={"variable" + j + "_minLabel"} label="Min Label" placeholder="Enter Min Label" value={variable.minLabel} setFunc={(value) => changeObjectValue(variable, 'minLabel', value)} showError={false} />
                                                                                                                                    <FormField id={"variable" + j + "_midLabel"} label="Mid Label" placeholder="Enter Mid Label" value={variable.midLabel} setFunc={(value) => changeObjectValue(variable, 'midLabel', value)} showError={false} />
                                                                                                                                    <FormField id={"variable" + j + "_maxLabel"} label="Max Label" placeholder="Enter Max Label" value={variable.maxLabel} setFunc={(value) => changeObjectValue(variable, 'maxLabel', value)} showError={false} />
                                                                                                                                </>
                                                                                                                            }
                                                                                                                        </>
                                                                                                                    }
                                                                                                                
                                                                                                                {(variable.dashboardDisplayType === FEATURE_CONTROLS_TYPES.DropDown.type || variable.dashboardDisplayType === FEATURE_CONTROLS_TYPES.Radio.type || variable.dashboardDisplayType === FEATURE_CONTROLS_TYPES.RadioVertical.type) && Array.isArray(variable.valOptions) &&
                                                                                                                    <CollapsibleHeadedSection title={`Options (${variable.valOptions ? variable.valOptions.length : '0'})`} open={false}>
                                                                                                                        <div className="fl-column">
                                                                                                                            {variable.valOptions && variable.valOptions.map(
                                                                                                                                (option, k) => {
                                                                                                                                    const valKey = option.val ? 'val' : 'value';
                                                                                                                                    return (
                                                                                                                                        <div key={"option" + k} className={`seeder-content-box full-width`}>
                                                                                                                                            <div className="fl-row equal">
                                                                                                                                                <FormField id={"option" + k + "_option"} label="Label" placeholder="Enter Label" value={option.label || ''} setFunc={(value) => changeObjectValue(option, 'label', value)} showError={false} />
                                                                                                                                                {(variable.varType === VAR_TYPES.stringType || variable.varType === VAR_TYPES.numberType) &&
                                                                                                                                                    <FormField id={"option" + k + "_val"} type={`${variable.varType === VAR_TYPES.numberType ? 'number' : 'text'}`} label="Value" placeholder="Enter Value" value={option[valKey]} setFunc={(value) => changeObjectValue(option, valKey, value)} showError={false} />
                                                                                                                                                }
                                                                                                                                                {variable.varType === VAR_TYPES.booleanType &&
                                                                                                                                                    <div className="fl-row shrink">
                                                                                                                                                        <div className="label">Value</div>
                                                                                                                                                        <div className="shrink">
                                                                                                                                                            <Toggle id={"option" + k + "_val"} currentState={option[valKey] || false} toggleFunc={(e, id) => changeObjectValue(option, valKey, !(option[valKey] || false))} />
                                                                                                                                                        </div>
                                                                                                                                                        <div className="grow"></div>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                            <div className="move-icons">
                                                                                                                                                <div className='move-icon' onClick={(e) => moveValOption(variable.valOptions, k, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                <div className='move-icon' onClick={(e) => moveValOption(variable.valOptions, k, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                            </div>
                                                                                                                                            <div className='del-icon' onClick={(e) => {variable.valOptions.splice(k, 1); setFeatureData({...featureData_ref.current})}}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            )}

                                                                                                                            <button className="standard-button tight" onClick={(e) => addValOption(e, i, j)}>Add Option</button>
                                                                                                                        </div>
                                                                                                                    </CollapsibleHeadedSection>
                                                                                                                }

                                                                                                            </>
                                                                                                            </CollapsibleHeadedSection>
                                                                                                        </div>

                                                                                                        <div className={`seeder-content-box full-width outline fl-start`}>
                                                                                                            <CollapsibleHeadedSection title={`Display Conditions (${variable.displayCondition ? variable.displayCondition.length : '0'})`} open={false}>
                                                                                                                <div className="fl-column">
                                                                                                                    {variable.displayCondition && variable.displayCondition.map(
                                                                                                                        (condition, k) => {
                                                                                                                            return (
                                                                                                                                <div key={"condition" + k} className={`seeder-content-box full-width`}>
                                                                                                                                    <div className="fl-row equal">
                                                                                                                                        <FormField id={"condition" + k + "_var"} className="eval-var-width-live" label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue(condition, 'var', value)} showError={false} />
                                                                                                                                        <div className="eval-width">
                                                                                                                                            <FormDropDown id={"condition" + k + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue(condition, 'evalCondition', item.value)} showErrors={false} />
                                                                                                                                        </div>
                                                                                                                                        <FormField id={"condition" + k + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue(condition, 'val', value)} showError={false} />
                                                                                                                                        <div className="fl-column compact shrink">
                                                                                                                                            <div className="label">*C*</div>
                                                                                                                                            <ToggleCheck id={"condition" + k + "_customisationCondition"} currentState={condition.customisationCondition || false} toggleFunc={(e, id) => changeObjectValue(condition, 'customisationCondition', !(condition.customisationCondition || false))} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="del-icon" onClick={(e) => removeDisplayCondition(e, i, j, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    )}
                                                                                                                    <button className="standard-button tight" onClick={(e) => addDisplayCondition(e, i, j)}>Add Display Condition</button>
                                                                                                                </div>
                                                                                                            </CollapsibleHeadedSection>
                                                                                                        </div>

                                                                                                        <div className={`seeder-content-box full-width outline fl-start`}>
                                                                                                            <CollapsibleHeadedSection title={`Disabled Conditions (${variable.disabledCondition ? variable.disabledCondition.length : '0'})`} open={false}>
                                                                                                                <div className="fl-column">
                                                                                                                    {variable.disabledCondition && variable.disabledCondition.map(
                                                                                                                        (condition, k) => {
                                                                                                                            return (
                                                                                                                                <div key={"condition" + k} className={`seeder-content-box full-width`}>
                                                                                                                                    <div className="fl-row equal">
                                                                                                                                        <FormField id={"condition" + k + "_var"} className="eval-var-width-live" label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue(condition, 'var', value)} showError={false} />
                                                                                                                                        <div className="eval-width">
                                                                                                                                            <FormDropDown id={"condition" + k + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue(condition, 'evalCondition', item.value)} showErrors={false} />
                                                                                                                                        </div>
                                                                                                                                        <FormField id={"condition" + k + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue(condition, 'val', value)} showError={false} />
                                                                                                                                        <div className="fl-column compact shrink">
                                                                                                                                            <div className="label">*C*</div>
                                                                                                                                            <ToggleCheck id={"condition" + k + "_customisationCondition"} currentState={condition.customisationCondition || false} toggleFunc={(e, id) => changeObjectValue(condition, 'customisationCondition', !(condition.customisationCondition || false))} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="del-icon" onClick={(e) => removeDisabledCondition(e, i, j, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    )}
                                                                                                                    <button className="standard-button tight" onClick={(e) => addDisabledCondition(e, i, j)}>Add Disabled Condition</button>
                                                                                                                </div>
                                                                                                            </CollapsibleHeadedSection>
                                                                                                        </div>

                                                                                                        <div className="move-icons">
                                                                                                            <div className='move-icon' onClick={(e) => copyVariable(e, i, j)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                            <div className='move-icon' onClick={(e) => moveVariable(e, i, j, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                            <div className='move-icon' onClick={(e) => moveVariable(e, i, j, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                        </div>
                                                                                                        <div className='del-icon' onClick={(e) => removeVariable(e, i, j)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        )}

                                                                                        <div className="fl-row shrink fl-start">
                                                                                            <div className="shrink fl-start">
                                                                                                <button className="standard-button tight" onClick={(e) => addVariable(e, i)}>Add Variable</button>
                                                                                            </div>
                                                                                            <div className="shrink fl-start">
                                                                                                <button className="standard-button tight" onClick={(e) => pasteVariable(e, i)}>Paste Variable</button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        </CollapsibleHeadedSection>
                                                                        </div>

                                                                        {/* <div className="fl-row"></div> */}

                                                                        <div className="seeder-content-box full-width outline">
                                                                        <CollapsibleHeadedSection title={`Command Buttons (${varGroup.commandButtons ? varGroup.commandButtons.length : '0'})`} open={false}>
                                                                            <>
                                                                                <div className="fl-column">

                                                                                    <div className="fl-row"></div>

                                                                                    <div className="fl-row fl-wrap">

                                                                                        {varGroup.commandButtons && varGroup.commandButtons.map(
                                                                                            (button, j) => {
                                                                                                button.__ed__commandLinkage = getCommandLinkage(button.command);
                                                                                                if (typeof button.relatedButton === 'undefined') {
                                                                                                    button.relatedButton = {label: '', command: ''};
                                                                                                }
                                                                                                if (typeof button.addRelatedButton === 'undefined') {
                                                                                                    button.addRelatedButton = false;
                                                                                                }
                                                                                                button.__ed__relB_commandLinkage = getCommandLinkage(button.relatedButton.command || '');
                                                                                                return (
                                                                                                    <div key={"button" + j} className={`seeder-content-box third-width fl-start`}>
                                                                                                        <FormField id={"button" + j + "_label"} label="Label" placeholder="Enter Label" value={button.label || ''} setFunc={(value) => changeObjectValue(button, 'label', value)} showError={false} />
                                                                                                        <FormDropDown id={"button" + j + "_colour"} label="Colour" items={BUTTON_COLOUR_OPTIONS} currentIndex={getSelectedIndex(BUTTON_COLOUR_OPTIONS, button, 'colour')} selectFunc={(e, id, item) => setValFromDropDown(item, button, 'colour')} showErrors={false} />
                                                                                                        <FormField id={"button" + j + "_command"} label="Command" labelNote={"Buttons can now also call pre-defined functions, look for BUTTON_FUNCTIONS in the src (it's in 3 places)"} placeholder="Enter Command" value={button.__ed__commandLinkage.command || ''} setFunc={(value) => changeObjectValue(button, 'command', value)} showError={false} />
                                                                                                        <FormDropDown id={"button" + j + "_commandLinkage"} label="Command Linkage" labelNote="Link your button to enable auto buttons (see Audio Disruptor)." items={getCommandsDropdownData()} currentIndex={findCommandDropDownIndex(button.command)} 
                                                                                                            selectFunc={
                                                                                                                (e, id, item) => {
                                                                                                                    if (item.value === -1) {
                                                                                                                        button.command = button.__ed__commandLinkage.command;
                                                                                                                    } else {
                                                                                                                        button.command = item.value;
                                                                                                                    }
                                                                                                                    if (!showLiveEditVarFamilyPopup) {
                                                                                                                        setFeatureData({...featureData_ref.current});
                                                                                                                    }
                                                                                                                }
                                                                                                            } 
                                                                                                            showErrors={false} 
                                                                                                        />
                                                                                                        <div className="fl-row">
                                                                                                            <div className="label">Add Secondary Related Button?</div>
                                                                                                            <div className="grow"></div>
                                                                                                            <div className="shrink">
                                                                                                                <Toggle id={"button" + j + "_addRelatedButton"} currentState={button.addRelatedButton || false} toggleFunc={(e, id) => changeObjectValue(button, 'addRelatedButton', !(button.addRelatedButton || false))} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        {button.addRelatedButton &&
                                                                                                            <div className={`seeder-content-box full-width outline fl-start`}>
                                                                                                                <div className="label">Secondary Button Settings:</div>
                                                                                                                <FormField id={"button" + j + "_relB_label"} label="Label" placeholder="Enter Label" value={button.relatedButton.label || ''} setFunc={(value) => changeObjectValue(button.relatedButton, 'label', value)} showError={false} />
                                                                                                                <FormDropDown id={"button" + j + "_relB_colour"} label="Colour" items={BUTTON_COLOUR_OPTIONS} currentIndex={getSelectedIndex(BUTTON_COLOUR_OPTIONS, button.relatedButton, 'colour')} selectFunc={(e, id, item) => setValFromDropDown(item, button.relatedButton, 'colour')} showErrors={false} />
                                                                                                                <FormField id={"button" + j + "_relB_command"} label="Command" placeholder="Enter Command" value={button.__ed__relB_commandLinkage.command || ''} setFunc={(value) => changeObjectValue(button.relatedButton, 'command', value)} showError={false} />
                                                                                                                <FormDropDown id={"button" + j + "_relB_commandLinkage"} label="Command Linkage" labelNote="Link your button to enable auto buttons (see Audio Disruptor)." items={getCommandsDropdownData()} currentIndex={findCommandDropDownIndex(button.relatedButton.command)} 
                                                                                                                    selectFunc={
                                                                                                                        (e, id, item) => {
                                                                                                                            if (item.value === -1) {
                                                                                                                                button.relatedButton.command = button.__ed__relB_commandLinkage.command;
                                                                                                                            } else {
                                                                                                                                button.relatedButton.command = item.value;
                                                                                                                            }
                                                                                                                            if (!showLiveEditVarFamilyPopup) {
                                                                                                                                setFeatureData({...featureData_ref.current});
                                                                                                                            }
                                                                                                                        }
                                                                                                                    } 
                                                                                                                    showErrors={false} 
                                                                                                                />
                                                                                                            </div>
                                                                                                        }
                                                                                                        
                                                                                                        <div className={`seeder-content-box full-width outline fl-start`}>
                                                                                                            <CollapsibleHeadedSection title="Configure" open={false}>
                                                                                                                <>
                                                                                                                <div className="fl-row equal">
                                                                                                                    <FormField id={"button" + j + "_width"} label="Width" type="number" placeholder="Enter Width" value={button.width} setFunc={(value) => changeObjectValue(button, 'width', value)} showError={false} />
                                                                                                                    <div className="fl-row force-width-options">
                                                                                                                        <FormDropDown id={"option" + j + "_buttonWidthOptions"} className="grow" label="Width Options" items={BUTTON_WIDTH_OPTIONS} currentIndex={getSelectedIndex(BUTTON_WIDTH_OPTIONS, button, 'width')} selectFunc={(e, id, item) => setValFromDropDown(item, button, 'width')} showErrors={false} />
                                                                                                                    </div>
                                                                                                                    <FormField id={"button" + j + "_index"} label="Index" type="number" placeholder="Enter Index" value={button.index} setFunc={(value) => changeObjectValue(button, 'index', value)} showError={false} />
                                                                                                                </div>

                                                                                                                <div className="fl-row">
                                                                                                                    <div className="label">Quick Access Control</div>
                                                                                                                    <div className="grow"></div>
                                                                                                                    <div className="shrink">
                                                                                                                        <Toggle id={"button" + j + "_mainControl"} currentState={button.mainControl || false} toggleFunc={(e, id) => changeObjectValue(button, 'mainControl', !(button.mainControl || false))} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="fl-row">
                                                                                                                    <div className="label">Use Icon</div>
                                                                                                                    <div className="grow"></div>
                                                                                                                    <div className="shrink">
                                                                                                                        <Toggle id={"button" + j + "_useIcon"} currentState={button.useIcon || false} toggleFunc={(e, id) => changeObjectValue(button, 'useIcon', !(button.useIcon || false))} />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {(button.mainControl || button.useIcon) &&
                                                                                                                    <div className="fl-row equal">
                                                                                                                        <div className="fl-column grow">
                                                                                                                            <FormField id={"button" + j + "_icon"} label="Icon" placeholder="Enter Icon" value={button.icon || ''} setFunc={(value) => changeObjectValue(button, 'icon', value)} showError={false} />
                                                                                                                        </div>
                                                                                                                        <div className="fl-column grow">
                                                                                                                            <FormDropDown id={"button" + j + "_iconString"} label="Choose" items={ICONS_OPTIONS} currentIndex={getSelectedIndex(ICONS_OPTIONS, button, 'icon')} selectFunc={(e, id, item) => item.index !== 0 && setValFromDropDown(item, button, 'icon')} showErrors={false} /> 
                                                                                                                        </div>
                                                                                                                        {button.icon && button.icon !== '' &&
                                                                                                                            <div className="fl-column shrink icon-preview">
                                                                                                                                {IconJsxer.GetIcon(button.icon, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                                                                                            </div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                }
                                                                                                                </>
                                                                                                            </CollapsibleHeadedSection>

                                                                                                        </div>
                                                                                                        <div className={`seeder-content-box full-width outline fl-start`}>

                                                                                                            <CollapsibleHeadedSection title={`Display Conditions (${button.displayCondition ? button.displayCondition.length : '0'})`} open={false}>
                                                                                                                <div className="fl-column">
                                                                                                                    {button.displayCondition && button.displayCondition.map(
                                                                                                                        (condition, k) => {
                                                                                                                            return (
                                                                                                                                <div key={"condition" + k} className={`seeder-content-box full-width`}>
                                                                                                                                    <div className="fl-row equal">
                                                                                                                                        <FormField id={"condition" + k + "_var"} className="eval-var-width-live" label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue(condition, 'var', value)} showError={false} />
                                                                                                                                        <div className="eval-width">
                                                                                                                                            <FormDropDown id={"condition" + k + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue(condition, 'evalCondition', item.value)} showErrors={false} />
                                                                                                                                        </div>
                                                                                                                                        <FormField id={"condition" + k + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue(condition, 'val', value)} showError={false} />
                                                                                                                                        <div className="fl-column compact shrink">
                                                                                                                                            <div className="label">*C*</div>
                                                                                                                                            <ToggleCheck id={"condition" + k + "_customisationCondition"} currentState={condition.customisationCondition || false} toggleFunc={(e, id) => changeObjectValue(condition, 'customisationCondition', !(condition.customisationCondition || false))} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="del-icon" onClick={(e) => removeButtonDisplayCondition(e, button, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                </div>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    )}
                                                                                                                    <button className="standard-button tight" onClick={(e) => addButtonDisplayCondition(e, button)}>Add Display Condition</button>
                                                                                                                </div>
                                                                                                            </CollapsibleHeadedSection>

                                                                                                        </div>

<div className={`seeder-content-box full-width outline fl-start`}>

<CollapsibleHeadedSection title={`Disabled Conditions (${button.disabledCondition ? button.disabledCondition.length : '0'})`} open={false}>
    <div className="fl-column">
        {button.disabledCondition && button.disabledCondition.map(
            (condition, k) => {
                return (
                    <div key={"condition" + k} className={`seeder-content-box full-width`}>
                        <div className="fl-row equal">
                            <FormField id={"condition" + k + "_var"} className="eval-var-width-live" label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue(condition, 'var', value)} showError={false} />
                            <div className="eval-width">
                                <FormDropDown id={"condition" + k + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue(condition, 'evalCondition', item.value)} showErrors={false} />
                            </div>
                            <FormField id={"condition" + k + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue(condition, 'val', value)} showError={false} />
                            <div className="fl-column compact shrink">
                                <div className="label">*C*</div>
                                <ToggleCheck id={"condition" + k + "_customisationCondition"} currentState={condition.customisationCondition || false} toggleFunc={(e, id) => changeObjectValue(condition, 'customisationCondition', !(condition.customisationCondition || false))} />
                            </div>
                        </div>
                        <div className="del-icon" onClick={(e) => removeButtonDisabledCondition(e, button, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                    </div>
                )
            }
        )}
        <button className="standard-button tight" onClick={(e) => addButtonDisabledCondition(e, button)}>Add Disabled Condition</button>
    </div>
</CollapsibleHeadedSection>

</div>

                                                                                                        <div className='del-icon' onClick={(e) => removeCommandButton(e, i, j)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        )}

                                                                                        <div className="shrink fl-start">
                                                                                            <button className="standard-button tight" onClick={(e) => addCommandButton(e, i)}>Add Command Button</button>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        </CollapsibleHeadedSection>
                                                                        </div>

                                                                        <div className="seeder-content-box full-width outline">
                                                                            <CollapsibleHeadedSection title={`Display Conditions (${varGroup.displayCondition ? varGroup.displayCondition.length : '0'})`} open={false}>
                                                                                <div className="fl-row fl-wrap">
                                                                                    {varGroup.displayCondition && varGroup.displayCondition.map(
                                                                                        (condition, k) => {
                                                                                            return (
                                                                                                <div key={"condition" + k} className={`seeder-content-box half-width`}>
                                                                                                    <div className="fl-row equal">
                                                                                                        <FormField id={"condition" + k + "_var"} className="eval-var-width-live" label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue(condition, 'var', value)} showError={false} />
                                                                                                        <div className="eval-width">
                                                                                                            <FormDropDown id={"condition" + k + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue(condition, 'evalCondition', item.value)} showErrors={false} />
                                                                                                        </div>
                                                                                                        <FormField id={"condition" + k + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue(condition, 'val', value)} showError={false} />
                                                                                                        <div className="fl-column compact shrink">
                                                                                                            <div className="label">*C*</div>
                                                                                                            <ToggleCheck id={"condition" + k + "_customisationCondition"} currentState={condition.customisationCondition || false} toggleFunc={(e, id) => changeObjectValue(condition, 'customisationCondition', !(condition.customisationCondition || false))} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="del-icon" onClick={(e) => removeLiveGroupDisplayCondition(e, i, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    )}
                                                                                    <button className="standard-button tight quarter-width" onClick={(e) => addLiveGroupDisplayCondition(e, i)}>Add Group Display Condition</button>
                                                                                </div>
                                                                            </CollapsibleHeadedSection>
                                                                        </div>

                                                                        <div className="move-icons">
                                                                            <div className="move-icon" onClick={(e) => copyVarGroup(e, i)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                            <div className="move-icon" onClick={(e) => moveVarGroup(e, i, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                            <div className="move-icon" onClick={(e) => moveVarGroup(e, i, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                        </div>
                                                                        <div className="del-icon" onClick={(e) => removeVarGroup(e, i)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                    </div>
                                                            )
                                                        }
                                                    )}

                                                </div>

                                                <div className="fl-row shrink">
                                                    <div className="shrink fl-start">
                                                        <button className="standard-button tight" onClick={addVarGroup}>Add Control Group</button>
                                                    </div>
                                                    <div className="shrink fl-start">
                                                        <button className="standard-button tight" onClick={pasteVarGroup}>Paste Control Group</button>
                                                    </div>
                                                </div>

                                        </div>
                                        <div className="seeder-content-box quarter-width fl-start">
                                            <FeatureControls
                                                feature={{feature: featureData}}
                                                streamVars={generateLiveControlsValsDataForPreview()}
                                                customisationVars={generateCustomisationPreviewData(false)}
                                                ignoreCustomisationDisplayConditions={true}
                                                featureId={'preview'}
                                                reportFeatureVarsChangedFunc={() => {}}
                                                reportCustomisationVarsChanged={() => {}}
                                                sendFeatureCommandFunc={() => {}}
                                                showTooltipFunc={() => {}}
                                                hideTooltipFunc={() => {}}
                                                label={"Controls Preview"}
                                                startCollapsed={false}
                                                previewControls={true}
                                                quizData={{}}
                                            />
                                        </div>
                                        </div>
                                        }
                                        </>
                                    </CollapsibleHeadedSection>
                                </div>

                                <div className="fl-row"></div>

                                <div className={`co-tab-content-area fl-equal`}>
                                    <CollapsibleHeadedSection title={`Customisations (${featureData.customisation && featureData.customisation.length ? featureData.customisation.length : '0'})`} open={false} 
                                        id="customisations_collapsible" onCollapseToggle={onCollapseToggle}
                                        extraHeaderContentRight={
                                            <div className="fl-row" style={{paddingLeft: "10px", paddingRight: "10px", cursor: "default"}} onClick={(e) => e.stopPropagation()}>
                                                <div className="shrink fl-start main-top-options">
                                                    <div onClick={resetAllIntialSettings}>Reset All Initial Settings</div>
                                                </div>
                                                <div className="shrink fl-start main-top-options"> 
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.preview, IconJsxer.ICON_STYLES.roundPanelButton, 'icon', openPreview)}
                                                </div>
                                            </div>
                                        }>
                                        <>
                                        {expandedCollapsibles.indexOf('customisations_collapsible') !== -1 &&

                                        <div className="fl-column">

                                            <div className="fl-row"></div>

                                            <div className="fl-row fl-wrap">

                                                {featureData.customisation && featureData.customisation.map(
                                                    
                                                    (customisation, i) => {
                                                        return (
                                                            <div key={"tab" + i} className={`seeder-content-box width-99 fl-start`}>
                                                                <div className="fl-row">
                                                                    <div className="grow">
                                                                        <FormField id={"tab" + i + "_tabTitle"} label="Tab Title" placeholder="Enter Tab Title" value={customisation.tabTitle || ''} setFunc={(value) => changeObjectValue(customisation, 'tabTitle', value)} showError={false} />
                                                                    </div>
                                                                    <div className="grow">
                                                                        <FormField id={"tab" + i + "_tabKey"} label="Tab Key" placeholder="Enter Tab Key" value={customisation.tabKey || ''} setFunc={(value) => changeObjectValue(customisation, 'tabKey', value)} showError={false} />
                                                                    </div>
                                                                    <div className="fl-column no-gap shrink">
                                                                        <div className="label no-wrap">Themeable</div>
                                                                        <div className="fl-row no-gap">
                                                                            <div className="grow"></div>
                                                                            <div className="shrink">
                                                                                <Toggle id={"tab" + i + "_themeable"} currentState={customisation.themeable || false} toggleFunc={(e, id) => changeObjectValue(customisation, 'themeable', !(customisation.themeable || false))} />   
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div className="fl-row"></div> */}

                                                                <div className="seeder-content-box full-width outline">
                                                                <CollapsibleHeadedSection title={`Groups (${customisation.options ? customisation.options.length : '0'})`} open={false} id={"tab_" + i + "_collapsible"} onCollapseToggle={onCollapseToggle}>
                                                                    <>
                                                                    {expandedCollapsibles.indexOf("tab_" + i + "_collapsible") !== -1 &&
                                                                    <div className="fl-column">
                                                                        {customisation.options && customisation.options.map(
                                                                            (option, j) => {
                                                                                return (
                                                                                    <div key={"option" + j} className={`seeder-content-box full-width${option.variables && option.variables.length ? '' : ' cosmetic-var'}`}>
                                                                                        <div className="fl-row equal">
                                                                                            <FormField id={"option" + j + "_groupTitle"} className="quarter-width" label="Group Title" placeholder="Enter Group Title" value={option.groupTitle || ''} setFunc={(value) => changeObjectValue(option, 'groupTitle', value)} showError={false} />
                                                                                            <FormField id={"option" + j + "_varKey"} className="quarter-width" label={`Var Key (${customisation.tabKey}.${option.varKey})`} placeholder="Enter Var Key" value={option.varKey || ''} setFunc={(value) => changeObjectValue(option, 'varKey', value)} showError={false} />
                                                                                            
                                                                                            <FormField id={"option" + j + "_forceWidth"} label="Force Width" placeholder="Enter Force Width" value={option.forceWidth} setFunc={(value) => changeObjectValue(option, 'forceWidth', value)} showError={false} />
                                                                                            <div className="fl-row force-width-options">
                                                                                                <FormDropDown id={"option" + j + "_forceWidthOptions"} className="grow" label="Force Width Options" items={FORCE_WIDTH_OPTIONS} currentIndex={getSelectedIndex(FORCE_WIDTH_OPTIONS, option, 'forceWidth')} selectFunc={(e, id, item) => setValFromDropDown(item, option, 'forceWidth')} showErrors={false} />
                                                                                            </div>
                                                                                            <FormField id={"option" + j + "_padEnd"} label="Pad End" placeholder="Enter Pad End" value={option.padEnd} setFunc={(value) => changeObjectValue(option, 'padEnd', value)} showError={false} />
                                                                                            <div className="fl-row">
                                                                                                <div className="label no-wrap">Advanced Mode</div>
                                                                                                <div className="grow"></div>
                                                                                                <div className="shrink">
                                                                                                    <Toggle id={"option" + j + "_advancedMode"} currentState={option.advancedMode || false} toggleFunc={(e, id) => changeObjectValue(option, 'advancedMode', !(option.advancedMode || false))} />   
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fl-row">
                                                                                                <div className="label no-wrap">Collapsible</div>
                                                                                                <div className="grow"></div>
                                                                                                <div className="shrink">
                                                                                                    <Toggle id={"option" + j + "_advancedMode"} currentState={option.collapsible || false} toggleFunc={(e, id) => changeObjectValue(option, 'collapsible', !(option.collapsible || false))} />   
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="fl-row">
                                                                                                <div className="label no-wrap">Start Expanded</div>
                                                                                                <div className="grow"></div>
                                                                                                <div className="shrink">
                                                                                                    <Toggle id={"option" + j + "_advancedMode"} currentState={option.startExpanded || false} toggleFunc={(e, id) => changeObjectValue(option, 'startExpanded', !(option.startExpanded || false))} />   
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        

                                                                                        {/* <div className="fl-row"></div> */}

                                                                                        <div className="seeder-content-box full-width outline">
                                                                                        <CollapsibleHeadedSection title={`Variable Families (${option.variables ? option.variables.length : '0'})`} open={false} id={"group_" + j +  "_varfams_collapsible"} onCollapseToggle={onCollapseToggle}>
                                                                                            <>
                                                                                            {expandedCollapsibles.indexOf("group_" + j +  "_varfams_collapsible") !== -1 &&
                                                                                            <div className="fl-row fl-wrap">

                                                                                                {option.variables && option.variables.map(
                                                                                                    (variable, k) => {

                                                                                                        // console.log('--- PROCESS SCHEMA FILES ---');
                                                                                                        for (let p in variable.valSchema) {
                                                                                                            convertFilenameToObject(variable.valSchema, p, variable.valSchema[p], 'default');
                                                                                                        }
                                                                                                        // console.log('--- PROCESS VALS FILES ---');
                                                                                                        for (let i = 0; i < variable.vals.length; i++) {
                                                                                                            for (let p in variable.vals[i]) {
                                                                                                                convertFilenameToObject(variable.valSchema, p, variable.vals[i], p);
                                                                                                            }
                                                                                                        }
                                                                                                        // console.log('--- PROCESS PRESETS FILES ---');
                                                                                                        if (variable.presets && variable.presets.length > 0) {
                                                                                                            for (let i = 0; i < variable.presets.length; i++) {
                                                                                                                for (let j = 0; j < variable.presets[i].vals.length; j++) {
                                                                                                                    for (let p in variable.presets[i].vals[j]) {
                                                                                                                        convertFilenameToObject(variable.valSchema, p, variable.presets[i].vals[j], p);
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }

                                                                                                        // console.log('Processed filenames: ', variable);

                                                                                                        return (
                                                                                                            <div key={"variable" + k} className={`seeder-content-box half-width fl-start`}>
                                                                                                                <div className="fl-row grow small-text tight">{`${customisation.tabKey}.${option.varKey}.${variable.varName}`}</div>
                                                                                                                <div className="fl-row equal">
                                                                                                                    <FormField id={"variable" + k + "_label"} label="Label" placeholder="Enter Label" value={variable.label || ''} setFunc={(value) => changeObjectValue(variable, 'label', value)} showError={false} />
                                                                                                                    <FormField id={"variable" + k + "_varName"} label="Family Key" placeholder="Enter Family Key" value={variable.varName || ''} setFunc={(value) => changeObjectValue(variable, 'varName', value)} showError={false} />
                                                                                                                    <div className="fl-row">
                                                                                                                        <div className="label no-wrap">Advanced Mode</div>
                                                                                                                        <div className="grow"></div>
                                                                                                                        <div className="shrink">
                                                                                                                            <Toggle id={"option" + j + "_advancedMode"} currentState={variable.advancedMode || false} toggleFunc={(e, id) => changeObjectValue(variable, 'advancedMode', !(variable.advancedMode || false))} />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                {/* <div className="fl-row"></div> */}

                                                                                                                
                                                                                                                <CollapsibleHeadedSection title={`More Settings`} open={false}>
                                                                                                                    <div className="fl-column full-width">
                                                                                                                        <div className="fl-row">
                                                                                                                            <div className="fl-row">
                                                                                                                                <div className="label no-wrap">Force To New Row</div>
                                                                                                                                <div className="grow"></div>
                                                                                                                                <div className="shrink">
                                                                                                                                    <Toggle id={"option" + j + "_displayOnNewRow"} currentState={variable.displayOnNewRow || false} toggleFunc={(e, id) => changeObjectValue(variable, 'displayOnNewRow', !(variable.displayOnNewRow || false))} />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="fl-row grow">
                                                                                                                            </div>
                                                                                                                            <div className="fl-row">
                                                                                                                                <FormDropDown className="grow" id={"variable" + k + "_stylePresets"} label="Style Presets" showLabel={false} items={getVarBoxStylePresetsDropDownData()} currentIndex={selectedVarBoxStyleIndex} selectFunc={selectVarBoxStyle} showErrors={false} />
                                                                                                                                <div className="shrink">
                                                                                                                                    <button className="standard-button tight" onClick={() => applyCustomisationVariableBoxSettings(variable)}>Apply</button>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="fl-row equal">
                                                                                                                            <div className="grow">
                                                                                                                                <TextArea id={"variable" + k + "_description"} rows={3} label="Description" placeholder="Enter Description" value={variable.description || ''} setFunc={(value) => changeObjectValue(variable, 'description', value)} showError={false} />
                                                                                                                            </div>
                                                                                                                            <div className="fl-column">
                                                                                                                                <div className="fl-row">
                                                                                                                                    <div className="label no-wrap">Add Box</div>
                                                                                                                                    <div className="grow"></div>
                                                                                                                                    <div className="shrink">
                                                                                                                                        <Toggle id={"option" + j + "_addBox"} currentState={variable.addBox || false} toggleFunc={(e, id) => changeObjectValue(variable, 'addBox', !(variable.addBox || false))} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="fl-row">
                                                                                                                                    <div className="label no-wrap">Outline Box</div>
                                                                                                                                    <div className="grow"></div>
                                                                                                                                    <div className="shrink">
                                                                                                                                        <Toggle id={"option" + j + "_addOutlineBox"} currentState={variable.addOutlineBox || false} toggleFunc={(e, id) => changeObjectValue(variable, 'addOutlineBox', !(variable.addOutlineBox || false))} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="fl-row">
                                                                                                                                <ColorPicker
                                                                                                                                    label="Box Colour"
                                                                                                                                    id="box_colour"
                                                                                                                                    setFunc={(newValue) => changeObjectValue(variable, 'boxColour', newValue)}
                                                                                                                                    value={variable.boxColour || '#EFF0F6'}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="fl-row equal">
                                                                                                                            <div className="fl-column grow">
                                                                                                                                <div className="fl-row grow">
                                                                                                                                    <div className="fl-column">
                                                                                                                                        <div className="fl-row">
                                                                                                                                            <div className="label no-wrap">Prevent Delete</div>
                                                                                                                                            <div className="grow"></div>
                                                                                                                                            <div className="shrink">
                                                                                                                                                <Toggle id={"option" + j + "_preventDelete"} currentState={variable.preventDelete || false} toggleFunc={(e, id) => changeObjectValue(variable, 'preventDelete', !(variable.preventDelete || false))} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div className="fl-row">
                                                                                                                                            <div className="label no-wrap">Allow Multiple</div>
                                                                                                                                            <div className="grow"></div>
                                                                                                                                            <div className="shrink">
                                                                                                                                                <Toggle id={"option" + j + "_allowMultiple"} currentState={variable.allowMultiple || false} toggleFunc={(e, id) => changeObjectValue(variable, 'allowMultiple', !(variable.allowMultiple || false))} />
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <FormField id={"variable" + k + "_maxMultiples"} type="number" label="Max Multiples" placeholder="Enter Max Multiples" value={variable.maxMultiples} setFunc={(value) => changeObjectValue(variable, 'maxMultiples', value)} showError={false} />
                                                                                                                                    {featureData.isChainable &&
                                                                                                                                        <FormField id={"variable" + k + "_chainedMaxMultiples"} type="number" label="Chained Max" placeholder="Enter Chained Max Multiples" value={variable.chainedMaxMultiples} setFunc={(value) => changeObjectValue(variable, 'chainedMaxMultiples', value)} showError={false} />
                                                                                                                                    }
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className="fl-row">
                                                                                                                                <div className="fl-column">
                                                                                                                                    <div className="fl-row">
                                                                                                                                        <div className="label no-wrap">Item Box</div>
                                                                                                                                        <div className="grow"></div>
                                                                                                                                        <div className="shrink">
                                                                                                                                            <Toggle id={"option" + j + "_addVarBox"} currentState={variable.addVarBox || false} toggleFunc={(e, id) => changeObjectValue(variable, 'addVarBox', !(variable.addVarBox || false))} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    <div className="fl-row">
                                                                                                                                        <div className="label no-wrap">Outline Box</div>
                                                                                                                                        <div className="grow"></div>
                                                                                                                                        <div className="shrink">
                                                                                                                                            <Toggle id={"option" + j + "_addVarOutlineBox"} currentState={variable.addVarOutlineBox || false} toggleFunc={(e, id) => changeObjectValue(variable, 'addVarOutlineBox', !(variable.addVarOutlineBox || false))} />
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div className="fl-row">
                                                                                                                                    <ColorPicker
                                                                                                                                        label="Item Box Colour"
                                                                                                                                        id="box_colour"
                                                                                                                                        setFunc={(newValue) => changeObjectValue(variable, 'varBoxColour', newValue)}
                                                                                                                                        value={variable.varBoxColour || '#EFF0F6'}
                                                                                                                                    />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="fl-row">
                                                                                                                            <div className="fl-row equal half-width">
                                                                                                                                <FormField id={"option" + j + "_forceWidth"} label={`${variable.allowMultiple ? 'Item' : 'Force'} Width`} placeholder={`Enter ${variable.allowMultiple ? 'Item' : 'Force'} Width`} value={variable.forceWidth} setFunc={(value) => changeObjectValue(variable, 'forceWidth', value)} showError={false} />
                                                                                                                                <div className="fl-row force-width-options">
                                                                                                                                    <FormDropDown id={"option" + j + "_forceWidthOptions"} className="grow" label={`${variable.allowMultiple ? 'Item' : 'Force'} Width Options`} items={FORCE_WIDTH_OPTIONS} currentIndex={getSelectedIndex(FORCE_WIDTH_OPTIONS, variable, 'forceWidth')} selectFunc={(e, id, item) => setValFromDropDown(item, variable, 'forceWidth')} showErrors={false} />
                                                                                                                                </div>
                                                                                                                                <FormField id={"option" + j + "_padEnd"} label="Pad End" placeholder="Enter Pad End" value={variable.padEnd} setFunc={(value) => changeObjectValue(variable, 'padEnd', value)} showError={false} />
                                                                                                                            </div>
                                                                                                                            {variable.allowMultiple &&
                                                                                                                                <div className="fl-row equal half-width">
                                                                                                                                    <FormField id={"option" + j + "_groupWidth"} label="Group Width" placeholder="Enter Group Width" value={variable.groupWidth} setFunc={(value) => changeObjectValue(variable, 'groupWidth', value)} showError={false} />
                                                                                                                                    <div className="fl-row force-width-options">
                                                                                                                                        <FormDropDown id={"option" + j + "_groupWidthOptions"} className="grow" label="Group Width Options" items={FORCE_WIDTH_OPTIONS} currentIndex={getSelectedIndex(FORCE_WIDTH_OPTIONS, variable, 'groupWidth')} selectFunc={(e, id, item) => setValFromDropDown(item, variable, 'groupWidth')} showErrors={false} />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </CollapsibleHeadedSection>
                                                                                                                

                                                                                                                {/* <div className="fl-row"></div> */}

                                                                                                                <div className="seeder-content-box full-width outline">
                                                                                                                            <CollapsibleHeadedSection title={`Variables (${variable.valSchema ? Object.keys(variable.valSchema).length : '0'})`} open={false} id={"group_" + j +  "_varfam_" + k + "_vars_collapsible"} onCollapseToggle={onCollapseToggle}>
                                                                                                                                <>
                                                                                                                                {expandedCollapsibles.indexOf("group_" + j +  "_varfam_" + k + "_vars_collapsible") !== -1 &&
                                                                                                                                <div className="fl-row">
                                                                                                                                    <div className="fl-column half-width fl-start customisation-controls-holder">
                                                                                                                                        <div className="control-group">
                                                                                                                                            <CustomisationControls
                                                                                                                                                renderSingleControlBlock={true}
                                                                                                                                                isPreview={true}
                                                                                                                                                vals={[getDefaultValsObject(variable.valSchema)]}
                                                                                                                                                displaySettings={variable}
                                                                                                                                                controlsData={featureData.customisation}
                                                                                                                                                rootVals={null}
                                                                                                                                                objRef={null}
                                                                                                                                                varBoxWidthStyle={null}
                                                                                                                                                updateFunction={
                                                                                                                                                    (newVal, valsArray, varSchema, index, valKey) => {
                                                                                                                                                        // console.log('Val changed: ', newVal);
                                                                                                                                                        if (CUSTOMISED_URL_MAP.hasOwnProperty(variable.valSchema[valKey].type.toLowerCase())) {
                                                                                                                                                            // File!
                                                                                                                                                            const fileNameSplit = newVal.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                                                                                                                                                            // fileNameSplit[0] += '__' + Date.now();
                                                                                                                                                            variable.valSchema[valKey].default.filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                                                                                                                                                            newVal.uniqueFilename = fileNameSplit.join('.');
                                                                                                                                                            variable.valSchema[valKey].default.newFile = newVal;
                                                                                                                                                            variable.valSchema[valKey].default.srcUrl = newVal ? URL.createObjectURL(newVal) : null;
                                                                                                                                                            variable.valSchema[valKey].default.isDefault = true;
                                                                                                                                                            variable.valSchema[valKey].default.updated = true;
                                                                                                                                                            // valsArray[index].isDefault = false;
                                                                                                                                                            // valsArray[index].updated = true;
                                                                                                                                                            // console.log('File handled: ', variable.valSchema[valKey].default);
                                                                                                                                                        } else {
                                                                                                                                                            // Everything else!
                                                                                                                                                            // variable.valSchema[valKey].default = newVal;
                                                                                                                                                            changeObjectValue(variable.valSchema[valKey], 'default', newVal);
                                                                                                                                                        }
                                                                                                                                                        if (!showLiveEditVarFamilyPopup) {
                                                                                                                                                            setFeatureData({...featureData_ref.current});
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                                highlightKey={schemaHighlighKey}
                                                                                                                                                highlightNoValSet={true}
                                                                                                                                            />
                                                                                                                                        </div>
                                                                                                                                        <div className="grow"></div>
                                                                                                                                    </div>
                                                                                                                                    <div className="fl-row half-width fl-start">
                                                                                                                                        <div className="seeder-content-box full-width">
                                                                                                                                            {Object.keys(variable.valSchema).map(
                                                                                                                                                (schemaKey, schemaIndex) => {
                                                                                                                                                    // console.log('Render setup: ', schemaKey, schemaIndex, variable.valSchema[schemaKey]);
                                                                                                                                                    const controlData = getControlTypeData(getCustomisationControlTypes(), variable.valSchema[schemaKey].type);
                                                                                                                                                    // console.log('Control Data: ', controlData);
                                                                                                                                                    if (typeof variable.valSchema[schemaKey].__ed__schemaKey === 'undefined') {
                                                                                                                                                        variable.valSchema[schemaKey].__ed__schemaKey = schemaKey;
                                                                                                                                                    }
                                                                                                                                                    if (!variable.valSchema[schemaKey].valOptions || variable.valSchema[schemaKey].valOptions.length === 0) {
                                                                                                                                                        variable.valSchema[schemaKey].valOptions = [{label: 'Option 1', val: 'option1'}];
                                                                                                                                                    }
                                                                                                                                                    if (typeof variable.valSchema[schemaKey].default === 'undefined') {
                                                                                                                                                        variable.valSchema[schemaKey].default = '';
                                                                                                                                                    }
                                                                                                                                                    if (controlData && controlData.type === FEATURE_CONTROLS_TYPES.Font.type) {
                                                                                                                                                        if (!variable.valSchema[schemaKey].default) {
                                                                                                                                                            variable.valSchema[schemaKey].default = 'Roboto';
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    for (let v = 0; v < variable.vals.length; v++) {
                                                                                                                                                        if (typeof variable.vals[v][schemaKey] === 'undefined') {
                                                                                                                                                            variable.vals[v][schemaKey] = '';
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    return (
                                                                                                                                                        <div key={"valSchema_" + schemaIndex + "_" + schemaKey} className={`seeder-content-box outline${controlData && controlData.isCosmetic ? ' cosmetic-var' : ''} full-width fl-start`} onMouseEnter={(e) => setSchemaHighlightKey(schemaKey)} onMouseLeave={(e) => setSchemaHighlightKey('')}>
                                                                                                                                                            <CollapsibleHeadedSection title={schemaKey} open={false}>
                                                                                                                                                                <div className="fl-column">
                                                                                                                                                                    <FormField id={"schema" + schemaIndex + "_varName"} label="Var Name" placeholder="Enter Var Name" value={variable.valSchema[schemaKey].__ed__schemaKey || ''} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], '__ed__schemaKey', value)} confirmChangeFunc={(value) => changeSchemaKey(variable, schemaKey, value)} showError={false} />
                                                                                                                                                                    <div className="label-note">{`${customisation.tabKey}.${option.varKey}.${variable.varName}.${schemaKey}`}</div>
                                                                                                                                                                    {controlData && controlData.noLabel !== true &&
                                                                                                                                                                        <>
                                                                                                                                                                            <FormField id={"schema" + schemaIndex + "_label"} type="text" label="Label" placeholder="Enter Label" value={variable.valSchema[schemaKey].label || ''} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'label', value)} showError={false} />
                                                                                                                                                                            {controlData && controlData.noLabelNote !== true &&
                                                                                                                                                                                <TextArea id={"schema" + schemaIndex + "_labelNote"} rows={3} label="Label Note" placeholder="Enter Label Note" value={variable.valSchema[schemaKey].labelNote || ''} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'labelNote', value)} showError={false} />
                                                                                                                                                                            }
                                                                                                                                                                        </>
                                                                                                                                                                    }
                                                                                                                                                                    {controlData && (controlData.noVal !== true && controlData.noDefaultVal !== true) &&
                                                                                                                                                                        <>
                                                                                                                                                                            {controlData.defaultVarType === VAR_TYPES.booleanType &&
                                                                                                                                                                                <div className="fl-row">
                                                                                                                                                                                    <div className="label no-wrap">Default<br/><span className="label-note">Used when adding new items to multiples</span></div>
                                                                                                                                                                                    <div className="grow"></div>
                                                                                                                                                                                    <div className="shrink">
                                                                                                                                                                                        <Toggle id={"option" + j + "_advancedMode"} currentState={variable.valSchema[schemaKey].default || false} toggleFunc={(e, id) => changeObjectValue(variable.valSchema[schemaKey], 'default', !(variable.valSchema[schemaKey].default || false))} />
                                                                                                                                                                                    </div>
                                                                                                                                                                                </div>
                                                                                                                                                                            }
                                                                                                                                                                            {controlData.defaultVarType !== VAR_TYPES.booleanType &&
                                                                                                                                                                            <>
                                                                                                                                                                                {CUSTOMISED_URL_MAP.hasOwnProperty(controlData.type) && 
                                                                                                                                                                                    <FormField id={"schema" + schemaIndex + "_default"} label="Default" readonly={true} labelNote="Used when adding new items to multiples" placeholder="Currently Empty" value={variable.valSchema[schemaKey].default.filename} setFunc={null} showError={false} />
                                                                                                                                                                                }
                                                                                                                                                                                {!CUSTOMISED_URL_MAP.hasOwnProperty(controlData.type) &&
                                                                                                                                                                                    <FormField id={"schema" + schemaIndex + "_default"} label={`Default${controlData.type === FEATURE_CONTROLS_TYPES.CopySettingsButton.type ? ' Vals Location' : ''}`} labelNote={controlData.type === FEATURE_CONTROLS_TYPES.CopySettingsButton.type ? "The family location to copy settings from (you can leave the Family Key off if it's the same)" : "Used when adding new items to multiples"} placeholder="Enter Default" value={variable.valSchema[schemaKey].default} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'default', value)} showError={false} />
                                                                                                                                                                                }
                                                                                                                                                                            </>
                                                                                                                                                                            }
                                                                                                                                                                        </>
                                                                                                                                                                    }
                                                                                                                                                                    <div className="fl-row">
                                                                                                                                                                        <div className="label no-wrap">Advanced Mode</div>
                                                                                                                                                                        <div className="grow"></div>
                                                                                                                                                                        <div className="shrink">
                                                                                                                                                                            <Toggle id={"option" + j + "_advancedMode"} currentState={variable.valSchema[schemaKey].advancedMode || false} toggleFunc={(e, id) => changeObjectValue(variable.valSchema[schemaKey], 'advancedMode', !(variable.valSchema[schemaKey].advancedMode || false))} />
                                                                                                                                                                        </div>
                                                                                                                                                                    </div>
                                                                                                                                                                    <FormDropDown id={"schema" + schemaIndex + "_varType"} label="Var Type" items={getCustomisationControlTypes()} currentIndex={getSelectedIndex(getCustomisationControlTypes(), variable.valSchema[schemaKey], 'type')} selectFunc={(e, id, item) => setValFromDropDown(item, variable.valSchema[schemaKey], 'type')} showErrors={false} />
                                                                                                                                                                    {controlData && (controlData.type === FEATURE_CONTROLS_TYPES.DescriptionString.type || controlData.type === FEATURE_CONTROLS_TYPES.LightDescriptionString.type || controlData.type === FEATURE_CONTROLS_TYPES.WarningDescriptionString.type || controlData.type === FEATURE_CONTROLS_TYPES.ConfirmDescriptionString.type || controlData.type === FEATURE_CONTROLS_TYPES.AttentionDescriptionString.type) &&
                                                                                                                                                                        <TextArea id={"schema" + schemaIndex + "_description"} rows={3} label="Info Text" placeholder="Enter Text" value={variable.valSchema[schemaKey].description || ''} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'description', value)} showError={false} />
                                                                                                                                                                    }
                                                                                                                                                                    {controlData && controlData.defaultVarType && controlData.defaultVarType === VAR_TYPES.numberType && controlData.noMinMax !== true && 
                                                                                                                                                                        <>
                                                                                                                                                                            <FormField id={"schema" + schemaIndex + "_minVal"} label="Min Value" type="number" placeholder="Enter Min Value" value={variable.valSchema[schemaKey].minVal} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'minVal', value)} showError={false} />
                                                                                                                                                                            <FormField id={"schema" + schemaIndex + "_maxVal"} label="Max Value" type="number" placeholder="Enter Max Value" value={variable.valSchema[schemaKey].maxVal} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'maxVal', value)} showError={false} />
                                                                                                                                                                        </>
                                                                                                                                                                    }
                                                                                                                                                                    {controlData && controlData.defaultVarType && controlData.defaultVarType === VAR_TYPES.stringType && controlData.noMaxLength !== true &&
                                                                                                                                                                        <FormField id={"schema" + schemaIndex + "_maxLength"} label="Max Length" placeholder="Enter Max Length" value={variable.valSchema[schemaKey].maxLength} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'maxLength', value)} showError={false} />
                                                                                                                                                                    }
                                                                                                                                                                    {controlData && controlData.type === FEATURE_CONTROLS_TYPES.Range.type &&
                                                                                                                                                                        <>
                                                                                                                                                                            <div className="fl-row">
                                                                                                                                                                                <div className="label no-wrap">Show Value</div>
                                                                                                                                                                                <div className="grow"></div>
                                                                                                                                                                                <div className="shrink">
                                                                                                                                                                                    <Toggle id={"option" + j + "_showCurrentValue"} currentState={typeof variable.valSchema[schemaKey].showCurrentValue !== 'undefined' ? variable.valSchema[schemaKey].showCurrentValue : true} toggleFunc={(e, id) => changeObjectValue(variable.valSchema[schemaKey], 'showCurrentValue', !(variable.valSchema[schemaKey].showCurrentValue || false))} />
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                            <div className="fl-row">
                                                                                                                                                                                <div className="label no-wrap">Use Text Labels</div>
                                                                                                                                                                                <div className="grow"></div>
                                                                                                                                                                                <div className="shrink">
                                                                                                                                                                                    <Toggle id={"option" + j + "_useTextLabels"} currentState={variable.valSchema[schemaKey].useTextLabels || false} toggleFunc={(e, id) => changeObjectValue(variable.valSchema[schemaKey], 'useTextLabels', !(variable.valSchema[schemaKey].useTextLabels || false))} />
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                            {variable.valSchema[schemaKey].useTextLabels &&
                                                                                                                                                                            <>
                                                                                                                                                                                <FormField id={"schema" + schemaIndex + "_minLabel"} label="Min Label" placeholder="Enter Min Label" value={variable.valSchema[schemaKey].minLabel} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'minLabel', value)} showError={false} />
                                                                                                                                                                                <FormField id={"schema" + schemaIndex + "_midLabel"} label="Mid Label" placeholder="Enter Mid Label" value={variable.valSchema[schemaKey].midLabel} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'midLabel', value)} showError={false} />
                                                                                                                                                                                <FormField id={"schema" + schemaIndex + "_maxLabel"} label="Max Label" placeholder="Enter Max Label" value={variable.valSchema[schemaKey].maxLabel} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'maxLabel', value)} showError={false} />
                                                                                                                                                                            </>
                                                                                                                                                                            }
                                                                                                                                                                        </>
                                                                                                                                                                    }

                                                                                                                                                                    {controlData && controlData.type === FEATURE_CONTROLS_TYPES.ImagePreview.type &&
                                                                                                                                                                        <FormField id={"schema" + schemaIndex + "_previewFor"} label="Preview For" labelNote="The drop down to pull preview image from (see object zapper)" placeholder="Enter Var Name" value={variable.valSchema[schemaKey].previewFor || ''} setFunc={(value) => changeObjectValue(variable.valSchema[schemaKey], 'previewFor', value)} showError={false} />
                                                                                                                                                                    }

                                                                                                                                                                    {controlData && (controlData.type === FEATURE_CONTROLS_TYPES.DropDown.type || controlData.type === FEATURE_CONTROLS_TYPES.Radio.type || controlData.type === FEATURE_CONTROLS_TYPES.RadioVertical.type) &&
                                                                                                                                                                        <CollapsibleHeadedSection title={`Options (${variable.valSchema[schemaKey].valOptions ? variable.valSchema[schemaKey].valOptions.length : '0'})`} open={false}>
                                                                                                                                                                            <div className="fl-column">
                                                                                                                                                                                {variable.valSchema[schemaKey].valOptions && variable.valSchema[schemaKey].valOptions.map(
                                                                                                                                                                                    (option, k) => {
                                                                                                                                                                                        const valKey = option.val ? 'val' : 'value';
                                                                                                                                                                                        return (
                                                                                                                                                                                            <div key={"option" + k} className={`seeder-content-box full-width`}>
                                                                                                                                                                                                <div className="fl-row equal">
                                                                                                                                                                                                    <FormField id={"option" + k + "_option"} label="Label" placeholder="Enter Label" value={option.label || ''} setFunc={(value) => changeObjectValue(option, 'label', value)} showError={false} />
                                                                                                                                                                                                    <FormField id={"option" + k + "_val"} type='text' label="Value" placeholder="Enter Value" value={option[valKey]} setFunc={(value) => changeObjectValue(option, valKey, value)} showError={false} />
                                                                                                                                                                                                </div>
                                                                                                                                                                                                <CollapsibleHeadedSection title="More..." open={false}>
                                                                                                                                                                                                    <>
                                                                                                                                                                                                    <div className="fl-row">
                                                                                                                                                                                                        <FormField id={"option" + k + "_previewFile"} label="Preview File" labelNote="Preview files must exist in public/customise-previews/<br />(see object zapper)" placeholder={`Enter File Path`} value={option.previewFile || ''} setFunc={(value) => changeObjectValue(option, 'previewFile', value)} showError={false} />
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    <CollapsibleHeadedSection title="Basic Mode Preset" open={false}>
                                                                                                                                                                                                        <>
                                                                                                                                                                                                        {option.basicModePreset &&
                                                                                                                                                                                                            <div className="fl-column full-width fl-start customisation-controls-holder rel">
                                                                                                                                                                                                                <div className="control-group">
                                                                                                                                                                                                                    <CustomisationControls
                                                                                                                                                                                                                        renderSingleControlBlock={true}
                                                                                                                                                                                                                        highlightUndefined={true}
                                                                                                                                                                                                                        showUndefinedToggle={true}
                                                                                                                                                                                                                        toggleUndefinedFunc={
                                                                                                                                                                                                                            (presetObject, defaultValue, key) => {
                                                                                                                                                                                                                                if (typeof presetObject[key] === 'undefined') {
                                                                                                                                                                                                                                    try {
                                                                                                                                                                                                                                        presetObject[key] = JSON.parse(JSON.stringify(defaultValue));
                                                                                                                                                                                                                                    } catch (error) {
                                                                                                                                                                                                                                        presetObject[key] = defaultValue;
                                                                                                                                                                                                                                    }
                                                                                                                                                                                                                                } else {
                                                                                                                                                                                                                                    delete presetObject[key];
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                if (!showLiveEditVarFamilyPopup) {
                                                                                                                                                                                                                                    setFeatureData({ ...featureData_ref.current });
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        vals={[option.basicModePreset]}
                                                                                                                                                                                                                        displaySettings={variable}
                                                                                                                                                                                                                        controlsData={featureData.customisation}
                                                                                                                                                                                                                        rootVals={null}
                                                                                                                                                                                                                        objRef={null}
                                                                                                                                                                                                                        varBoxWidthStyle={null}
                                                                                                                                                                                                                        updateFunction={
                                                                                                                                                                                                                            (newVal, valsArray, varSchema, index, valKey) => {
                                                                                                                                                                                                                                // console.log('Val changed: ', newVal);
                                                                                                                                                                                                                                if (CUSTOMISED_URL_MAP.hasOwnProperty(variable.valSchema[valKey].type.toLowerCase())) {
                                                                                                                                                                                                                                    // File!
                                                                                                                                                                                                                                    const fileNameSplit = newVal.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                                                                                                                                                                                                                                    // fileNameSplit[0] += '__' + Date.now();
                                                                                                                                                                                                                                    option.basicModePreset[valKey].filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                                                                                                                                                                                                                                    newVal.uniqueFilename = fileNameSplit.join('.');
                                                                                                                                                                                                                                    option.basicModePreset[valKey].newFile = newVal;
                                                                                                                                                                                                                                    option.basicModePreset[valKey].srcUrl = newVal ? URL.createObjectURL(newVal) : null;
                                                                                                                                                                                                                                    option.basicModePreset[valKey].isDefault = true;
                                                                                                                                                                                                                                    option.basicModePreset[valKey].updated = true;
                                                                                                                                                                                                                                    // valsArray[index].isDefault = false;
                                                                                                                                                                                                                                    // valsArray[index].updated = true;
                                                                                                                                                                                                                                    // console.log('File handled: ', option.basicModePreset[valKey]);
                                                                                                                                                                                                                                } else {
                                                                                                                                                                                                                                    // Everything else!
                                                                                                                                                                                                                                    option.basicModePreset[valKey] = newVal;
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                if (!showLiveEditVarFamilyPopup) {
                                                                                                                                                                                                                                    setFeatureData({ ...featureData_ref.current });
                                                                                                                                                                                                                                }
                                                                                                                                                                                                                            }
                                                                                                                                                                                                                        }
                                                                                                                                                                                                                        highlightKey={schemaHighlighKey}
                                                                                                                                                                                                                    />
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        }
                                                                                                                                                                                                        {!option.basicModePreset &&
                                                                                                                                                                                                            <div className="fl-row">
                                                                                                                                                                                                                <div className="shrink">
                                                                                                                                                                                                                    <button className="standard-button tight" onClick={(e) => {option.basicModePreset = {}; setFeatureData({...featureData_ref.current})}}>Add Basic Mode Preset</button>
                                                                                                                                                                                                                </div>
                                                                                                                                                                                                                <div className="grow"></div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        }
                                                                                                                                                                                                        </>
                                                                                                                                                                                                    </CollapsibleHeadedSection>
                                                                                                                                                                                                    </>
                                                                                                                                                                                                </CollapsibleHeadedSection>
                                                                                                                                                                                                <div className="move-icons">
                                                                                                                                                                                                    <div className='move-icon' onClick={(e) => moveValOption(variable.valSchema[schemaKey].valOptions, k, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                                                    <div className='move-icon' onClick={(e) => moveValOption(variable.valSchema[schemaKey].valOptions, k, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                                <div className='del-icon' onClick={(e) => {variable.valSchema[schemaKey].valOptions.splice(k, 1); setFeatureData({...featureData_ref.current})}}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                                            </div>
                                                                                                                                                                                        )
                                                                                                                                                                                    }
                                                                                                                                                                                )}
                                                    
                                                                                                                                                                                <div className="fl-row">
                                                                                                                                                                                    <div className="shrink">
                                                                                                                                                                                        <button className="standard-button tight" onClick={(e) => addCustomisationValOption(e, variable.valSchema[schemaKey].valOptions)}>Add Option</button>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    <div className="grow"></div>
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </CollapsibleHeadedSection>
                                                                                                                                                                    }
                                                                                                                                                                    <div className={`seeder-content-box full-width fl-start`}>
                                                                                                                                                                        <div className="move-icons">
                                                                                                                                                                            <div className='move-icon' onClick={(e) => copyValSchemaItemDisplayConditionsList(e, variable, schemaKey)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                        </div>
                                                                                                                                                                        <CollapsibleHeadedSection title={`Disp Conds (${variable.valSchema[schemaKey].displayCondition ? variable.valSchema[schemaKey].displayCondition.length : '0'})`} open={false}>
                                                                                                                                                                            <div className="fl-column">
                                                                                                                                                                                {variable.valSchema[schemaKey].displayCondition && variable.valSchema[schemaKey].displayCondition.map(
                                                                                                                                                                                    (condition, k) => {
                                                                                                                                                                                        return (
                                                                                                                                                                                            <div key={"condition" + k} className={`seeder-content-box outline full-width`}>
                                                                                                                                                                                                <div className="fl-row equal">
                                                                                                                                                                                                    <FormField id={"condition" + k + "_var"} label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue(condition, 'var', value)} showError={false} />
                                                                                                                                                                                                    <div className="eval-width">
                                                                                                                                                                                                        <FormDropDown id={"condition" + k + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue(condition, 'evalCondition', item.value)} showErrors={false} />
                                                                                                                                                                                                    </div>
                                                                                                                                                                                                    <FormField id={"condition" + k + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue(condition, 'val', value)} showError={false} />
                                                                                                                                                                                                </div>
                                                                                                                                                                                                <div className="move-icons">
                                                                                                                                                                                                    <div className='move-icon' onClick={(e) => copyValSchemaItemDisplayCondition(e, variable, schemaKey, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                                                </div>
                                                                                                                                                                                                <div className='del-icon' onClick={(e) => removeValSchemaItemDisplayCondition(e, variable, schemaKey, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                                            </div>
                                                                                                                                                                                        )
                                                                                                                                                                                    }
                                                                                                                                                                                )}
                                                                                                                                                                                <div className="fl-row">
                                                                                                                                                                                    <div className="shrink">
                                                                                                                                                                                        <button className="standard-button tight" onClick={(e) => addValSchemaItemDisplayCondition(e, variable, schemaKey)}>Add Display Condition</button>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    <div className="shrink">
                                                                                                                                                                                        <button className="standard-button tight" onClick={(e) => pasteValSchemaItemDisplayCondition(e, variable, schemaKey)}>Paste Display Condition</button>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    <div className="grow"></div>
                                                                                                                                                                                </div>
                                                                                                                                                                                <div className="fl-row">
                                                                                                                                                                                    <div className="shrink">
                                                                                                                                                                                        <button className="standard-button tight" onClick={(e) => {pasteValSchemaItemDisplayConditionsList(e, variable, schemaKey);}}>Paste Display Conditions List</button>
                                                                                                                                                                                    </div>
                                                                                                                                                                                    <div className="grow"></div>
                                                                                                                                                                                </div>
                                                                                                                                                                            </div>
                                                                                                                                                                        </CollapsibleHeadedSection>
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                            </CollapsibleHeadedSection>
                                                                                                                                                            <div className="insert-above-icons">
                                                                                                                                                                <div className="insert-above-icon" onClick={(e) => insertValSchemaItemAbove(e, variable, schemaKey)}>{IconJsxer.GetIcon(IconJsxer.ICONS.share, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                <div className="insert-above-icon" onClick={(e) => pasteValSchemaItemAbove(e, variable, schemaKey)}>{IconJsxer.GetIcon(IconJsxer.ICONS.paste, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                <div className="insert-above-icon" onClick={(e) => addVarSchemaPredefinedBlockAbove(e, variable, variable.varName, schemaKey)}>{IconJsxer.GetIcon(IconJsxer.ICONS.snippet, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                            </div>
                                                                                                                                                            <div className="move-icons">
                                                                                                                                                                <div className="move-icon" onClick={(e) => copyValSchemaItem(e, variable, schemaKey)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                <div className='move-icon' onClick={(e) => moveValSchemaItem(e, variable, schemaKey, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                <div className='move-icon' onClick={(e) => moveValSchemaItem(e, variable, schemaKey, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                            </div>
                                                                                                                                                            <div className='del-icon' onClick={(e) => removeValSchemaItem(e, variable, schemaKey)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            )}
                                                                                                                                            <div className="fl-row">
                                                                                                                                                <div className="shrink">
                                                                                                                                                    <button className="standard-button tight" onClick={(e) => addValSchemaItem(e, variable)}>Add Variable</button>
                                                                                                                                                </div>
                                                                                                                                                <div className="shrink">
                                                                                                                                                    <button className="standard-button tight" onClick={(e) => pasteValSchemaItem(e, variable)}>Paste Variable</button>
                                                                                                                                                </div>
                                                                                                                                                <div className="grow"></div>
                                                                                                                                            </div>
                                                                                                                                            <div className="fl-row">
                                                                                                                                                <div className="grow">
                                                                                                                                                    <FormDropDown id={"select_blockType_" + j} label="" showLabel={false} showErrors={false} items={predefinedBlockDropDownOptions} currentIndex={selectedPredefinedBlockIndex} selectFunc={(e, id, item) => selectPredefinedBlock(item)} />
                                                                                                                                                </div>
                                                                                                                                                <div className="shrink">
                                                                                                                                                    <button className="standard-button tight" onClick={(e) => addVarSchemaPredefinedBlock(e, variable, variable.varName)}>Add Var Block</button>
                                                                                                                                                </div>
                                                                                                                                                {/* <div className="shrink">
                                                                                                                                                    <button className="standard-button tight" onClick={(e) => addVarSchemaPredefinedBlock(e, variable, variable.varName, 'fontSettings')}>Add Font Options</button>
                                                                                                                                                </div>
                                                                                                                                                <div className="shrink">
                                                                                                                                                    <button className="standard-button tight" onClick={(e) => addVarSchemaPredefinedBlock(e, variable, variable.varName, 'animateSettings')}>Add Anim Options</button>
                                                                                                                                                </div> */}
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                }
                                                                                                                                </>
                                                                                                                            </CollapsibleHeadedSection>
                                                                                                                </div>

                                                                                                                {/* <div className="fl-row"></div> */}

                                                                                                                <div className="seeder-content-box full-width outline">
                                                                                                                    <CollapsibleHeadedSection title={`Initial Settings (${variable.vals ? variable.vals.length : '0'})`} open={false}>
                                                                                                                        <div className="fl-row fl-wrap">
                                                                                                                            {variable.vals && variable.vals.map(
                                                                                                                                (val, l) => {
                                                                                                                                    return (
                                                                                                                                        <div className="fl-column half-width fl-start customisation-controls-holder rel" key={"init_set_" + l}>
                                                                                                                                            <div className="control-group">
                                                                                                                                                <CustomisationControls
                                                                                                                                                    renderSingleControlBlock={true}
                                                                                                                                                    vals={[val]}
                                                                                                                                                    displaySettings={variable}
                                                                                                                                                    controlsData={featureData.customisation}
                                                                                                                                                    rootVals={null}
                                                                                                                                                    objRef={null}
                                                                                                                                                    varBoxWidthStyle={null}
                                                                                                                                                    updateFunction={
                                                                                                                                                        (newVal, valsArray, varSchema, index, valKey) => {
                                                                                                                                                            // console.log('Val changed: ', newVal);
                                                                                                                                                            if (CUSTOMISED_URL_MAP.hasOwnProperty(variable.valSchema[valKey].type.toLowerCase())) {
                                                                                                                                                                if (typeof val[valKey] !== 'object') {
                                                                                                                                                                    val[valKey] = {};
                                                                                                                                                                }
                                                                                                                                                                // File!
                                                                                                                                                                const fileNameSplit = newVal.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                                                                                                                                                                // fileNameSplit[0] += '__' + Date.now();
                                                                                                                                                                val[valKey].filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                                                                                                                                                                newVal.uniqueFilename = fileNameSplit.join('.');
                                                                                                                                                                val[valKey].newFile = newVal;
                                                                                                                                                                val[valKey].srcUrl = newVal ? URL.createObjectURL(newVal) : null;
                                                                                                                                                                val[valKey].isDefault = true;
                                                                                                                                                                val[valKey].updated = true;
                                                                                                                                                                // valsArray[index].isDefault = false;
                                                                                                                                                                // valsArray[index].updated = true;
                                                                                                                                                                // console.log('File handled: ', val[valKey]);
                                                                                                                                                            } else {
                                                                                                                                                                // Everything else!
                                                                                                                                                                val[valKey] = newVal;
                                                                                                                                                            }
                                                                                                                                                            if (!showLiveEditVarFamilyPopup) {
                                                                                                                                                                setFeatureData({...featureData_ref.current});
                                                                                                                                                            }
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    highlightKey={schemaHighlighKey}
                                                                                                                                                    highlightNoValSet={true}
                                                                                                                                                />
                                                                                                                                            </div>
                                                                                                                                            <div className="grow"></div>
                                                                                                                                            {l > 0 &&
                                                                                                                                                <div className="del-icon" onClick={(e) => removeInitialSetting(variable.vals, l)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                            }
                                                                                                                                        </div>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                            )}
                                                                                                                            <div className="fl-row fl-start">
                                                                                                                                {(variable.allowMultiple || variable.vals.length === 0) && (variable.maxMultiples === -1 || variable.vals.length < variable.maxMultiples) &&
                                                                                                                                    <div className="shrink">
                                                                                                                                        <button className="standard-button tight" onClick={(e) => addInitialSetting(variable.vals, variable.valSchema)}>Add Initial Setting</button>
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                                {
                                                                                                                                    <div className="shrink">
                                                                                                                                        <button className="standard-button tight" onClick={(e) => resetInitialSettings(variable.vals, variable.valSchema)}>Reset Initial Settings</button>
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                                <div className="grow"></div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </CollapsibleHeadedSection>
                                                                                                                </div>

                                                                                                                {/* <div className="fl-row"></div> */}

                                                                                                                <div className="seeder-content-box full-width outline">
                                                                                                                    <CollapsibleHeadedSection title={`Presets (${variable.presets ? variable.presets.length : '0'})`} open={false}>

                                                                                                                        <div className="fl-column">

                                                                                                                        {variable.presets && variable.presets.map(
                                                                                                                            (preset, l) => {
                                                                                                                                return (
                                                                                                                                    <div className="seeder-content-box full-width outline" key={"preset_" + l}>
                                                                                                                                        <CollapsibleHeadedSection title={`${preset.name} (${preset.vals ? preset.vals.length : '0'})`} open={false}>
                                                                                                                                            <>
                                                                                                                                            <div className="fl-column">
                                                                                                                                                <FormField id={"preset" + l + "_name"} label="Name" placeholder="Enter Name" value={preset.name || ''} setFunc={(value) => changeObjectValue(preset, 'name', value)} showError={false} />
                                                                                                                                            </div>
                                                                                                                                            <div className="fl-row fl-wrap">
                                                                                                                                                {preset.vals && preset.vals.map(
                                                                                                                                                    (val, l) => {
                                                                                                                                                        return (
                                                                                                                                                            <div className="fl-column half-width fl-start customisation-controls-holder rel" key={"preset_val_" + l}>
                                                                                                                                                                <div className="control-group">
                                                                                                                                                                    <CustomisationControls
                                                                                                                                                                        renderSingleControlBlock={true}
                                                                                                                                                                        highlightUndefined={true}
                                                                                                                                                                        showUndefinedToggle={true}
                                                                                                                                                                        toggleUndefinedFunc={
                                                                                                                                                                            (presetObject, defaultValue, key) => {
                                                                                                                                                                                if (typeof presetObject[key] === 'undefined') {
                                                                                                                                                                                    try {
                                                                                                                                                                                        presetObject[key] = JSON.parse(JSON.stringify(defaultValue));
                                                                                                                                                                                    } catch (error) {
                                                                                                                                                                                        presetObject[key] = defaultValue;
                                                                                                                                                                                    }
                                                                                                                                                                                } else {
                                                                                                                                                                                    delete presetObject[key];
                                                                                                                                                                                }
                                                                                                                                                                                if (!showLiveEditVarFamilyPopup) {
                                                                                                                                                                                    setFeatureData({...featureData_ref.current});
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                        vals={[val]}
                                                                                                                                                                        displaySettings={variable}
                                                                                                                                                                        controlsData={featureData.customisation}
                                                                                                                                                                        rootVals={null}
                                                                                                                                                                        objRef={null}
                                                                                                                                                                        varBoxWidthStyle={null}
                                                                                                                                                                        updateFunction={
                                                                                                                                                                            (newVal, valsArray, varSchema, index, valKey) => {
                                                                                                                                                                                // console.log('Val changed: ', newVal);
                                                                                                                                                                                if (CUSTOMISED_URL_MAP.hasOwnProperty(variable.valSchema[valKey].type.toLowerCase())) {
                                                                                                                                                                                    // File!
                                                                                                                                                                                    const fileNameSplit = newVal.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                                                                                                                                                                                    // fileNameSplit[0] += '__' + Date.now();
                                                                                                                                                                                    val[valKey].filename = fileNameSplit.join('.'); // newData.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "");
                                                                                                                                                                                    newVal.uniqueFilename = fileNameSplit.join('.');
                                                                                                                                                                                    val[valKey].newFile = newVal;
                                                                                                                                                                                    val[valKey].srcUrl = newVal ? URL.createObjectURL(newVal) : null;
                                                                                                                                                                                    val[valKey].isDefault = true;
                                                                                                                                                                                    val[valKey].updated = true;
                                                                                                                                                                                    // valsArray[index].isDefault = false;
                                                                                                                                                                                    // valsArray[index].updated = true;
                                                                                                                                                                                    // console.log('File handled: ', val[valKey]);
                                                                                                                                                                                } else {
                                                                                                                                                                                    // Everything else!
                                                                                                                                                                                    val[valKey] = newVal;
                                                                                                                                                                                }
                                                                                                                                                                                if (!showLiveEditVarFamilyPopup) {
                                                                                                                                                                                    setFeatureData({...featureData_ref.current});
                                                                                                                                                                                }
                                                                                                                                                                            }
                                                                                                                                                                        }
                                                                                                                                                                        highlightKey={schemaHighlighKey}
                                                                                                                                                                    />
                                                                                                                                                                </div>
                                                                                                                                                                <div className="grow"></div>
                                                                                                                                                                {l > 0 &&
                                                                                                                                                                        <div className="del-icon" onClick={(e) => removePresetSetting(preset.vals, l)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                                                }
                                                                                                                                                            </div>
                                                                                                                                                        )
                                                                                                                                                    }
                                                                                                                                                )}
                                                                                                                                                {(variable.allowMultiple || preset.vals.length === 0) && (variable.maxMultiples === -1 || preset.vals.length < variable.maxMultiples) &&
                                                                                                                                                    <div className="fl-row fl-start">
                                                                                                                                                        <div className="shrink">
                                                                                                                                                            <button className="standard-button tight" onClick={(e) => addPresetSetting(preset.vals, variable.valSchema)}>Add Preset Setting</button>
                                                                                                                                                        </div>
                                                                                                                                                        <div className="grow"></div>
                                                                                                                                                    </div>
                                                                                                                                                }
                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                        </CollapsibleHeadedSection>

                                                                                                                                        <div className="del-icon" onClick={(e) => removePreset(variable, l)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        )}

                                                                                                                        <div className="fl-row fl-start">
                                                                                                                            <div className="shrink">
                                                                                                                                <button className="standard-button tight" onClick={(e) => addPreset(variable)}>Add Preset</button>
                                                                                                                            </div>
                                                                                                                            <div className="grow"></div>
                                                                                                                        </div>

                                                                                                                        </div>

                                                                                                                    </CollapsibleHeadedSection>
                                                                                                                </div>


                                                                                                                {/* <div className="fl-row"></div> */}

                                                                                                                <div className="seeder-content-box full-width outline">
                                                                                                                <CollapsibleHeadedSection title={`Display Conditions (${variable.displayCondition ? variable.displayCondition.length : '0'})`} open={false}>
                                                                                                                    <div className="fl-column">
                                                                                                                        {variable.displayCondition && variable.displayCondition.map(
                                                                                                                            (condition, l) => {
                                                                                                                                return (
                                                                                                                                    <div key={"condition" + l} className={`seeder-content-box full-width`}>
                                                                                                                                        <div className="fl-row equal">
                                                                                                                                            <FormField id={"condition" + l + "_var"} className="eval-var-width" label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue(condition, 'var', value)} showError={false} />
                                                                                                                                            <div className="eval-width">
                                                                                                                                                <FormDropDown id={"condition" + l + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue(condition, 'evalCondition', item.value)} showErrors={false} />
                                                                                                                                            </div>
                                                                                                                                            <FormField id={"condition" + l + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue(condition, 'val', value)} showError={false} />
                                                                                                                                        </div>
                                                                                                                                        <div className="del-icon" onClick={(e) => removeCustomisationVariableDisplayCondition(e, i, j, k, l)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                                    </div>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        )}
                                                                                                                        <div className="fl-row">
                                                                                                                            <div className="shrink">
                                                                                                                                <button className="standard-button tight" onClick={(e) => addCustomsationVariableDisplayCondition(e, i, j, k)}>Add Display Condition</button>
                                                                                                                            </div>
                                                                                                                            <div className="shrink">
                                                                                                                                <button className="standard-button tight" onClick={(e) => {pasteCustomisationVariableDisplayConditionsList(e, i, j, k)}}>Paste Display Conditions List</button>
                                                                                                                            </div>
                                                                                                                            <div className="grow"></div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </CollapsibleHeadedSection>
                                                                                                                <div className="move-icons">
                                                                                                                    <div className="move-icon" onClick={(e) => copyCustomisationVariableDisplayConditionsList(e, i, j, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                </div>
                                                                                                                </div>

                                                                                                                <div className="fl-row">
                                                                                                                    <div className="grow"></div>
                                                                                                                    <div className="shrink">
                                                                                                                        <button className="standard-button tight" onClick={(e) => console.log(variable)}>Log Out Data</button>
                                                                                                                    </div>
                                                                                                                    <div className="shrink">
                                                                                                                        <button className="standard-button tight" onClick={(e) => copyCustomisationVariable(e, i, j, k)}>Copy Var Family</button>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div className="move-icons">
                                                                                                                    <div className="move-icon" onClick={(e) => copyCustomisationVariable(e, i, j, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                    <div className="move-icon" onClick={(e) => moveCustomisationVariable(e, i, j, k, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                    <div className="move-icon" onClick={(e) => moveCustomisationVariable(e, i, j, k, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                                </div>
                                                                                                                <div className="del-icon" onClick={(e) => removeCustomisationVariable(e, i, j, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                )}

                                                                                                <div className="fl-row shrink fl-start">
                                                                                                    <div className="shrink fl-start">
                                                                                                        <button className="standard-button tight" onClick={(e) => addCustomisationVariable(e, i, j)}>Add Variable Family</button>
                                                                                                    </div>
                                                                                                    <div className="shrink fl-start">
                                                                                                        <button className="standard-button tight" onClick={(e) => pasteCustomisationVariable(e, i, j)}>Paste Variable Family Data</button>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                            }
                                                                                            </>
                                                                                        </CollapsibleHeadedSection>
                                                                                        </div>

                                                                                        {/* <div className="fl-row"></div> */}
                                                                                        
                                                                                        <div className="seeder-content-box full-width outline">
                                                                                        <CollapsibleHeadedSection title={`Display Conditions (${option.displayCondition && option.displayCondition.length ? option.displayCondition.length : '0'})`} open={false}>

                                                                                            <div className="fl-row fl-wrap">

                                                                                                {option.displayCondition && option.displayCondition.map(
                                                                                                    (condition, k) => {
                                                                                                        return (
                                                                                                            <div key={"condition" + k} className={`seeder-content-box quarter-width`}>
                                                                                                                <div className="fl-row equal">
                                                                                                                    <FormField id={"condition" + k + "_var"} className="eval-var-width" label="Var" placeholder="Enter Var" value={condition.var || ''} setFunc={(value) => changeObjectValue(condition, 'var', value)} showError={false} />
                                                                                                                    <div className="eval-width">
                                                                                                                        <FormDropDown id={"condition" + k + "_evalCondition"} label="Eval" items={EVAL_CONDITION_ITEMS} currentIndex={getEvalConditionIndex(condition.evalCondition)} selectFunc={(e, id, item) => changeObjectValue(condition, 'evalCondition', item.value)} showErrors={false} />
                                                                                                                    </div>
                                                                                                                    <FormField id={"condition" + k + "_val"} label="Value" placeholder="Enter Value" value={String(condition.val)} setFunc={(value) => changeObjectValue(condition, 'val', value)} showError={false} />
                                                                                                                </div>
                                                                                                                <div className="del-icon" onClick={(e) => removeCustomisationGroupDisplayCondition(e, i, j, k)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                )}

                                                                                                <div className="fl-row fl-start">
                                                                                                    <div className="shrink">
                                                                                                        <button className="standard-button tight" onClick={(e) => addCustomisationGroupDisplayCondition(e, i, j)}>Add Display Condition</button>
                                                                                                    </div>
                                                                                                    <div className="shrink">
                                                                                                        <button className="standard-button tight" onClick={(e) => pasteCustomisationGroupDisplayConditionsList(e, i, j)}>Paste Display Conditions</button>
                                                                                                    </div>
                                                                                                    <div className="grow"></div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </CollapsibleHeadedSection>
                                                                                                <div className="move-icons">
                                                                                                    <div className="move-icon" onClick={(e) => copyCustomisationGroupDisplayConditionsList(e, i, j)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                                </div>
                                                                                        </div>

                                                                                        <div className="move-icons">
                                                                                            <div className="move-icon" onClick={(e) => copyCustomisationGroup(e, i, j)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                            <div className="move-icon" onClick={(e) => moveCustomisationGroup(e, i, j, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                            <div className="move-icon" onClick={(e) => moveCustomisationGroup(e, i, j, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                        </div>
                                                                                        <div className="del-icon" onClick={(e) => removeCustomisationGroup(e, i, j)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )}

                                                                        <div className="fl-row shrink fl-start">
                                                                            <div className="shrink fl-start">
                                                                                <button className="standard-button tight" onClick={(e) => addCustomisationGroup(e, i)}>Add Group</button>
                                                                            </div>
                                                                            <div className="shrink fl-start">
                                                                                <button className="standard-button tight" onClick={(e) => pasteCustomisationGroup(e, i)}>Paste Group Data</button>
                                                                            </div>
                                                                            <div className="fl-row">
                                                                                <div className="grow">
                                                                                    <FormDropDown id={"select_groupType"} label="" showLabel={false} showErrors={false} items={predefinedGroupDropDownOptions} currentIndex={selectedPredefinedGroupIndex} selectFunc={(e, id, item) => selectPredefinedGroup(item)} />
                                                                                </div>
                                                                                <div className="shrink">
                                                                                    <button className="standard-button tight" onClick={(e) => {addPredefinedCustomisationGroup(e, i);}}>Add Predefined Group</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    }
                                                                    </>
                                                                </CollapsibleHeadedSection>
                                                                </div>

                                                                <div className="move-icons">
                                                                    <div className="move-icon" onClick={(e) => copyCustomisationTab(e, i)}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                    <div className="move-icon" onClick={(e) => moveCustomisationTab(e, i, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                    <div className="move-icon" onClick={(e) => moveCustomisationTab(e, i, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                                </div>
                                                                <div className="del-icon" onClick={(e) => removeCustomisationTab(e, i)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                                                            </div>
                                                        )
                                                    }
                                                )}

                                                <div className="fl-row">
                                                    <div className="shrink fl-start">
                                                        <button className="standard-button tight" onClick={addCustomisationTab}>Add Tab</button>
                                                    </div>
                                                    <div className="shrink fl-start">
                                                        <button className="standard-button tight" onClick={pasteCustomisationTab}>Paste Tab Data</button>
                                                    </div>
                                                    <div className="shrink fl-start">
                                                        <button className="standard-button tight" onClick={resetAllIntialSettings}>Reset All Initial Settings</button>
                                                    </div>
                                                    <div className="shrink fl-start">
                                                        <button className="standard-button tight" onClick={openPreview}>Preview</button>
                                                    </div>
                                                    <div className="grow"></div>
                                                </div>

                                            </div>

                                        </div>
                                        }
                                        </>
                                    </CollapsibleHeadedSection>
                                </div>

                                {/* <div className={`co-tab-content-area fl-equal`}>
                                    <div className="underlined-panel-heading">
                                        <h4>Feature Commands</h4>
                                        <div className="grow"></div>
                                    </div>

                                    {featureData.commands && featureData.commands.map(
                                        (command, i) => {
                                            return (
                                                <div key={"command_" + i}>
                                                    <h3>{command.title || command.Title}</h3>
                                                    <p>{command.command}</p>
                                                    <p>{command.description}</p>
                                                </div>
                                            )
                                        }
                                    )};

                                </div> */}





                            </div>


                        </div>

                        
                    </div>
                </>
            }

            {/* Hidden File Input */}
            <input
                type="file"
                accept=".json"
                name="jsonFile"
                onChange={handleJsonFile}
                hidden
                ref={jsonInputRef}
                id="jsonInput"
            />

        </div>
        }
            {showPreview &&
                <CustomisationPreviewPopup
                    closePanelFunc={closePreview}
                    featureData={featureData}
                    customisationVars={generateCustomisationPreviewData(false)}
                    getGroupLayoutSettings={getGroupLayoutSettings}
                    getVarFamilyLayoutSettings={getVarFamilyLayoutSettings}
                    addVarGroup={addCustomisationGroup}
                    predefinedGroupDropDownOptions={predefinedGroupDropDownOptions}
                    selectPredefinedGroup={selectPredefinedGroup}
                    selectedPredefinedGroupIndex={selectedPredefinedGroupIndex}
                    addPredefinedCustomisationGroup={addPredefinedCustomisationGroup}
                    addVarFamily={addCustomisationVariable}
                    pasteVarFamily={pasteCustomisationVariable}
                />
            }
            {showLiveEditVarFamilyPopup &&
                <LiveEditVarFamilyPopup />
            }

        </>
    )
}

export default SeederEditor;
