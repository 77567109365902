import { useEffect, useRef, useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import "./LiveTextControls.scss";

const LiveTextQueueStatus = (props) => {
    const [tick, setTick] = useState(0);
    const [allowCommandButtons, setAllowCommandButtons] = useState(false);

    const getStateData = () => {
        let stateData = null;
        if (props.previewControls && props?.passedInPreviewData?.liveTextStatus) {
            stateData = props.passedInPreviewData.liveTextStatus;
        }
        if (stateData === null) {
            stateData = props?.featureVars?.liveTextStatus;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {
            activeMessages: [],
            queuedMessages: [],
        };
    }

    const stateData = getStateData();
    console.log('LiveTextQueueStatus stateData', stateData);
    const templatesData = props.customisationData?.setup?.templates?.settings;

    useEffect(() => {
        console.log('LiveTextQueueStatus props', props);
        setTick((t) => t + 1);
        setAllowCommandButtons(true);
    }, [props]);

    return (
        <div className="fl-column">
            <div className="fl-row compact">
                <div className="label shrink">Queued Messages:</div>
                <div className="label grow">{stateData.queuedMessages.length}</div>
            </div>
            <div className="fl-column lt-mess-list-box">
                {stateData.queuedMessages.map((message, index) => {
                    return (
                        <div key={index} className={`one-live-message-status${message.isResponse ? ' response' : ''} fl-column compact`}>
                            <div className="fl-row compact">
                                <div className="shrink">Template:</div>
                                <div className="shrink dark-text">
                                    {templatesData && templatesData.length &&
                                        <>{templatesData[message.templateIndex].templateName}</>
                                    }
                                    {(!templatesData || templatesData.length === 0) &&
                                        <>Unknown</>
                                    }
                                    {message.isResponse &&
                                        <span className="light-text"> (Response)</span>
                                    }
                                </div>
                                <div className="grow"></div>
                            </div>
                            <div className="fl-column no-gap">
                                <div className="grow bold-text dark-text">{message.headerText}</div>
                                <div className="grow dark-text">{message.mainContent}</div>
                            </div>
                            <div className="fl-row">
                                <div className={`small-lt-control-btn ${allowCommandButtons ? 'green' : 'grey'} grow`} onClick={allowCommandButtons ? (e) => {props.sendFeatureCommandFunc(e, 'TRIGGER_QUEUED_MESSAGE_LEAVE_IN_QUEUE', props.featureId, index); setAllowCommandButtons(false)} : null}>Show Now</div>
                                <div className={`small-lt-control-btn ${allowCommandButtons ? 'red' : 'grey'} shrink`} onClick={allowCommandButtons ? (e) => {props.sendFeatureCommandFunc(e, 'REMOVE_MESSAGE_FROM_QUEUE', props.featureId, index); setAllowCommandButtons(false)} : null}>Remove From Queue</div>
                            </div>
                            <div className="fl-row">
                                <div className={`small-lt-control-btn ${allowCommandButtons ? 'yellow' : 'grey'} grow`} onClick={allowCommandButtons ? (e) => {props.sendFeatureCommandFunc(e, 'TRIGGER_QUEUED_MESSAGE', props.featureId, index); setAllowCommandButtons(false)} : null}>Show Now And Remove From Queue</div>
                            </div>
                        </div>
                    )
                })}
            </div>
            {stateData.queueError !== '' &&
                <div className="label warning-text">{stateData.queueError}</div>
            }
        </div>
    )
}

export default LiveTextQueueStatus;
