import { useEffect, useRef, useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import "./LiveTextControls.scss";

const LiveTextTemplateSelector = (props) => {
    // Build our drop down options from props.customisationData.setup.templates.settings[i].templateName
    const templateListData = [];

    const templatesData = props.customisationData?.setup?.templates?.settings;
    if (!templatesData || templatesData.length === 0) {
        templateListData.push({
            id: 0,
            label: "No Templates Available",
            value: 0,
            val: 0
        });
    } else {
        for (let i = 0; i < props.customisationData.setup.templates.settings.length; i++) {
            templateListData.push({
                id: i,
                label: props.customisationData.setup.templates.settings[i].templateName,
                value: i,
                val: i
            });
        }
    }

    // onMount - make sure we have these values!
    useEffect(() => {
        // Ensure we have a valid template index
        let correctTemplateIndex = props.value;
        if (isNaN(correctTemplateIndex) || correctTemplateIndex < 0 || correctTemplateIndex >= templateListData.length) {
            correctTemplateIndex = 0;
        }
        if (correctTemplateIndex !== props.value) {
            props.setFunc(correctTemplateIndex);
        }
    }, []);

    return (
        <>
            <FormDropDown
                label={props.label}
                items={templateListData}
                value={props.value}
                selectFunc={
                    (e, id, item) => {
                        props.setFunc(item.index)
                    }
                }
                currentIndex={props.value}
                showErrors={false}
                enabled={templateListData.length > 1}
            />
        </>
    )
}

export default LiveTextTemplateSelector;
