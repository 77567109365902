import { useContext, useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { deepObjectMerge, POST_MESSAGE_IN_TYPES, POST_MESSAGE_OUT_TYPES } from "../../helpers/FeatureControlsHelper";
import "./FeaturePreview.scss";
import HeadedPanel from "../HeadedPanel/HeadedPanel";
import { IconJsxer } from "../../helpers/IconHelper";
import FeatureControls from "../FeatureControls/FeatureControls";
import { CUSTOMISED_ASSETS_BASE_URL, CUSTOMISED_URL_MAP, getValFromObject } from "../../config/FeatureDashboardVarMap";
import { createArcadeEntriesRoute, filesRoute, getCampaignThemesRoute, getQuizzesByIdsRoute } from "../../api/routes";
import { assets } from "../../assets/assets";
import { getFeatureAssetBaseUrl } from "../../helpers/FeatureAssets";
import { findFeature, findLinkedFeatureTriggers } from "../../helpers/CampaignsHelper";
import { CampaignContext } from "../../contexts/CampaignContext";
import axios from "../../api/axios";
import { injectQuizImageSrcUrls } from "../../helpers/QuizHelper";
import { AuthContext } from "../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";

const DEV_MODE = false;
const PREVIEW_DEV_URL = "http://localhost:3001";
const isDevMode = () => {
    // console.log('is dev mode? ', process.env.NODE_ENV, DEV_MODE);
    return (process.env.NODE_ENV === "local" || process.env.NODE_ENV === "development") && DEV_MODE;
}

export const currentPassedInPreviewData = {data: {}};
export const postMessageToPreviewFrame = (type, detail, frameId = null) => {
    const frame = frameId || "previewFrame";
    const frameRef = document.getElementById(frame);
    const dataToPost = JSON.parse(JSON.stringify(detail)); // {...detail, ...currentPassedInPreviewData.data};
    deepObjectMerge(dataToPost, currentPassedInPreviewData.data);
    console.log('Post to preview frame: ', frame, frameRef, type, dataToPost);
    frameRef?.contentWindow.postMessage({ type: type, detail: dataToPost }, '*');
}

const previewEdgeBuffer = 5;
const previewWidth = 475;
const previewHeight = 350;

// const quizData = null;


const FeaturePreview = (props) => {
    const frontendBaseUrl = process.env.REACT_APP_AXIOS_ORIGIN;
    const backendBaseUrl = process.env.REACT_APP_API_BASE_URL.split('/api')[0];

    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [authContext, setAuthContext] = useContext(AuthContext);

    const framesLoaded = useRef([false]);
    const frameIds = useRef(['previewFrame']);
    const [cancelledFrames, setCancelledFrames] = useState([]);
    const cancelFrame = (id) => {
        setCancelledFrames((oldVal) => [...oldVal, id]);
        framesLoaded.current[frameIds.current.indexOf(id)] = true;
    }

    const [quizData, setQuizData] = useState(null);

    // Iteratively remove any props that start with "columnBreak", "subTitle", "new_var"
    const removeUnneededProps = (data) => {
        for (let prop in data) {
            if (prop.startsWith('columnBreak') || prop.startsWith('subTitle') || prop.startsWith('new_var')) {
                delete data[prop];
            }
        }
        if (Object.keys(data).length > 0) {
            for (let prop in data) {
                if (typeof data[prop] === 'object') {
                    removeUnneededProps(data[prop]);
                }
            }
        }
    }

    const logoutData = (id) => {
        id = id === 'previewFrame' ? props.currentFeature._id : id;
        console.log('>>>>> Feature ID: ', id);
        console.log('>>>>> Feature Data: ', findFeature(id, campaignContext.campaignData));
        const customisationVars = props.customisationVars;
        if (customisationVars.current.hasOwnProperty(id)) {
            const filteredCustomisationVars = JSON.parse(JSON.stringify(customisationVars.current[id]));
            removeUnneededProps(filteredCustomisationVars);

            console.log('>>>>> Customisation Data: ', filteredCustomisationVars);
        }
        if (customisationVars.default && customisationVars.default.hasOwnProperty(id)) {
            console.log('>>>>> Default Customisation Data: ', customisationVars.default[id]);
        }
        const featureVars = props.featureVars;
        if (featureVars.current.hasOwnProperty(id)) {
            console.log('>>>>> Feature vars: ', featureVars.current[id]);
        }
        if (featureVars.default && featureVars.default.hasOwnProperty(id)) {
            console.log('>>>>> Default Feature vars: ', featureVars.default[id]);
        }
    }
    const [forceUpdateCounter, setForceUpdateCounter] = useState(0);
    const forceUpdate = () => {
        setForceUpdateCounter((oldVal) => oldVal + 1);
    }

    const linkedFeatureData = useRef({});
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const [canBeRefreshed, setCanBeRefreshed] = useState(false);
    const [needsRefesh, setNeedsRefresh] = useState(false);
    const [randomPreviewBG, setRandomPreviewBG] = useState(Math.random());

    const [passedInPreviewData, setPassedInPreviewData] = useState({});
    useEffect(() => {
        currentPassedInPreviewData.data = passedInPreviewData;
    }, [passedInPreviewData]);


    const [maximized, setMaximized] = useState(props.startMaximized || false);
    const [previewX, setPreviewX] = useState(props.startPosX === 'left' ? previewEdgeBuffer : window.innerWidth - previewEdgeBuffer - previewWidth);
    const [previewY, setPreviewY] = useState(props.startPosY === 'bottom' ?  window.innerHeight - previewEdgeBuffer - previewHeight : previewEdgeBuffer);
    const previewPosRef = useRef({ x: props.startPosX === 'left' ? previewEdgeBuffer : window.innerWidth - previewEdgeBuffer - previewWidth, y: props.startPosY === 'bottom' ?  window.innerHeight - previewEdgeBuffer - previewHeight : previewEdgeBuffer })

    const mousePosStartDrag = useRef(null);
    const dragStartHandler = (e) => {
        console.log('Drag start: ', e, e.pageX, e.pageY);
        mousePosStartDrag.current = { x: e.pageX, y: e.pageY };
        e.dataTransfer.dropEffect = "move";
        console.log('Drop effect: ', e.dataTransfer.dropEffect);
        document.body.addEventListener('dragover', dragOverHandler);
        document.body.addEventListener('dragenter', dragOverHandler);
        document.body.addEventListener('dragend', dragEndHandler);
    }
    const dragEndHandler = (e) => {
        e.preventDefault();
        console.log('Drag end: ', e, e.pageX, e.pageY);
        const moveDistX = e.pageX - mousePosStartDrag.current.x;
        const moveDistY = e.pageY - mousePosStartDrag.current.y;
        let newPreviewX = previewPosRef.current.x + moveDistX;
        let newPreviewY = previewPosRef.current.y + moveDistY;
        newPreviewX = newPreviewX >= previewEdgeBuffer ? (newPreviewX <= window.innerWidth - previewEdgeBuffer - previewWidth ? newPreviewX : window.innerWidth - previewEdgeBuffer - previewWidth) : previewEdgeBuffer;
        newPreviewY = newPreviewY >= previewEdgeBuffer ? (newPreviewY <= window.innerHeight - previewEdgeBuffer - previewHeight ? newPreviewY : window.innerHeight - previewEdgeBuffer - previewHeight) : previewEdgeBuffer;

        previewPosRef.current = { x: newPreviewX, y: newPreviewY };
        setPreviewX(newPreviewX);
        setPreviewY(newPreviewY);

        document.body.removeEventListener('dragover', dragOverHandler);
        document.body.removeEventListener('dragenter', dragOverHandler);
        document.body.removeEventListener('dragend', dragEndHandler);
    }
    const dragOverHandler = (e) => {
        e.preventDefault();
        // console.log('Body drag event:', e);
        e.dataTransfer.effectAllowed = "move";
        // console.log('Effect allowed: ', e.dataTransfer.effectAllowed, e.dataTransfer.dropEffect)
    }

    /* THEMES */
    const [isThemeable, setIsThemeable] = useState(false);
    const [themeData, setThemeData] = useState(null);
    const themeDataRef = useRef(null);
    const themeDataFetchedRef = useRef(false);

    const getThemeData = () => {
        // Check tabs in featureData for any themeable tabs
        const featureData = props.currentFeature;
        let themeable = false;
        for (let i = 0; i < featureData.feature.customisation.length; i++) {
            if (featureData.feature.customisation[i].themeable) {
                themeable = true;
                break;
            }
        }

        if (themeable) {
            axios
                .get(getCampaignThemesRoute, { params: { campaignID: props.campaignId }, withCredentials: true })
                .then((res) => {
                    console.log('[THEMES] --- Got theme data: ', res.data, campaignContext.campaignData);
                    themeDataRef.current = res.data.themes;
                    setThemeData(res.data.themes);

                    const fixThemeSrcUrls = (themeData) => {
                        const featureData = props.currentFeature;
                        if (featureData) {
                            const customisationData = featureData.feature.customisation;
                            // console.log('Fix theme srcUrls: ', featureData, themeData);
                            // console.log('Customisation data: ', customisationData);
                            // console.log('-----');
                            for (let tI = 0; tI < themeData.length; tI++) {
                                // console.log('Checking theme data: ', themeData[tI], props.currentFeature._id);
                                setIsThemeable(true);
                                for (let i = 0; i < customisationData.length; i++) {
                                    // const rootVal = customisationVars.current[key];
                                    const tabKey = customisationData[i].tabKey;
                                    for (let j = 0; j < customisationData[i].options.length; j++) {
                                        const varKey = customisationData[i].options[j].varKey;
                                        for (let k = 0; k < customisationData[i].options[j].variables.length; k++) {
                                            const varName = customisationData[i].options[j].variables[k].varName;
                                            // console.log('Valschema: ', customisationData[i].options[j].variables[k].valSchema);
                                            for (let prop in customisationData[i].options[j].variables[k].valSchema) {
                                                const type = customisationData[i].options[j].variables[k].valSchema[prop].type.toLowerCase();
                                                // console.log('Checking for type: ', type);
                                                if (CUSTOMISED_URL_MAP.hasOwnProperty(type)) {
                                                    // We found one we need to map to a url!
                                                    const vals = themeData[tI]?.themeData?.[tabKey]?.[varKey]?.[varName]; // getValFromObject(rootVal[tabKey], varKey, varName);
                                                    // console.log('Fixing urls for: ', vals, themeData[tI], tabKey, varKey, varName);
                                                    if (vals && vals?.length > 0) {
                                                        // console.log('Fixing urls for: ', prop, vals);
                                                        for (let l = 0; l < vals.length; l++) {
                                                            // Reformat legacy string based filenames to be objects...
                                                            // SrcUrl has to be part of the object, meaning all feature customisations are going to break...
                                                            let filename = vals[l][prop];
                                                            if (typeof filename === 'string' || typeof filename === 'undefined' || filename === null) {
                                                                filename = { filename: vals[l][prop] };
                                                                vals[l][prop] = filename;
                                                                filename.isDefault = vals[l].isDefault;
                                                            }

                                                            const srcUrl = filename.srcUrl ||
                                                                getFeatureAssetBaseUrl(false) + 'campaigns/' + (props.campaignId || campaignContext.campaignData._id) + '/' + (themeData[tI].featureId)
                                                                + '/' + CUSTOMISED_URL_MAP[type].dir + '/' + filename.filename;

                                                            // console.log('LOAD ASSET:', srcUrl)

                                                            filename.srcUrl = srcUrl;
                                                            filename.originalFile = filename.filename;
                                                            filename.type = CUSTOMISED_URL_MAP[type].dir;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            console.log('Theme data fixed: ', themeData);
                            setThemeData(themeData);
                            themeDataFetchedRef.current = true;
                        } else {
                            console.log('No feature data to fix urls with');
                            setTimeout(() => fixThemeSrcUrls(themeData), 100);
                        }
                    }
                    fixThemeSrcUrls(res.data.themes);
                })
        } else {
            themeDataFetchedRef.current = true;
        }
    }

    const passFeatureCustomisationsToFeature = (frameId = 'previewFrame') => {
        if (!themeDataFetchedRef.current) {
            setTimeout(() => passFeatureCustomisationsToFeature(frameId), 100);
            return;
        }
        const key = frameId === 'previewFrame' ? props.currentFeature._id : frameId;
        const customisationVars = props.customisationVars
        console.log('Preview customisations vars: ', customisationVars);

            if (customisationVars.current.hasOwnProperty(key)) {
                // convert any filenames into urls
                const featureData = frameId === 'previewFrame' ? props.currentFeature : linkedFeatureData.current[key];
                if (featureData) {
                    const customisationData = featureData.feature.customisation;
                    for (let i = 0; i < customisationData.length; i++) {
                        const rootVal = customisationVars.current[key];
                        const tabKey = customisationData[i].tabKey;
                        for (let j = 0; j < customisationData[i].options.length; j++) {
                            const varKey = customisationData[i].options[j].varKey;
                            for (let k = 0; k < customisationData[i].options[j].variables.length; k++) {
                                const varName = customisationData[i].options[j].variables[k].varName;
                                for (let prop in customisationData[i].options[j].variables[k].valSchema) {
                                    const type = customisationData[i].options[j].variables[k].valSchema[prop].type.toLowerCase();
                                    if (CUSTOMISED_URL_MAP.hasOwnProperty(type)) {
                                        // We found one we need to map to a url!
                                        const vals = getValFromObject(rootVal[tabKey], varKey, varName);
                                        for (let l = 0; l < vals.length; l++) {
                                            // Reformat legacy string based filenames to be objects...
                                            // SrcUrl has to be part of the object, meaning all feature customisations are going to break...
                                            let filename = vals[l][prop];
                                            if (typeof filename === 'string' || typeof filename === 'undefined' || filename === null) {
                                                filename = {filename: vals[l][prop]};
                                                vals[l][prop] = filename;
                                                filename.isDefault = vals[l].isDefault;
                                            }

                                            const srcUrl = filename.srcUrl ||  
                                                            (filename.isDefault ? getFeatureAssetBaseUrl(true) + featureData.feature.defaultAssetFolder : getFeatureAssetBaseUrl(false) + 'campaigns/'+(props.campaignId || campaignContext.campaignData._id)+'/'+(key + (props.streamId && vals[l][prop].isStreamLevel ? ('/' + props.streamId) : ''))) 
                                                            + '/' + CUSTOMISED_URL_MAP[type].dir + '/' + filename.filename;

                                            console.log('LOAD ASSET:', srcUrl)
                                            
                                            filename.srcUrl = srcUrl;
                                            filename.originalFile = filename.filename;
                                            filename.type = CUSTOMISED_URL_MAP[type].dir;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                console.log('Posting customisation data to frame');
                console.log(`${key}: `, customisationVars.current[key]);

                // Modify Howl on this frame to fix issues with blob urls
                const frameRef = document.getElementById(frameId);
                // console.log('---> HOWLER, ' + frameId, frameRef.contentWindow, frameRef.contentWindow.Howl.prototype);
                if (!isDevMode() && frameRef.contentWindow.Howl && frameRef.contentWindow.Howl.prototype && frameRef.contentWindow.Howl.prototype.load && !frameRef.contentWindow.Howl.prototype.originalLoadFunction) {
                    const howlerOriginalLoadFunction = frameRef.contentWindow.Howl.prototype.load;
                    frameRef.contentWindow.Howl.prototype.originalLoadFunction = howlerOriginalLoadFunction;
                    frameRef.contentWindow.Howl.prototype.load = function() {
                        var self = this;
                        // console.log('HOWLER LOAD CALLED FOR: ', self);
                        if (typeof self._src === 'string' && self._src.indexOf('blob:') > 0) {
                            let srcSplit = self._src.split('blob:');
                            self._src = ('blob:' + srcSplit[1]).split('.ogg')[0];
                            self._format = 'ogg';
                        } else
                        if (Array.isArray(self._src)) {
                            if (!Array.isArray(self._format)) {
                                self._format = [];
                            }
                            for (let i = 0; i < self._src.length; i++) {
                                if (typeof self._src[i] === 'string' && self._src[i].indexOf('blob:') > 0) {
                                    let srcSplit = self._src[i].split('blob:');
                                    self._src[i] = ('blob:' + srcSplit[1]).split('.ogg')[0];
                                }
                            }
                            while (self._format.length < self._src.length) {
                                self._format.push('ogg');
                            }
                        }
                        self.originalLoadFunction();
                    }
                }

                // Before we post the data to the frame, add in the domains we are running from so the feature can use it to build api requests and such
                // customisationVars.current[key].domain = window.location.origin;
                // customisationVars.current[key].apiDomain = process.env.REACT_APP_API_BASE_URL;

                // Do we need to apply a theme?
                // Clone the customisation data so we can apply the theme to it
                let themedCustomisationData = JSON.parse(JSON.stringify(customisationVars.current[key]));
                const streamVars = props?.featureVars?.current?.[key];
                if (streamVars) {
                    if (streamVars.theme && streamVars.theme.id && streamVars.theme.id !== '') {
                        const themeData = themeDataRef.current.find((theme) => theme._id === streamVars.theme.id);
                        if (themeData) {
                            const themeVars = themeData.themeData;
                            for (let tabKey in themeVars) {
                                if (themedCustomisationData[tabKey]) {
                                    for (let varKey in themeVars[tabKey]) {
                                        if (themedCustomisationData[tabKey][varKey]) {
                                            for (let varName in themeVars[tabKey][varKey]) {
                                                for (let varI = 0; varI < themeVars[tabKey][varKey][varName].length; varI++) {
                                                    let themedVal = false;
                                                    if (Object.keys(themeVars[tabKey][varKey][varName][varI]).length > 0) {
                                                        // If our theme has more values than the customisation data, we need to add them in
                                                        while (themedCustomisationData[tabKey][varKey][varName].length <= varI) {
                                                            // Apply the default values
                                                            const varSchema = featureData.feature.customisation.find((c) => c.tabKey === tabKey).options.find((o) => o.varKey === varKey).variables.find((v) => v.varName === varName).valSchema;
                                                            const defaultVals = {};
                                                            for (let prop in varSchema) {
                                                                defaultVals[prop] = varSchema[prop].default;
                                                            }
                                                            themedCustomisationData[tabKey][varKey][varName].push(defaultVals);
                                                        }
                                                        // Then apply the theme values
                                                        for (let prop in themeVars[tabKey][varKey][varName][varI]) {
                                                            if (themedCustomisationData[tabKey][varKey][varName]?.[varI]?.[prop]) {
                                                                themedCustomisationData[tabKey][varKey][varName][varI][prop] = themeVars[tabKey][varKey][varName][varI][prop];
                                                            }
                                                            themedVal = true;
                                                        }
                                                        if (themedVal) {
                                                            themedCustomisationData[tabKey][varKey][varName][varI].isDefault = false;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                console.log('Posting themed customisation data to frame: ', themedCustomisationData);

                postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.CUSTOMISATION_VARS_RECEIVED, themedCustomisationData/*customisationVars.current[key]*/, frameId);
            }
        
    }

    const leaderboardData = useRef(null);
    const processPostedMessages = (event) => {
        console.log('Koko Manage Stream received message from preview: ', event.origin, event.data);
        switch (event.data.type) {
            case POST_MESSAGE_IN_TYPES.HANDSHAKE:
                // respond that we are connected and ready!
                event.source.postMessage(
                    { type: POST_MESSAGE_OUT_TYPES.CONNECTED, detail: 'Howdy!' },
                    event.origin
                );
                passFeatureCustomisationsToFeature(event.data.id);
                break;
            case POST_MESSAGE_IN_TYPES.FEATURE_LOADED:
                console.log(event, props.featureVars, props.currentFeature);
                postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.SETTINGS_VARS_RECEIVED, props.featureVars.current[event.data.id === 'previewFrame' ? props.currentFeature._id : event.data.id], event.data.id);

                // Does this feature require pixi (and therefore a canvas?)
                // If not remove any canvas elements from the iframe content
                const featureData = linkedFeatureData.current[event.data.id];
                const frameRef = document.getElementById(event.data.id);
                // console.log('Feature loaded (looking for requiresPixi): ', event.data.id, featureData, frameRef);
                if (frameRef && frameRef.contentWindow && featureData && featureData.feature && featureData.feature.requiresPixi === false) {
                    // Time to remove our canvas elements
                    const canvasElements = frameRef.contentWindow.document.getElementsByTagName('canvas');
                    // console.log('Canvas elements found: ', canvasElements);
                    for (let i = 0; i < canvasElements.length; i++) {
                        canvasElements[i].remove();
                    }
                }

                if (needsRefesh) {
                    refreshPreview(true);
                } else {
                    // if (framesLoaded.current.indexOf(false) >= 0) {
                        framesLoaded.current[frameIds.current.indexOf(event.data.id)] = true;
                        forceUpdate();
                    // }
                    if (framesLoaded.current.indexOf(false) === -1) {
                        setPreviewLoaded(true);
                        setCanBeRefreshed(true);
                        
                        if (props.onLoad) {
                            props.onLoad();
                        }
                        if (props.autoStart) {
                            sendPrimaryCommand(null, true);
                        }
                    }
                    console.log('Frame loaded: ', framesLoaded);
                }
                break;
            case POST_MESSAGE_IN_TYPES.FEATURE_ENDED:
                console.log('PREVIEW: Feature has ended! We might need to emit a socket or something here?');
                // socket.current.emit('featureEnded', {streamID: streamId});
                if (event.data.id === 'previewFrame') {
                    if (props.onEnded) {
                        props.onEnded();
                    }
                } else {
                    postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.TRIGGERED_FEATURE_ENDED, {featureId: event.data.id}, 'previewFrame');
                    for (let prop in linkedFeatureData.current) {
                        postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.TRIGGERED_FEATURE_ENDED, {featureId: event.data.id}, prop);
                    }
                }
                break;
            case POST_MESSAGE_IN_TYPES.AUDIO_ENABLED:
                console.log('PREVIEW: We have interacted and therefore enabled audio! '/*, audioEnabled.current*/);
                // audioEnabled.current = true;
                // socket.current.emit('audioEnabled', {streamID: streamId});
                break;

                case POST_MESSAGE_IN_TYPES.TRIGGER_FEATURE:
                    console.log('Feature ' + event.data.id + ' triggering another feature: ', event.data.featureId);
                    if (cancelledFrames.indexOf(event.data.featureId) < 0) {
                        // Find the correct command
                        const triggerFeatureId = event.data.featureId.split(':')[0];
                        let triggerCommandParam = 0;
                        const featureData = linkedFeatureData.current[triggerFeatureId];
                        if (featureData) {
                            console.log('Looking for primary command, feature data: ', featureData);
                            let commandString = '';
                            for (let i = 0; i < featureData.feature.commands.length; i++) {
                                if (featureData.feature.commands[i].isPrimaryTrigger) {
                                    commandString = featureData.feature.commands[i].command;
                                    if (featureData.feature.commands[i].triggerParam) {
                                        triggerCommandParam = event.data.featureId.split(':')[1] || featureData.feature.commands[i].triggerParam.default || 0;
                                    }
                                }
                            }
                            if (commandString) {
                                console.log('Emitting feature command direct to iframe: ', commandString);
                                // We are not connected, we can't do this with a socket emit, but still want to emulate the correct functionality.
                                // So directly send the command to the frame...
                                postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: commandString, extraData: {username: 'CampaignStudio', hashtag:'Feature Triggered Command', commandParam: triggerCommandParam} }, triggerFeatureId);
                            } else {
                                setTimeout(
                                    () => {
                                        postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.TRIGGERED_FEATURE_ENDED, {featureId: event.data.id}, 'previewFrame');
                                        for (let prop in linkedFeatureData.current) {
                                            postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.TRIGGERED_FEATURE_ENDED, {featureId: event.data.id}, prop);
                                        }
                                    },
                                    1000
                                )
                            }
                        }
                    } else {
                        // This frame has been cancelled, just post a feature ended message after a second so we don't get stuck
                        setTimeout(
                            () => {
                                postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.TRIGGERED_FEATURE_ENDED, {featureId: event.data.featureId}, 'previewFrame');
                                for (let prop in linkedFeatureData.current) {
                                    postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.TRIGGERED_FEATURE_ENDED, {featureId: event.data.featureId}, prop);
                                }
                            },
                            1000
                        );
                    }
                    break;
    
                case POST_MESSAGE_IN_TYPES.TRIGGER_FEATURE_END:
                    console.log('Feature ' + event.data.id + ' triggering another feature to end: ', event.data.featureId);
                    // Find the correct command
                    const triggerEndFeatureId = event.data.featureId.split(':')[0];
                    const featureData_end = linkedFeatureData.current[triggerEndFeatureId];
                    if (featureData_end) {
                        console.log('Looking for feature end command, feature data: ', featureData_end);
                        let commandString_end = '';
                        for (let i = 0; i < featureData_end.feature.commands.length; i++) {
                            if (featureData_end.feature.commands[i].isEndTrigger) {
                                commandString_end = featureData_end.feature.commands[i].command;
                            }
                        }
                        if (commandString_end) {
                            console.log('Emitting feature end command direct to iframe: ', commandString_end);
                            // We are not connected, we can't do this with a socket emit, but still want to emulate the correct functionality.
                            // So directly send the command to the frame...
                            postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: commandString_end, extraData: {username: 'CampaignStudio', hashtag:'Feature End Triggered Command', commandParam: 0} }, triggerEndFeatureId);
                        }
                    }
                    break;

                case POST_MESSAGE_IN_TYPES.REQUEST_QUIZ_DATA:
                    console.log('Feature ' + event.data.id + ' requesting quiz data');
                    // Axios call to get quiz data by ids
                    axios.get(getQuizzesByIdsRoute, {params: {ids: event.data.quizIds}}).then(
                        (res) => {
                            // We need to update the quiz data to include the correct image srcUrls
                            for (let i = 0; i < res.data.quizzes.length; i++) {
                                injectQuizImageSrcUrls(res.data.quizzes[i]);
                            }

                            // We only need to concern ourselves with the quiz data for the disruptor we are previewing
                            if (event.data.id === 'previewFrame') {
                                // FeaturePreview.quizData = res.data;
                                setQuizData(res.data);
                            }

                            console.log('POST MESSAGE FOR QUIZ', res.data)
                          
                            //window.postMessage({ type: POST_MESSAGE_OUT_TYPES.QUIZ_DATA, detail: res.data }, '*');
                            postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.QUIZ_DATA, res.data, event.data.id);
                        }
                    ).catch(
                        (err) => {
                            console.log('Error getting quiz data: ', err);
                        }
                    )
                    
                    break;

                case POST_MESSAGE_IN_TYPES.LEADERBOARD_DATA:
                    console.log('Feature ' + event.data.id + ' sent leaderboard data: ', event.data.leaderboardData);
                    leaderboardData.current = event.data.leaderboardData;
                    // Update leaderboards!
                    for (let prop in linkedFeatureData.current) {
                        postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, {command: 'RECEIVE_LEADERBOARD_DATA', extraData: {forcedScore: leaderboardData.current}}, prop);
                    }
                    break;

                // This will need removing, it's only here for testing!
                case POST_MESSAGE_IN_TYPES.REPORT_ARCADE_DATA:
                    console.log('Feature ' + event.data.id + ' sent arcade data: ', event.data.arcadeData);
                    const arcadeData = event.data.arcadeData;
                    // Insert stream Id and feature Id
                    const dataToSend = {
                        streamID: 'preview_test', // 12 character string is accepted as ObjectId by mongoose
                        featureID: findFeature(props.currentFeature._id, campaignContext.campaignData).feature._id,
                        entryData: arcadeData,
                    }
                    // Now report it using axios
                    axios.post(createArcadeEntriesRoute, dataToSend).then(
                        (res) => {
                            console.log('Arcade data reported: ', res);
                        }
                    ).catch(
                        (err) => {
                            console.log('Error reporting arcade data: ', err);
                        }
                    )
                    break;

            case POST_MESSAGE_IN_TYPES.SET_STREAM_MARKER:
                console.log('Feature ' + event.data.id + ' set a stream marker: ', event.data.description);
                // setStreamMarker(event.data.confirmationID, event.data.description);
                // In preview mode, we are going to just respond as if we have set the marker, without actually doing it (because we can't).
                const fakeResponseData = { channel: 'test_channel', time_secs: 90 + Math.floor(Math.random() * 5000), description: event.data.description, confirmationID: event.data.confirmationID, markerID: 'fake_id_' + Math.floor(Math.random() * 100000) };
                console.log('Marker confirmation received: ', fakeResponseData);
                postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.STREAM_MARKER_CONFIRMED, { markerData: fakeResponseData });
                break;

            case POST_MESSAGE_IN_TYPES.POST_FEATURE_DATA:
                console.log('Feature ' + event.data.id + ' posted some data: ', event.data.featureData);
                // updateFeatureVars(event.data.id, event.data.featureData);
                if (event.data.id === 'previewFrame') {
                    console.log('Merge with passed in data: ', passedInPreviewData);

                    // Go through the data being passed in and make sure we have the original versions in our passedInPreviewData,
                    // ready for merging with the updated data
                    for (let prop in event.data.featureData) {
                        if (typeof passedInPreviewData[prop] === 'undefined' && typeof props.featureVars.current[props.currentFeature._id][prop] !== 'undefined') {
                            passedInPreviewData[prop] = JSON.parse(JSON.stringify(props.featureVars.current[props.currentFeature._id][prop]));
                        }
                    }

                    // Preview Frame has returned some data back. Lets remember it, but not save it...
                    // {...passedInPreviewData, ...event.data.featureData};
                    const newPreviewData = deepObjectMerge({...passedInPreviewData}, event.data.featureData);
                    setPassedInPreviewData(newPreviewData);
                }
                break;

            default:
                break;
        }
    }

    const setUpPostMessageListener = () => {
        if (!window.listenerFunc) {
            window.listenerFunc = processPostedMessages;
            window.addEventListener(
                "message",
                window.listenerFunc,
                true
            );
        }
    }

    const closePreview = (e) => {
        console.log('Removing message listener');
        window.removeEventListener(
            "message",
            window.listenerFunc,
            true
        );
        window.listenerFunc = null;
        // Reset any feature vars that need to be reset on load, so when we load the actual feature the default settings is preserved
        resetOnLoadDefaultFeatureVars();
        props.closeFunc(e);
    }

    const goFullScreenPreview = () => {
        const elem = document.getElementById('previewContent');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    const resetOnLoadDefaultFeatureVars = () => {
        const featureVars = props.featureVars;
        const featureData = props.currentFeature;
        // Any feature vars that are configured in featureData to resetDefaultOnLoad, we need to reset to the default value
        // console.log('[resetOnLoadDefaultFeatureVars] Feature vars: ', featureVars, featureData);
        let madeChanges = false;
        for (let i = 0; i < featureData.feature.defaultVars.length; i++) {
            let varGroupConfig = featureData.feature.defaultVars[i];
            for (let j = 0; j < varGroupConfig.variables.length; j++) {
                let varConfig = varGroupConfig.variables[j];
                // console.log('Checking feature var: ', varGroupConfig, varConfig, featureVars.current[featureData._id][varGroupConfig.varKey][varConfig.varName], varConfig.defaultVal);
                if (varConfig.resetDefaultOnLoad) {
                    // console.log('Needs to be reset: ', varConfig.varName);
                    
                    if (typeof(varConfig?.useCustomisationValueOnLoad) === 'string' && varConfig.useCustomisationValueOnLoad !== '') {
                        featureVars.current[featureData._id][varGroupConfig.varKey][varConfig.varName] = getValFromObject(props.customisationVars?.current ?? props.customisationVars, props.currentFeature._id, varConfig?.useCustomisationValueOnLoad);
                        // console.log('Set live value to customisation value: ', getValFromObject(props.customisationVars?.current ?? props.customisationVars, props.currentFeature._id, varConfig?.useCustomisationValueOnLoad), featureVars.current[featureData._id][varGroupConfig.varKey][varConfig.varName]);
                    } else
                    if (featureVars.current[featureData._id][varGroupConfig.varKey][varConfig.varName] !== varConfig.defaultVal) {
                        featureVars.current[featureData._id][varGroupConfig.varKey][varConfig.varName] = varConfig.defaultVal;
                        madeChanges = true;
                    }
                }
            }
        }

        // Add in our base urls so our features can access them from the state in bootstrap.js
        let baseUrls = {
            frontend: frontendBaseUrl,
            backend: backendBaseUrl,
        }
        for (let p in featureVars.current) {
            if (typeof featureVars.current[p] === 'object') {
                featureVars.current[p].baseUrls = baseUrls;
                if (typeof featureVars.current[p].theme === 'undefined') {
                    featureVars.current[p].theme = {id: ''};
                }
            }
        }

        if (madeChanges) {
            props._reportFeatureVarsChanged();
        }
    }

    useEffect(
        () => {
            setUpPostMessageListener();
            resetOnLoadDefaultFeatureVars();
            getThemeData();
        }, []
    )

    const sendPrimaryCommand = (e, forceStart = false) => {
        if (previewLoaded || forceStart) {
            let command = null;
            for (let i = 0; i < props.currentFeature.feature.commands.length; i++) {
                if (props.currentFeature.feature.commands[i].isPrimaryTrigger) {
                    command = props.currentFeature.feature.commands[i].command;
                    break;
                }
            }
            if (command) {
                props._sendFeatureCommandFunc(e, command, props.currentFeature._id, 0);
            }
        }
    }

    const sendQuickCommand = (e, command, param) => {
        if (previewLoaded) {
            props._sendFeatureCommandFunc(e, command, props.currentFeature._id, param || 0);
        }
    }

    const previewRefreshTimeoutId = useRef(null);
    const refreshPreview = (force = false) => {
        if (canBeRefreshed || force) {
            setPreviewLoaded(false);
            framesLoaded.current[0] = false;
            setNeedsRefresh(false);
            clearTimeout(previewRefreshTimeoutId.current);
            previewRefreshTimeoutId.current = setTimeout(
                () => {
                    setCanBeRefreshed(false);
                    const frame = "previewFrame";
                    const frameRef = document.getElementById(frame);
                    frameRef.contentWindow.location.reload();
                },
                3000
            );
        } else {
            setNeedsRefresh(true);
        }
    }
    useEffect(
        refreshPreview,
        [props.previewUpdate]
    )

    const [showDiagnotics, setShowDiagnostics] = useState(true);
    const closeDiagnostics = () => {
        setShowDiagnostics(false);
    }

    const [diagnosticsCollapsed, setDiagnosticsCollapsed] = useState(true);
    const toggleDiagnostics = () => {
        setDiagnosticsCollapsed((oldVal) => !oldVal);
    }

    console.log('Feature preview: ', props.currentFeature);

    const collapsedFrameStyle = previewLoaded ? {} : {height: 0, overflow: 'hidden', visibility: 'hidden'};
    return (
        <div className={`preview-mode-frame-holder${maximized ? ' preview-maximized' : ''}`} style={maximized ? {} : { left: previewX, top: previewY }} onDragStart={!maximized ? dragStartHandler : null} draggable={!maximized}>
            <HeadedPanel
                color={previewLoaded ? 'primary' : 'error'}
                className={maximized ? 'preview-maximized' : ''}
                headingContent={
                    <div className="fl-row preview-title-holder">
                        <div className="preview-title">
                            {`${previewLoaded ? (props.currentFeature.contentLabel || props.currentFeature.feature.featureName) + ' ' : ''}Preview${previewLoaded ? '' : ' Loading'}${props.titlePostFix !== '' ? ' ' + props.titlePostFix : ''}`}
                        </div>
                        <div className="grow"></div>

                        {!maximized && props.showQuickPlayButton &&
                            <>
                                <div className="win-controls" onClick={sendPrimaryCommand}>
                                    {IconJsxer.GetIcon(
                                        IconJsxer.ICONS.play,
                                        IconJsxer.ICON_STYLES.campaignPanelTop,
                                    )}
                                </div>
                                {props.currentFeature.feature.commands.map(
                                    (val, i, arr) => {
                                        if (val.previewQuickCommand && val.previewQuickIcon && IconJsxer.ICONS[val.previewQuickIcon]) {
                                            return (
                                                <div className="win-controls" onClick={(e) => sendQuickCommand(e, val.command, val.previewQuickParam || 0)} key={'wc_' + i}>
                                                    {IconJsxer.GetIcon(
                                                        IconJsxer.ICONS[val.previewQuickIcon],
                                                        IconJsxer.ICON_STYLES.campaignPanelTop,
                                                    )}
                                                </div>
                                            )
                                        } else {
                                            return null
                                        }
                                    }
                                )}
                            </>
                        }
                        {maximized &&
                            <div className="win-controls" onClick={goFullScreenPreview}>
                                {IconJsxer.GetIcon(
                                    IconJsxer.ICONS.magnify,
                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                )}
                            </div>
                        }
                        {!maximized && props.resizeable &&
                            <div className="win-controls" onClick={() => setMaximized(true)}>
                                {IconJsxer.GetIcon(
                                    IconJsxer.ICONS.winMaximize,
                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                )}
                            </div>
                        }
                        {maximized && props.resizeable &&
                            <div className="win-controls" onClick={() => setMaximized(false)}>
                                {IconJsxer.GetIcon(
                                    IconJsxer.ICONS.winRestore,
                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                )}
                            </div>
                        }
                        {props.closeFunc &&
                            <div className="win-controls" onClick={closePreview}>
                                {IconJsxer.GetIcon(
                                    IconJsxer.ICONS.closeX,
                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                )}
                            </div>
                        }
                    </div>
                }
            >
                <div className="preview-frame-holder">
                    <div className="grow fl-start">
                        <div id="previewContent" className={`preview-mode-frame${maximized ? ' frame-maximized' : ''}${randomPreviewBG>.5 ? ' preview-random-1':' preview-random-2'}`}>
                            {showDiagnotics && (cancelledFrames.length > 0 || framesLoaded.current.indexOf(false) >= 0 || authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level) &&
                                <div className={`preview-diagnostics${diagnosticsCollapsed ? ' diagnostics-collapsed' : ''}`}>
                                    <div className="fl-row grow compact">
                                        <h3 onClick={toggleDiagnostics}>LOADER DIAGNOSTICS{isDevMode() ? ' (DEV)' : ''}</h3>
                                        <div className="grow"></div>
                                        <div className="shrink" onClick={closeDiagnostics}><h3 className="close-diagnotics-button">X</h3></div>
                                    </div>
                                    {!diagnosticsCollapsed && authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level &&
                                        <div className="small-text">
                                            Will stick for Koko Admins to allow output of feature data.
                                        </div>
                                    }
                                    {!diagnosticsCollapsed && framesLoaded.current.map(
                                        (val, i, arr) => {
                                            return (
                                                <div key={'fr_' + i} className="diagnostic-holder">
                                                    <div>{i === 0 ? 'Main Preview' : campaignContext.campaignData.features[i - 1].contentLabel}<br /><span className={`${cancelledFrames.indexOf(frameIds.current[i]) >= 0 ? 'cancelled' : val ? 'loaded' : 'loading'}`}>{cancelledFrames.indexOf(frameIds.current[i]) >= 0 ? 'Cancelled' : val ? 'Loaded' : 'Loading'}</span></div>
                                                    <div className="grow"></div>
                                                    {!val && cancelledFrames.indexOf(frameIds.current[i]) < 0 &&
                                                        <div className="shrink">
                                                            <button className="standard-button slim-button" onClick={() => cancelFrame(frameIds.current[i])}>Cancel</button>
                                                        </div>
                                                    }
                                                    {authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level &&
                                                        <div className="shrink">
                                                            <button className="standard-button slim-button" onClick={() => logoutData(frameIds.current[i])}>Data</button>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    )}    
                                </div>
                            }

                            <iframe title="previewFrame" id="previewFrame" name="previewFrame" style={{...collapsedFrameStyle, zIndex: 450 + (findFeature(props.currentFeature._id, campaignContext.campaignData).stackingOrder || 999)}} src={isDevMode() ? PREVIEW_DEV_URL : props.currentFeature.feature.featureURL + "?demoMode=true"} />
                            {// findLinkedFeatureTriggers(props.currentFeature._id, campaignContext.campaignData, props.customisationVars.current).map(
                            campaignContext.campaignData.features.map(
                                (val, i, arr) => {
                                    if (framesLoaded.current.length <= i + 1) {
                                        framesLoaded.current.push(false);
                                        frameIds.current.push(val._id);
                                    }
                                    linkedFeatureData.current[val._id] = val;
                                    if (cancelledFrames.indexOf(val._id) < 0) {
                                        return (
                                            <iframe key={val._id} title={val._id} id={val._id} name={val._id} style={{...collapsedFrameStyle, zIndex: 450 + (findFeature(val._id, campaignContext.campaignData).stackingOrder || 999)}} src={val.feature.featureURL + "?demoMode=true"} />
                                        )
                                    } else {
                                        return null;
                                    }
                                }
                            )}
                            {!previewLoaded &&
                                <div className={`preview-loading${maximized ? ' frame-maximized' : ''}${randomPreviewBG>.5 ? ' preview-random-1':' preview-random-2'}`}>
                                    <img className="glitch" alt="Twitch Glitch 1" src={assets.LoadingFeature} />
                                </div>
                            }
                        </div>
                    </div>

                    {maximized && previewLoaded &&
                    <div className="fl-column shrink fl-start controls-area form-holder">
                        <FeatureControls
                            feature={props.currentFeature}
                            streamVars={props.featureVars.current}
                            customisationVars={props.customisationVars}
                            featureId={props.currentFeature._id}
                            reportFeatureVarsChangedFunc={props._reportFeatureVarsChanged}
                            reportCustomisationVarsChanged={props._reportCustomisationVarsChanged}
                            sendFeatureCommandFunc={props._sendFeatureCommandFunc}
                            showTooltipFunc={props._showTooltip}
                            hideTooltipFunc={props._hideTooltip}
                            label={props.currentFeature.contentLabel}
                            startCollapsed={!props.startControlsExpanded}
                            previewControls={true}
                            passedInPreviewData={passedInPreviewData}
                            quizData={quizData}
                            isThemeable={isThemeable}
                            themeData={themeData}
                            applyThemeFunc={() => refreshPreview(true)}
                        />
                        </div>
                    }
                </div>
            </HeadedPanel>

        </div>
    )
}

FeaturePreview.propTypes = {
    currentFeature: PropTypes.object.isRequired,
    featureVars: PropTypes.object.isRequired,
    customisationVars: PropTypes.object.isRequired,
    onLoad: PropTypes.func,
    closeFunc: PropTypes.func,

    startMaximized: PropTypes.bool,
    resizeable: PropTypes.bool,
    startControlsExpanded: PropTypes.bool,
    startPosX: PropTypes.string,
    startPosY: PropTypes.string,
    showQuickPlayButton: PropTypes.bool,
    autoStart: PropTypes.bool,

    previewUpdate: PropTypes.number,

    titlePostFix: PropTypes.string,

    _reportFeatureVarsChanged: PropTypes.func.isRequired,
    _reportCustomisationVarsChanged: PropTypes.func.isRequired,
    _sendFeatureCommandFunc: PropTypes.func.isRequired,
    _showTooltip: PropTypes.func,
    _hideTooltip: PropTypes.func,
}

FeaturePreview.defaultProps = {
    startMaximized: false,
    resizeable: true,
    startControlsExpanded: false,
    titlePostFix: '',
    startPosX: 'left',
    startPosY: 'bottom',
    showQuickPlayButton: false,
    autoStart: false,
    previewUpdate: 0,
}

export default FeaturePreview;
