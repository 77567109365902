import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { assets } from "../../assets/assets";
import { AuthContext } from "../../contexts/AuthContext";

import Login from "../Login/Login";
//import Register from "../Register/Register";
// import ConfirmLogin from "../Login/ConfirmLogin";
import AccountUnauthorised from "../Login/AccountUnauthorised"
import EditDisplayName from "../Settings/EditDisplayName"
import TopBar from "../../components/TopBar/TopBar";
import NavBar from "../../components/Navbar/Navbar";

import Campaigns from "../Campaigns/Campaigns";
import CampaignOverview from "../Campaigns/CampaignOverview";
import Users from "../Users/Users";
import BugReports from "../BugReport/BugReports";
import Analytics from "../Analytics/Analytics";
import Schedule from "../Schedule/Schedule";
import Settings from "../Settings/Settings";
import { CampaignProvider } from "../../contexts/CampaignContext";
import "./MainSiteContainer.scss";
import { defaultOptions } from "../../api/axios";
import CreateCampaign from "../CreateCampaign/CreateCampaign";
import { getDefautTimezone, getLocaleData, getTimezoneListData } from "../../helpers/Dates";
import ManageStream from "../Streams/ManageStream";

import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";

import Feature from "../Feature/Feature";
import KokoDebugControls from "../Debug/KokoDebugControls";
import ClawDebugControls from "../Debug/ClawDebugTools";
import Error404 from "../Error404/Error404";
import Blank from "../BlankContainer/BlankContainer";
import CustomiseFeature from "../CustomiseFeature/CustomiseFeature";
import ColorPickerPopUp from "../../components/forms/ColorPicker/ColorPickerPopUp";
import ChangeBotStatusPopup from "../Settings/ChangeBotStatusPopup";


import Quizzes from "../Quizzes/Quizzes";
import EditQuiz from "../Quizzes/EditQuiz";
import SeederEditor from "../SeederEditor/SeederEditor";
import StreamfogTest from "../StreamfogTest/StreamfogTest";
import ExpiredFeature from "../Feature/ExpiredFeature";

const MainSiteContainer = () => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    
    const [haveCheckedBotAuth, setHaveCheckedBotAuth] = useState(false);
    const [showBotAuthPop, setShowBotAuthPop] = useState(false);
    const closeBotAuthPop = () => setShowBotAuthPop(false);
    useEffect(
        () => {
            console.log('Bot auth useEffect');
            if (!haveCheckedBotAuth && authContext.isLoggedIn && authContext.userData.isTwitchLogin && !authContext.userData.isBotAuthorised) {
                console.log('Show pop');
                setShowBotAuthPop(true);
                setHaveCheckedBotAuth(true);
            }
        },
        [authContext.isLoggedIn]
    )
    
    // const [isFeature, setIsFeature] = useState(false);

    // Check if we are on a feature url!
    const location = useLocation();
    const isFeature = location.pathname.split('/')[1] === TOP_LEVEL_NAV_ROUTES.FEATURE.split('/')[1];

    return (
        <>
            {/* Auth context is performing initial validation */}
            {authContext.initialising && 
                <div className="initialising-container">
                    {/* We can probably do something better with this later (or maybe just leave it blank?) */}
                    <img className="glitch" alt="Twitch Glitch 3" src={assets.LoadingFeature} />
                </div>
            }
            {/* Not logged in - only allow us to view the login and register pages */}
            {!authContext.initialising && (!authContext.isLoggedIn || isFeature) &&
                <CampaignProvider>
                    <Routes>
                        {/* Feature route allowed when not logged in! */}
                        <Route path={TOP_LEVEL_NAV_ROUTES.FEATURE} element={<Feature />} />
                        <Route path={TOP_LEVEL_NAV_ROUTES.FEATURE_EXPIRED} element={<ExpiredFeature />} />
                        <Route path={TOP_LEVEL_NAV_ROUTES.LOGIN} element={<Login />} />
                        {/* Shunt us off to the login page if we are logged out and not on login or register page */}
                        <Route path="*" element={<Navigate to={TOP_LEVEL_NAV_ROUTES.LOGIN} replace={true} />} />
                    </Routes>
                </CampaignProvider>
            }

            {!isFeature && !authContext.initialising && authContext.isLoggedIn && (!authContext.isConfirmed) &&
                <Routes>
                    <Route path={TOP_LEVEL_NAV_ROUTES.LOGIN} element={<Login openConfirmLogin="true" />} />
                    {/* Shunt us off to the confirm page if we still need to confirm and don't go to Login or Register */}
                    <Route path="*" element={<Navigate to={TOP_LEVEL_NAV_ROUTES.LOGIN} replace={true} />} />
                </Routes>
            }
    
            {!isFeature && !authContext.initialising && authContext.isLoggedIn && (authContext.isConfirmed) && !authContext.userData.isAuthorised &&
                <Routes>
                    <Route path={TOP_LEVEL_NAV_ROUTES.NOT_AUTHORSIED} element={<AccountUnauthorised />} />
                    <Route path="*" element={<Navigate to={TOP_LEVEL_NAV_ROUTES.NOT_AUTHORSIED} replace={true} />} />
                </Routes>
            }

            {!isFeature && !authContext.initialising && authContext.isLoggedIn && (authContext.isConfirmed) && (!authContext.userData.firstName || authContext.userData.firstName =='') &&
                <Routes>
                    <Route path={TOP_LEVEL_NAV_ROUTES.EDIT_DISPLAY_NAME} element={<EditDisplayName newUser={true}/>} />
                    <Route path="*" element={<Navigate to={TOP_LEVEL_NAV_ROUTES.EDIT_DISPLAY_NAME} replace={true} />} />
                </Routes>
            }

            {/* Logged in - show persistent nav bar on the left, content on the right */}
            {!isFeature && !authContext.initialising && authContext.isLoggedIn && (authContext.isConfirmed) && authContext.userData.isAuthorised &&
            <div className="main-site-container">
                <div className={`${!isFeature ? 'under-top-bar' : 'no-top-bar'}`}>
                    {!isFeature &&
                        <div className="nav-pane">
                            <NavBar></NavBar>
                        </div>
                    }
                    <div className="content-pane">
                            <CampaignProvider>
                                <Routes>
                                    <Route path={TOP_LEVEL_NAV_ROUTES.CAMPAIGNS} element={<Campaigns />} />
                                    <Route path={'/'} element={<Campaigns />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW_TAB} element={<CampaignOverview />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW} element={<CampaignOverview />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE} element={<CustomiseFeature />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.EDIT_DISPLAY_NAME} element={<Blank />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE_STREAM} element={<CustomiseFeature />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.MANAGE_CAMPAIGN_STREAM} element={<ManageStream />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.USERS} element={<Users />} />
                                    { authContext.userData.userLevel >= ACCOUNT_TYPES.twitchSuperUser.level &&
                                        <Route path={TOP_LEVEL_NAV_ROUTES.BUG_REPORTS} element={<BugReports />} />
                                    }
                                    <Route path={TOP_LEVEL_NAV_ROUTES.ANALYTICS} element={<Analytics />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.SCHEDULE} element={<Schedule />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.SETTINGS} element={<Settings />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.CREATE_CAMPAIGN} element={<CreateCampaign />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.QUIZZES} element={<Quizzes />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.CREATE_QUIZ} element={<EditQuiz />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.EDIT_QUIZ} element={<EditQuiz />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.EDIT_CAMPAIGN_FEATURE_QUIZ} element={<EditQuiz />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.FEATURE} element={<Feature />} />
                                    <Route path={TOP_LEVEL_NAV_ROUTES.FEATURE_EXPIRED} element={<ExpiredFeature />} />
                                    { authContext.userData.userLevel >= ACCOUNT_TYPES.twitchSuperUser.level &&
                                        <>
                                            <Route path={TOP_LEVEL_NAV_ROUTES.CLAW_DEBUG_TOOLS} element={<ClawDebugControls />} />
                                            <Route path={TOP_LEVEL_NAV_ROUTES.KOKO_DEBUG_TOOLS} element={<KokoDebugControls />} />
                                            <Route path={TOP_LEVEL_NAV_ROUTES.FEATURE_EDITOR} element={<SeederEditor />} />
                                        </>
                                    }

                                    <Route path={TOP_LEVEL_NAV_ROUTES.STREAMFOG_TEST} element={<StreamfogTest />} />

                                    <Route path="*" element={<Error404 />} />
                                </Routes>
                            </CampaignProvider>
                    </div>
                </div>
                {/* Had to move the top bar down here because drop shadows using filters messed up z-indexes and box-shadow doesn't play well with rounded corners */}
                {!isFeature && <TopBar />}
                {!isFeature && <ColorPickerPopUp />}
            </div>
            }
            {showBotAuthPop &&
                <ChangeBotStatusPopup closePanelFunc={closeBotAuthPop} />
            }
        </>
    );
}

export default MainSiteContainer;
