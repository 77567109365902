import { useEffect, useRef, useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import "./LiveTextControls.scss";

const LiveTextPresetSelector = (props) => {
    // Build our drop down options from props.customisationData.setup.presetText.presets[i].presetName
    const presetListData = [];

    const presetsData = props.customisationData?.setup?.presetText?.presets;
    if (!presetsData || presetsData.length === 0) {
        presetListData.push({
            id: 0,
            label: "No Presets Available",
            value: 0,
            val: 0
        });
    } else {
        for (let i = 0; i < props.customisationData.setup.presetText.presets.length; i++) {
            presetListData.push({
                id: i,
                label: props.customisationData.setup.presetText.presets[i].presetName,
                value: i,
                val: i
            });
        }
    }

    // onMount - make sure we have these values!
    useEffect(() => {
        // Ensure we have a valid preset index
        let correctPresetIndex = props.value;
        if (isNaN(correctPresetIndex) || correctPresetIndex < 0 || correctPresetIndex >= presetListData.length) {
            correctPresetIndex = 0;
        }
        if (correctPresetIndex !== props.value) {
            props.setFunc(correctPresetIndex);
        }
    }, []);

    return (
        <>
            <FormDropDown
                label={props.label}
                items={presetListData}
                value={props.value}
                selectFunc={
                    (e, id, item) => {
                        props.setFunc(item.index)
                    }
                }
                currentIndex={props.value}
                showErrors={false}
                enabled={presetListData.length > 1}
            />
        </>
    )
}

export default LiveTextPresetSelector;
