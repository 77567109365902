import { getValFromObject, VAR_TYPES } from "../config/FeatureDashboardVarMap";

export const FindParamArrayFromCustomisations = (customisationVars, featureId, paramString) => {
    // Find our param string in default and see if it is an array of options...
    // console.log('Finding customisation: ', customisationVars, paramString, featureId);
    if (customisationVars?.default[featureId]) {
        for (let prop1 in customisationVars.default[featureId]) {
            for (let prop2 in customisationVars.default[featureId][prop1]) {
                for (let prop3 in customisationVars.default[featureId][prop1][prop2]) {
                    // console.log('Testing param prop3: ', prop3, paramString, customisationVars.current[featureId][prop1][prop2][prop3]);
                    if (prop3 === paramString && Array.isArray(customisationVars.default[featureId][prop1][prop2][prop3])) {
                        // We found a match!
                        return customisationVars.current[featureId][prop1][prop2][prop3];
                    }
                }
            }
        }
    }
    return null;
}

export const resetOnLoadDefaultFeatureVars = (featureVarsIn, featureData, customisationVars) => {
    let featureVars = featureVarsIn;
    if (featureVars.current) {
        featureVars = featureVars.current;
    }
    if (featureVars[featureData._id]) {
        featureVars = featureVars[featureData._id];
    }
    // Any feature vars that are configured in featureData to resetDefaultOnLoad, we need to reset to the default value
    // console.log('[resetOnLoadDefaultFeatureVars] ' + featureData.contentLabel + ' Feature vars: ', featureVarsIn, featureVars, featureData);
    let madeChanges = false;
    for (let i = 0; i < featureData.feature.defaultVars.length; i++) {
        let varGroupConfig = featureData.feature.defaultVars[i];
        // console.log('Checking feature var group: ', varGroupConfig);
        for (let j = 0; j < varGroupConfig.variables.length; j++) {
            let varConfig = varGroupConfig.variables[j];
            // console.log('Checking feature var: ', varGroupConfig, varConfig, featureVars[varGroupConfig.varKey][varConfig.varName], varConfig.defaultVal);
            if (varConfig.resetDefaultOnLoad) {
                // console.log('Needs to be reset: ', varConfig.varName);
                
                // We only reset these vars if we don't have a stream level override, otherwise stuff can go out of sync!
                const streamLevelOverrideKeyString = featureData._id + '.' + varGroupConfig.varKey + '.' + varConfig.varName;
                let ignoreReset = false;
                if (featureVarsIn.streamLevelOverrideKeys && featureVarsIn.streamLevelOverrideKeys.includes(streamLevelOverrideKeyString)) {
                    ignoreReset = true;
                }

                if (!ignoreReset) {
                    if (typeof(varConfig?.useCustomisationValueOnLoad) === 'string' && varConfig.useCustomisationValueOnLoad !== '') {
                        featureVars[varGroupConfig.varKey][varConfig.varName] = getValFromObject(customisationVars?.current ?? customisationVars, featureData._id, varConfig?.useCustomisationValueOnLoad);
                        // console.log('Set live value to customisation value: ', getValFromObject(customisationVars?.current ?? customisationVars, featureData._id, varConfig?.useCustomisationValueOnLoad), featureVars[varGroupConfig.varKey][varConfig.varName]);
                        madeChanges = true;
                    } else
                    if (featureVars[varGroupConfig.varKey][varConfig.varName] !== varConfig.defaultVal) {
                        featureVars[varGroupConfig.varKey][varConfig.varName] = varConfig.defaultVal;
                        // console.log('Set live value to default value: ', varConfig.defaultVal, featureVars[varGroupConfig.varKey][varConfig.varName]);
                        madeChanges = true;
                    }
                } else {
                    // console.log('Ignoring reset as we have a stream level override: ', streamLevelOverrideKeyString);
                }
            }
        }
    }
    return madeChanges;
}

/**
 * Deeply merge two objects together, overwriting values in obj1 with values in obj2.
 * @param {*} obj1 
 * @param {*} obj2 
 * @returns 
 */
export const deepObjectMerge = (obj1, obj2) => {
    // console.log('Deep object merge: ', JSON.parse(JSON.stringify(obj1)), JSON.parse(JSON.stringify(obj2)));
    for (let key in obj2) {
        if (obj2[key] !== null && typeof obj2[key] === 'object') {
            if (obj1[key] === undefined) {
                obj1[key] = JSON.parse(JSON.stringify(obj2[key]));
            } else {
                deepObjectMerge(obj1[key], obj2[key]);
            }
        } else {
            if (typeof obj2[key] !== 'undefined') {
                obj1[key] = obj2[key];
            }
        }
    }
    // console.log('Deep object merge result: ', obj1);
    return obj1;
}

export const FEATURE_CONTROLS_TYPES = {
    String: {
        type: "string",
        label: "String",
        defaultVarType: VAR_TYPES.stringType,
        liveControl: true,
    },
    Number: {
        type: "number",
        label: "Number",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
    },
    Input: {
        type: "input",
        label: "Input",
        defaultVarType: VAR_TYPES.stringType,
        liveControl: true,
    },
    TextArea: {
        type: "textarea",
        label: "Text Area",
        defaultVarType: VAR_TYPES.stringType,
        liveControl: true,
    },
    Range: {
        type: "slider",
        label: "Range Slider",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
    },
    Toggle: {
        type: "toggle",
        label: "Toggle",
        defaultVarType: VAR_TYPES.booleanType,
        liveControl: true,
    },
    ToggleCheck: {
        type: "toggle-check",
        label: "Toggle Check",
        defaultVarType: VAR_TYPES.booleanType,
        liveControl: true,
    },
    CheckBox: {
        type: "check-box",
        label: "Check Box",
        defaultVarType: VAR_TYPES.booleanType,
        liveControl: true,
    },
    DropDown: {
        type: "dropdown",
        label: "Drop Down",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
        noMinMax: true,
    },
    Radio: {
        type: "radio",
        label: "Radio (Horizontal)",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
        noMinMax: true,
    },
    RadioVertical: {
        type: "radio_v",
        label: "Radio (Vertical)",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
        noMinMax: true,
    },
    HmsTime: {
        type: "hms-time",
        label: "HMS Time of Day Field",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
        noMinMax: true,
    },
    MsTime: {
        type: "ms-time",
        label: "MS Time Field",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
        noMinMax: true,
    },
    RelHmsTime: {
        type: "rel-hms-time",
        label: "Relative HMS Time Field",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
        noMinMax: true,
    },
    DatePicker: {
        type: "date-picker",
        label: "Date Picker",
        defaultVarType: VAR_TYPES.numberType,
        liveControl: false,
        noMinMax: true,
    },
    DateTimePicker: {
        type: "datetime-picker",
        label: "Date Time Picker",
        defaultVarType: VAR_TYPES.stringType,
        fixedDefaultValue: 0,
        liveControl: false,
        noMinMax: true,
    },
    ColourPicker: {
        type: "colour",
        label: "Color Picker",
        defaultVarType: VAR_TYPES.stringType,
        liveControl: false,
        noMaxLength: true,
    },
    VideoUpload: {
        type: "video",
        label: "Video Uploader",
        validateSubProp: "filename",
        liveControl: false,
    },
    ImageUpload: {
        type: "image",
        label: "Image Uploader",
        validateSubProp: "filename",
        liveControl: false,
    },
    ImagePreview: {
        type: "image-preview",
        label: "Image Preview",
        noVal: true,
        liveControl: false,
    },
    AudioUpload: {
        type: "audio",
        label: "Audio Uploader",
        validateSubProp: "filename",
        liveControl: false,
    },
    SubTitle: {
        type: "subtitle",
        label: "Sub Title",
        defaultVarType: VAR_TYPES.stringType,
        liveControl: true,
        noLabelNote: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    CollapsibleSection: {
        type: "collapsible-section",
        label: "Collapsible Section",
        defaultVarType: VAR_TYPES.stringType,
        liveControl: false,
        noLabelNote: true,
        noDefaultVal: true,
        isCosmetic: true,
        sectionEndTypes: [
            "collapsible-section",
            "collapsible-section-end",
            'column-break',
            'section-break',
        ]
    },
    CollapsibleSectionEnd: {
        type: "collapsible-section-end",
        label: "Collapsible Section End",
        defaultVarType: VAR_TYPES.stringType,
        liveControl: false,
        noLabelNote: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    DescriptionString: {
        type: 'description-string',
        label: "Info Text",
        liveControl: true,
        noLabel: true,
        noLabelNote: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    LightDescriptionString: {
        type: 'light-description-string',
        label: "Light Info Text",
        liveControl: true,
        noLabel: true,
        noLabelNote: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    WarningDescriptionString: {
        type: 'warning-description-string',
        label: "Warning Info Text",
        liveControl: true,
        noLabel: true,
        noLabelNote: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    ConfirmDescriptionString: {
        type: 'confirm-description-string',
        label: "Confirm Info Text",
        liveControl: true,
        noLabel: true,
        noLabelNote: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    AttentionDescriptionString: {
        type: 'attention-description-string',
        label: "Attention Info Text",
        liveControl: true,
        noLabel: true,
        noLabelNote: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    Spacer: {
        type: "spacer",
        label: "Spacer",
        noVal: true,
        noLabel: true,
        noDefaultVal: true,
        liveControl: false,
        isCosmetic: true,
    },
    LineSpacer: {
        type: "line-spacer",
        label: "Line Spacer",
        noVal: true,
        noLabel: true,
        noDefaultVal: true,
        liveControl: true,
        isCosmetic: true,
    },
    Disruptor: {
        type: 'disruptor',
        label: "Select Disruptor",
        liveControl: false,
        noDefaultVal: true,
    },
    Quiz: {
        type: 'quiz',
        label: "Select Quiz",
        liveControl: false,
        noDefaultVal: true,
    },
    Font: {
        type: 'font',
        label: "Select Font",
        liveControl: false,
        noDefaultVal: false,
    },
    LeaderboardData: {
        type: 'leaderboardData',
        label: 'Leaderboard Data',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    CompChallengeList: {
        type: 'compChallengeList',
        label: 'Competitive Challenge Sets',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    ClawCameraFeed: {
        type: 'clawCameraFeed',
        label: 'Claw Camera Feed',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
        noDefaultVal: true,
        isCosmetic: true,
    },
    ColumnBreak: {
        type: 'column-break',
        label: 'Column Break',
        noVal: true,
        noLabel: true,
        noDefaultVal: true,
        liveControl: false,
        isCosmetic: true,
    },
    SectionBreak: {
        type: 'section-break',
        label: 'Row Break',
        noVal: true,
        noLabel: true,
        noDefaultVal: true,
        liveControl: false,
        isCosmetic: true,
    },
    CopySettingsButton: {
        type: 'copy-settings-button',
        label: 'Copy Settings Button',
        noVal: false,
        noLabel: false,
        noLabelNote: true,
        noDefaultVal: false,
        defaultVarType: VAR_TYPES.stringType,
        liveControl: false,
        isCosmetic: true,
    },
    LivePollSelector: {
        type: 'livePollSelector',
        label: 'Live Poll Selector',
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
    },
    LivePollAnswerSelector: {
        type: 'livePollAnswerSelector',
        label: 'Live Poll Answer Selector',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
    },
    SlidePuzzleSelector: {
        type: 'slidePuzzleSelector',
        label: 'Slide Puzzle Selector',
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
    },
    SlidePuzzleLiveState: {
        type: 'slidePuzzleLiveState',
        label: 'Slide Puzzle Live State',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
    },
    UncontrolledVar: {
        type: 'uncontrolledVar',
        label: 'Uncontrolled Var',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
    },

    LiveTextTemplateSelector: {
        type: 'liveTextTemplateSelector',
        label: 'Live Text Template Selector',
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
    },
    LiveTextPresetSelector: {
        type: 'liveTextPresetSelector',
        label: 'Live Text Preset Selector',
        defaultVarType: VAR_TYPES.numberType,
        liveControl: true,
    },
    LiveTextMessageStatus: {
        type: 'liveTextLiveMessageStatus',
        label: 'Live Text Message Status',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
    },
    LiveTextQueueStatus: {
        type: 'liveTextLiveQueueStatus',
        label: 'Live Text Queue Status',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
    },
    LiveTextMessageError: {
        type: 'liveTextLiveMessageError',
        label: 'Live Text Message Error',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
    },
    LiveTextResponseError: {
        type: 'liveTextResponseError',
        label: 'Live Text Response Error',
        defaultVarType: VAR_TYPES.customisationType,
        liveControl: true,
    }
}

export const POST_MESSAGE_OUT_TYPES = {
    CONNECTED: 'connected',
    HEARTBEAT: 'heartbeat',
  
    SETTINGS_VARS_RECEIVED: 'settings_vars_received',
    CUSTOMISATION_VARS_RECEIVED: 'customisation_vars_received',
    COMMAND_RECEIVED: 'command_received',
    STATE_UPDATE: 'state_update',

    TRIGGERED_FEATURE_ENDED: 'triggered_feature_ended',

    QUIZ_DATA: 'quiz_data',

    STREAM_MARKER_CONFIRMED: 'stream_marker_confirmed',

    CLAW_SWITCH_CAM: 'claw_switch_cam',
    CLAW_MOVE_ENDED: 'claw_move_ended',
}

export const POST_MESSAGE_IN_TYPES = {
    HANDSHAKE: 'handshake',
    FEATURE_LOADED: 'feature_loaded',
    FEATURE_STARTED: 'feature_started',
    FEATURE_ENDED: 'feature_ended',
    CONTINUE_CHAIN: 'continue_chain',
    AUDIO_ENABLED: 'audio_enabled',
    TRIGGER_FEATURE: 'trigger_feature',
    TRIGGER_FEATURE_END: 'trigger_feature_end',

    REQUEST_QUIZ_DATA: 'request_quiz_data',
    LEADERBOARD_DATA: 'leaderboard_data',
    REPORT_ARCADE_DATA: 'report_arcade_data',

    SET_STREAM_MARKER: 'set_stream_marker',

    POST_LEADERBOARD_DATA: 'post_leaderboard_data',
    POST_FEATURE_DATA: 'post_feature_data',

    CLAW_PLAY_MOVE: 'claw_play_move',
}

export const QUIZ_HELPER = {
    selectedQuiz:0
}
// Here for reference mostly, I guess
export const KOKO_TWITCH_STATES = {
    IDLE: 'idle',
    ACTIVE: 'active',
    END: 'end',
}

export const DATA_SAVE_LEVELS = {
    GLOBAL: 'global',
    STREAM: 'stream',
    CHANNEL: 'channel',
}
