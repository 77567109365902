import { useEffect, useState } from "react";

const LiveTextResponseError = (props) => {
    const [tick, setTick] = useState(0);
    useEffect(() => {
        setTick((t) => t + 1);
    }, [props]);

    const getStateData = () => {
        let stateData = null;
        if (props?.passedInPreviewData?.liveTextStatus) {
            stateData = props.passedInPreviewData.liveTextStatus;
        }
        if (stateData === null) {
            stateData = props?.featureVars?.liveTextStatus;
        }
        if (stateData) {
            return stateData;
        }
        // Default state data
        return {
            activeMessages: [],
            queuedMessages: [],
        };
    }

    const stateData = getStateData();

    if (stateData.responseError !== '') {
        return (
            <div className="label warning-text">{stateData.responseError}</div>
        );
    }
    return null;
}

export default LiveTextResponseError;
